/* width */
::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}
/* Track */
::-webkit-scrollbar-track {
    border-radius: 6px;
}
/* Handle */
::-webkit-scrollbar-thumb {
    border-radius: 6px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    border-radius: 3px;
}
.custom-tooltip {
    max-width: 396px;
    border-radius: 8px !important;
}
.custom-tooltip.__react_component_tooltip.show {
    opacity: 1;
}
.custom-tooltip span {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
}
.initiatvecommon-heading {
    display: flex;
    margin-top: 0.85rem !important;
    align-items: center;
    margin: 20px 0;
}
.initiatvecommon-heading h4 {
    font-family: Graphik-Semibold;
    font-size: 0.824rem;
    font-weight: 600;
    line-height: 22px;
    margin-bottom: 0;
    margin-right: 0.6rem;
}
.initiatvecommon-heading .grey-text {
    font-size: 0.824rem;
    font-weight: 500;
}
.cursor {
    cursor: pointer;
}
.header-tabs {
    position: absolute;
    top: 50px;
    height: 0px;
    width: 100%;
    overflow: hidden;
}
.header-tabs .navbar{
    align-items: flex-start;
}
.header-tabs .navbar-nav {
    border-bottom: none;
}
.header-tabs .navbar-nav .nav-link {
    border: none;
    font-family: Graphik-Regular;
    font-size: 1rem;
    font-weight: 400;
    position: relative;
    display: inline-block;
    margin-right: 1.882rem;
    line-height: 24px;
    cursor: pointer;
    padding: 0.5rem 0 !important;
}
.header-tabs .navbar-nav .nav-link.active, .header-tabs .navbar-nav .nav-link:hover {
    border-bottom: none;
    background-color: transparent;
}
.header-tabs .navbar-nav .nav-link:after {
    content: '';
    display: block;
    height: 3px;
    position: relative;
    bottom: -6px;
    border-radius: 4px;
    width: 0px;
    background-color: transparent;
    transition: width .5s ease, background-color .5s ease;
}
.header-tabs .navbar-nav .nav-link.active:after{
    width: 100%;
}
.header-tabs .tab-content {
    margin-top: 20px;
}
/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    display: none;
    position: absolute;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}
/* Links inside the dropdown */
.dropdown-content a {
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}
/* Change color of dropdown links on hover */
/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
    display: block;
}
/* Slick Slider */
.favouriteapp-slider .slick-prev, .favouriteapp-slider .slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}
.favouriteapp-slider .slick-prev.slick-disabled:before, .favouriteapp-slider .slick-next.slick-disabled:before {
    display: none;
}
.favouriteapp-slider .slick-prev:before {
    content: "<";
}
.favouriteapp-slider .slick-next:before, .favouriteapp-slider .slick-prev:before {
    background-image: url("../Images/rightarrow.svg");
    background-size: 20px 20px;
    background-repeat: no-repeat;
    display: inline-block;
    width: 20px;
    height: 20px;
    content: "";
}
.favouriteapp-slider .slick-prev:before {
    transform: rotate(180deg);
}
.favouriteapp-slider .slick-next {
    right: 12px;
}
.favouriteapp-slider .slick-prev {
    left: auto;
    z-index: 1;
    right: 20px;
}
.notification-cc {
    position: relative;
    text-align: left;
}
.guidedTourElementAdded.notification-cc{
    padding-bottom: 1rem !important;
    background: rgb(161,0,255);
    background: linear-gradient(0deg, rgba(161,0,255,.5) 0%, rgba(117,0,192,.5) 52%, rgba(70,0,115,.5) 100%);
}
.notification-cc .badge {
    min-width: 16px;
    height: 16px;
    border-radius: 13px;
    transform: translate(-8px, -10px);
    font-size: 10px;
    font-weight: 500;
    line-height: 12px;
    position: absolute;
    top: 13px;
}
.NotificationComponentshldr>div {
    padding-right: 5px;
    position: relative;
}
[data-initials]:before {
    opacity: 1;
    content: attr(data-initials);
    display: inline-block;
    font-weight: 600;
    border-radius: 50%;
    vertical-align: middle;
    margin-right: 0.5em;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
}
.modal-content .close {
    font-size: 2rem;
}
.backdroppopover {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 5;
}
.btn{
    border-radius: 1.5rem;
    font-size: .875rem;
}
.btn.btn-outline-gr-slate{
    color: #637381;
    border: .1rem solid #637381;
}
.btn.btn-outline-gr-slate:hover{
    color: #fff;
}
.btn:disabled{
    opacity: 1;
    background-color: rgb(231, 234, 238);
    color: #fff;
    border-color:transparent ;
}
.dropdown .btn.btn-outline-gr-slate{
    background-color: transparent;
    border: .1rem solid #ced4da;
    border-radius: 0.5rem;
    padding: 1rem 2.2rem 1rem .75rem;
    min-width: 60%;
    text-align: left;
    position: relative;
    font-family: Graphik-Regular;
    font-size: 16px;
    color: rgb(145, 158, 171);
}
.dropdown .btn.btn-outline-gr-slate span{
    color: rgb(21, 24, 27);
}
.dropdown .btn.btn-outline-gr-slate:hover{
    background-color: transparent;
    color: #637381;
    border: .1rem solid #ced4da;
}
.form-control{
    border: .1rem solid #ced4da;
    border-radius: 0.5rem;
}
.dropdown .btn.btn-outline-gr-slate::after{
    position: absolute;
    content: '';
    right: 1rem;
    top: 1.2rem;
    width: 16px;
    height: 16px;
    background: url(../Images/angle-down.svg) 0px 0px scroll no-repeat;
    background-size: 16px;
    transform: rotate(180deg);
    opacity: .5;
}
textarea.form-control::placeholder{
    color: #919eab;
}
.form-check-input:hover:before{
    border: 0px;
}
.surveyGridHolder .ag-theme-alpine{
    height: auto;
    margin-bottom: 1rem;
}
.surveyGridHolder .ag-center-cols-container, .surveyGridHolder .ag-theme-alpine .ag-layout-auto-height .ag-center-cols-clipper, .surveyGridHolder .ag-theme-alpine .ag-layout-auto-height .ag-center-cols-container, .surveyGridHolder .ag-theme-alpine .ag-layout-print .ag-center-cols-clipper, .surveyGridHolder .ag-theme-alpine .ag-layout-print .ag-center-cols-container{
    min-height: 0px !important;
}
.GTbackDropBSL{
    opacity: .7;
}
.DT_GTbackDropBSL{
    opacity: 0.85;
    background-color: rgba(255,255,255,0.22);
}
.DT_GTbackDropBSL rect:last-child{
    fill: white;
    opacity: 0.1;
}
.gtBSLClassName{
    background: linear-gradient(-225deg, rgb(161, 0, 255) 0%, rgb(117, 0, 192) 52%, rgb(70, 0, 115) 100%);
    border-radius: .5rem !important;
    box-shadow: 0px 2px 8px 0px rgb(0 0 0 / 10%) !important;
    color: #fff !important;
    padding:0px !important;
}
span[data-tour-elem="badge"] , .gtBSLClassName button:disabled{
    display: none;
}
div[data-tour-elem="controls"] {
    display: block;
    width: 100%;
    position: relative;
    height: 35px;
    margin-top: 1rem;
}
button[data-tour-elem="right-arrow"]{
    background:url(../Images/next_tour_guided.png) center center scroll no-repeat;
    background-color: rgb(161, 0, 255);
    border-radius: 0px 0px 8px;
    width: 50px;
    right: 0px;
}
button[data-tour-elem="left-arrow"]{
    background:url(../Images/previous_tour_guided.png) center center scroll no-repeat;
    width: 70px;
    left: 0px;
}
button[data-tour-elem="right-arrow"], button[data-tour-elem="left-arrow"]{
    height: 35px;
    position: absolute;
    bottom: 0px;
    background-size: auto 9px;
}
button[data-tour-elem="right-arrow"] svg,button[data-tour-elem="left-arrow"] svg{
    display: none;
}
.reactour__close{
    top: 17px !important;
    right: 12px !important;
    width: 11px !important;
    height: 11px !important;
    color: #fff !important;
}
.reactour__close:hover{
    color: #fff !important;
}
.gtBSLinner{
    position: relative;
}
.gtBSLinner:after, .gtBSLinner:before {
    position: absolute;
    content: "";
    background-color: rgb(139 10 222);
}
.gtBSLinner::before, .BVD_TP.gtBSLinner::before, .AKF_TP.gtBSLinner::before, .AKF_TP_B.gtBSLinner::before, .FA_TP.gtBSLinner::before, .HFY_TP.gtBSLinner::before{
    border-radius: 9px;
    width: 9px;
    height: 9px;
}
.notificationTP.gtBSLinner::before{
    left: 274px;
    top: -24px;
}
.notificationTP.gtBSLinner::after{
    left: 277px;
    top: -19px;
    width: 2px;
    height: 20px;
    transform: rotate(0deg) 
}
.header-notification{
    top: 70px !important;
    left: 90px !important;
}
.settingTP.gtBSLinner::before{
    left: 291px;
   /* bottom: 178px;
   */
    top: -1.7rem;
}
.settingTP.gtBSLinner::after{
    right:35px;
   /* bottom: 159px;
   */
    top: -1.2rem;
    width: 2px;
    height: 20px;
    transform: rotate(0deg) 
}
.profileTP.gtBSLinner::before{
    left: 291px;
   /* bottom: 178px;
   */
    top: -1.7rem;
}
.profileTP.gtBSLinner::after{
    right:35px;
   /* bottom: 159px;
   */
    top: -1.2rem;
    width: 2px;
    height: 20px;
    transform: rotate(0deg) 
}
.gtBSLClassName.settings-custom{
    top: 69px !important;
    left: 74px !important;
}
.gtBSLClassName.ioj-custom{
    top: 0 !important;
    left: 42px !important;
}
.gtBSLClassName.svm-custom{
    top: -40px !important;
    left: 370px !important;
}
.SVM_TP.gtBSLinner::before{
    transform: translate(-43px,4px);
}
.SVM_TP.gtBSLinner::after{
    left: -11px;
   /* bottom: 126px;
   */
    top: 0.9rem;
    width: 2px;
    height: 20px;
    transform: rotate(90deg);
}
.location-name{
    color: rgb(99, 115, 129);
    font-size: 14px;
    font-weight: 500;
    height: 16px;
    letter-spacing: -0.25px;
    width: 128px;
}
.IOJ_TP.gtBSLinner::before{
    left: 160px;
    top: -126px;
}
.IOJ_TP.gtBSLinner::after{
    left: 164px;
    top: -126px;
    width: 2px;
    height: 126px;
}
.FID_TP.gtBSLinner::before{
    left: 143px;
    top: -30px;
}
.FID_TP.gtBSLinner::after{
    left: 135px;
    top: -11px;
    width: 22px;
    height: 2px;
    transform: rotate(90deg) 
}
.fid-custom{
    top: 83px !important;
    left: -208px !important;
}
.FIDTP_tab.gtBSLinner::before{
    left: 154px;
    top: -27px;
}
.FIDTP_tab.gtBSLinner::after{
    left: 157px;
    top: -21px;
    width: 2px;
    height: 20px;
}
.FIDTP_SG.gtBSLinner::before{
    left: -42px;
    top: 24px;
}
.FIDTP_SG.gtBSLinner::after{
    left: -21px;
    top: 8px;
    width: 2px;
    height: 42px;
    transform: rotate(90deg) 
}
/*.custom-fid{
    max-width: 591px !important;
    transform: translate(43px,310px) !important;
}
*/
.FIDTP_CO.gtBSLinner::before{
    left: 355px;
    top: 15px;
}
.FIDTP_CO.gtBSLinner::after{
    left: 344px;
    top: 6px;
    width: 2px;
    height: 28px;
    transform: rotate(90deg) 
}
.FIDTP_OB.gtBSLinner::before{
    left: 125px;
    top: -56px;
}
.FIDTP_OB.gtBSLinner::after{
    left: 129px;
    top: -48px;
    width: 2px;
    height: 47px;
    transform: rotate(0deg);
}
.openbtn-custom{
    top: 146px !important;
    left: -219px !important;
}
.BOF_TP.gtBSLinner::before{
    left: 346px;
    top: 22px;
}
.BOF_TP.gtBSLinner::after{
    left: 339px;
    top: 18px;
    width: 2px;
    height: 17px;
    transform: rotate(90deg) 
}
.BOGraph_TP.gtBSLinner::before{
    left: -35px;
    top: 22px;
}
.BOGraph_TP.gtBSLinner::after{
    left: -14px;
    top: 12px;
    width: 2px;
    height: 28px;
    transform: rotate(90deg) 
}
.BOComment_TP.gtBSLinner::before{
    left: 351px;
    top: 22px;
}
.BOComment_TP.gtBSLinner::after{
    left: 344px;
    top: 12px;
    width: 2px;
    height: 28px;
    transform: rotate(90deg) 
}
.IOJH_TP.gtBSLinner::before{
    left: -18px;
    top: 22px;
}
.IOJH_TP.gtBSLinner::after{
    left: -9px;
    top: 18px;
    width: 2px;
    height: 17px;
    transform: rotate(90deg) 
}
.BORef_TP.gtBSLinner::before{
    right: -30px;
    top: 22px;
}
.BORef_TP.gtBSLinner::after{
    right: -14px;
    top: 13px;
    width: 2px;
    height: 28px;
    transform: rotate(90deg) 
}
#_tour_prev_btn, #_tour_next_btn{
    opacity: 0;
}
.IOJHead1_TP.gtBSLinner::before{
    left: 346px;
    top: 22px;
}
.IOJHead1_TP.gtBSLinner::after{
    left: 339px;
    top: 18px;
    width: 2px;
    height: 17px;
    transform: rotate(90deg) 
}
.gtBSLinner::before, .gtBSLinner::after .BVD_TP.gtBSLinner::before, .BVD_TP.gtBSLinner::after, .AKF_TP.gtBSLinner::before, .AKF_TP.gtBSLinner::after, .AKF_TP_B.gtBSLinner::before, .AKF_TP_B.gtBSLinner::after, .FA_TP.gtBSLinner::before, .FA_TP.gtBSLinner::after, .HFY_TP.gtBSLinner::before, .HFY_TP.gtBSLinner::after{
    position: absolute;
    background-color: rgb(139 10 222);
    content: '';
}
.BVD_TP.gtBSLinner::after, .AKF_TP.gtBSLinner::after, .AKF_TP_B.gtBSLinner::after, .FA_TP.gtBSLinner::after, .HFY_TP.gtBSLinner::after, .BOCards_TP.gtBSLinner::after{
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    width: 2px;
}
.BVD_TP.gtBSLinner::before{
    left: 46px;
    top: 170px;
}
.BVD_TP.gtBSLinner::after{
    left: 50px;
    top: 132px;
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    height: 45px;
}
.AKF_TP.gtBSLinner::before{
   /* left: 361px;
   */
    right: -2rem;
    top: 29px;
}
.AKF_TP.gtBSLinner::after{
   /* left: 346px;
   */
    right: -1rem;
    top: 18px;
    height: 31px;
}
.AKF_TP_B.gtBSLinner::before{
   right: 17rem;
   top: -6rem;
}
.AKF_TP_B.gtBSLinner::after{
    right: 17.2rem;
    top: -5.7rem;
    height: 90px;
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
}
.FA_TP.gtBSLinner::before{
    left: -33px;
    top: 18px;
}
.FA_TP.gtBSLinner::after{
    left: -14px;
    top: 9px;
    height: 26px;
}
.HFY_TP.gtBSLinner::before{
    left: 355px;
    bottom: 104px;
}
.HFY_TP.gtBSLinner::after{
    left: 342px;
    bottom: 95px;
    height: 26px;
}
.adminAndConfigHdr {
    min-height: 70px;
}
.adminAndConfigHdr .header-cc{
    position:fixed;
    width:100%;
    z-index: 2;
}
.survey-answer-row .dropdown-item:focus, .survey-answer-row .dropdown-item:hover{
    background-color: rgb(1, 142, 255);
}
/* .hmnyjj {
    position: absolute;
    font-family: monospace;
    background: var(--reactour-accent);
    background: #5cb7b7;
    height: 1.875em;
    line-height: 2;
    padding-left: 0.8125em;
    padding-right: 0.8125em;
    font-size: 1em;
    border-radius: 1.625em;
    color: white;
    text-align: center;
    box-shadow: 0 0.25em 0.5em rgb(0 0 0 / 30%);
    top: -0.8125em;
    left: -0.8125em;
}
*/
/* <style> *, *:before, *:after {
    box-sizing: inherit;
}
<style> */
/* .hmnyjj .helper {
    max-width: 900px !important;
}
*/
/* .gtBSLClassName{
    max-width:650px !important;
}
.gtBSLClassName .fUOHVC{
    min-width:180px !important;
    min-width:100px !important;
}
*/
.IOJSummary_TP{
    position: absolute;
    content: '';
    background: linear-gradient(-225deg, rgb(161, 0, 255) 0%, rgb(117, 0, 192) 52%, rgb(70, 0, 115) 100%);
    border-radius: .5rem !important;
    margin-top: -30px;
}
.IOJSummary_TP+span+.reactour__close{
    top: -10px !important;
    right: 15px !important;
}
.IOJSummary_TP.gtBSLinner::before{
    right: 358px;
    top: -28px;
}
.IOJSummary_TP.gtBSLinner::after{
    left: 327px;
    top: -22px;
    width: 2px;
    height: 23px;
}
.IOJSummary_TP p{
    margin-bottom: 10px !important;
}

.IOJDD_TP.gtBSLinner::before{
    left: 33px;
    top: 155px;
}
.IOJDD_TP.gtBSLinner::after{
    left: 36px;
    top: 116px;
    width: 2px;
    height: 39px;
    transform: rotate(0deg);
}
.IOJfilter_TP.gtBSLinner::before{
    left: 36%;
    top: -45px;
}
.IOJfilter_TP.gtBSLinner::after{
    left: 37%;
    top: -43px;
    width: 2px;
    height: 43px;
}
/* .sc-ifAKCX.fsECTj, .sc-ifAKCX.hWSXCy {
    min-width: 690px !important;
    transform: translate(45%,354%) ;
}
.sc-ifAKCX.FjcAN{
    min-width: 690px !important;
    transform: translate(50%,316%);
}
.sc-ifAKCX.itscns{
    min-width: 690px !important;
    transform: translate(50%,316%);
}
.sc-ifAKCX.itscns .ifAKCX{
    height:3px 
}
.sc-ifAKCX.fDFRGN{
    transform: translate(548px,110px);
}
.sc-ifAKCX.fDFRGN .ifAKCX{
    height:3px 
}
.sc-ifAKCX.fsECTj .diKbXs{
    height:3px 
}
.sc-ifAKCX.FjcAN .ifAKCX{
    height:3px 
}
.gNLHRr{
    transform: translate(962px,241px)!important;
    max-width: 235px!important;
}
*/
.BOCards_TP.gtBSLinner::before{
    left: -25px;
    top: 20px;
}
.BOCards_TP.gtBSLinner::after{
    left: -4px;
    top: 5px;
    height: 39px;
}
.guided-btns{
    height: 28px;
}
.next-btn.zh{
    background:url(../Images/next_tour_guided_chineese.png) center center scroll no-repeat;
    background-color: rgb(161, 0, 255);
    background-size: auto 10px;
}
.previous-btn.zh{
    background:url(../Images/Previous_tour_guided_chineese.png) center center scroll no-repeat;
    background-size: auto 10px;
}
.next-btn.ja{
    background:url(../Images/next_tour_guided_japaneese.png) center center scroll no-repeat;
    background-color: rgb(161, 0, 255);
    background-size: auto 12px;
}
.previous-btn.ja{
    background:url(../Images/Previous_tour_guided_japaneese.png) center center scroll no-repeat;
    background-size: auto 12px;
}
.next-btn, .endtour-btn, .previous-btn{
    position: absolute;
    height: 35px;
    bottom: 0px;
    background-size: auto 9px !important;
    border: none;
}
.next-btn, .endtour-btn{
    background:url(../Images/next_tour_guided.png) center center scroll no-repeat;
    background-color: rgb(161, 0, 255);
    border-radius: 0px 0px 8px;
    width: 50px;
    right: 0px;
}
.previous-btn{
    background:url(../Images/previous_tour_guided.png) center center scroll no-repeat;
    width: 70px;
    left: 0px;
}
.endtour-btn{
    background: center center scroll no-repeat;
    width: 74px;
    color: #fff;
    font-size: 12px;
}
.gtBSLinner p{
    margin-bottom: 10px !important;
}
.gtBSLinner p:last-child{
    margin-bottom: 0px !important;
}
.ioj_panel{
    min-width: 690px !important;
    transform: translate(4px,462px) !important;
}
.ioj_drill {
    max-width: 300px !important;
    transform: translate(958px,82px) !important;
}
.ioj-filter-custom{
    max-width: 420px !important;
    transform: translate(10px,374px) !important;
    left: 36% !important;
}
.highlights-container h6{
    font-size: 20px;
    font-family: Graphik-Semibold;
}
.highlights-container .btn{
    font-size: 12px;
}
.highlights-container .highlights-row{
    display: flex;
    margin-bottom: 1rem;
}
.highlights-container .highlights-row .highlight-feature{
    width: 64%;
}
.highlights-container .highlights-row .highlight-description{
    width:46%;
    padding-left: 10px;
}
.highlights-container .highlights-row .highlight-seq, .highlights-container .highlights-row .highlight-status, .highlights-container .highlights-row .highlight-action{
    width:12%;
    text-align: center;
}
.highlights-container .highlights-row .highlight-action span{
    width: 25%;
    display: inline-block;
}
.highlights-body .highlights-row{
    padding: 14px 0px 12px 15px;
    background: rgb(255, 255, 255);
    box-shadow: 0px 2px 8px 0px rgb(0 0 0 / 10%);
    border-radius: 8px;
}
.custom-tooltip span, .initiatvecommon-heading .grey-text, .notification-cc .badge, .location-name, .endtour-btn, .highlights-container .btn, .highlights-body .highlights-row .highlight-feature, .highlights-body .highlights-row .highlight-description, .highlights-body .highlights-row .highlight-seq, .highlights-body .highlights-row .highlight-status, .highlights-container .highlights-title div, .add_new_highlight_overlay .btn-sm{
    font-family: Graphik-Medium;
}
.highlights-body .highlights-row .highlight-feature{
    color: rgb(1, 142, 255);
    font-size: 14px;
    word-wrap: break-word;
}
.highlights-body .highlights-row .highlight-description, .highlights-body .highlights-row .highlight-seq, .highlights-body .highlights-row .highlight-status{
    color: rgb(33, 43, 54);
    font-size: 14px;
}
.highlights-container .highlights-title div{
    color: rgb(99, 115, 129);
    font-size: 12px;
}
.highlights-body .highlight-action img{
    position: relative;
    top: -6px;
    margin-right: 7px;
}
.add_new_highlight_overlay .modal-title{
    font-size: 16px;
    font-family: Graphik-Semibold;
    margin: 21px 4px;
}
.add_new_highlight_overlay .user_admin_form .custom-dropdown-search .floating-label, .add_new_highlight_overlay .user_admin_form .input-group .floating-label, .user_admin_form .Client-Name span{
    color: rgb(145, 158, 171);
    font-family: Graphik-Regular;
}
.add_new_highlight_overlay .modal-footer{
    background: rgb(239, 242, 245);
    border-radius: 0 0 16px 16px;
}
.add_new_highlight_overlay .custom-control{
    float: right;
}
.add_new_highlight_overlay .check_ref_img{
    background: rgb(181, 192, 202);
    border-radius: 4px;
    width: 54px;
    height: 41px;
    display: inline-block;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    position: relative;
    border: 0.1rem solid transparent;
}
.add_new_highlight_overlay .check_ref_img img{
    max-width:100%;
    max-height: 100%;
    vertical-align: top;
    cursor: pointer;
}
.add_new_highlight_overlay .big_ref_img{
    width: 104px;
    height: 56px;
}
.add_new_highlight_overlay .btn-sm{
    font-size: 12px;
    width: 72px;
    height: 32px;
}
.add_new_highlight_overlay .btn_cancel{
    background: rgb(255, 255, 255);
    border: 1.5px solid rgb(181, 192, 202);
    color: rgb(99, 115, 129);
    margin-right: 16px;
}
.add_new_highlight_overlay .btn_submit{
    background: rgb(1, 142, 255);
}
.add_new_highlight_overlay .user_admin_form .inputText{
    border: 1px solid rgb(181, 192, 202);
}
.add_new_highlight_overlay .modal-dialog{
    justify-content: center;
}
.add_new_highlight_overlay .modal-content{
    width: 625px;
    box-shadow: 0px 2px 64px 0px rgb(0 0 0 / 15%);
    border-radius: 16px;
}
.add_new_highlight_overlay .rem_character{
    color: rgb(99, 115, 129);
    font-size: 10px;
    position: absolute;
    right: 8px;
    bottom: 2px;
    z-index: 4;
    background: #fff;
}
.add_new_highlight_overlay .user_admin_form textarea.inputText {
    resize: none;
    height: 80px;
    padding-top: 18px;
}
.add_new_highlight_overlay .choose_imgs{
    padding-left: 35px;
}
.add_new_highlight_overlay .check_icon{
    position: absolute;
    left: 16px;
    top: 10px;
    box-shadow: 0px 4px 24px 0px rgb(0 0 0 / 30%);
}
.add_new_highlight_overlay .checked_img{
    border: 0.1rem solid rgb(1, 142, 255);
}
.add_new_highlight_overlay .highlights_dropdown .dropdown-toggle{
    background-color: rgb(255, 255, 255) !important;
    border: 1px solid rgb(181, 192, 202) !important;
    border-radius: 8px;
    color: rgb(145, 158, 171) !important;
    outline: none !important;
    box-shadow: none !important;
    min-width: 166px;
    width: 190px;
    height: 56px;
    text-align: left;
    padding: 0.5rem;
    line-height: 12px;
}
.add_new_highlight_overlay .user_admin_form .custom-dropdown-search .floating-label, .add_new_highlight_overlay .user_admin_form input:focus+ .floating-label, .add_new_highlight_overlay .user_admin_form input:not(:placeholder-shown)+ .floating-label, .add_new_highlight_overlay .inputTextarea:active + .floating-label, .add_new_highlight_overlay .inputTextarea:focus + .floating-label, .add_new_highlight_overlay .inputTextarea:not(:placeholder-shown)+ .floating-label{
    font-family: Graphik-Medium !important;
    color: rgb(99, 115, 129) !important;
}
.add_new_highlight_overlay .angleimg{
    float: right;
    margin-top: 7px;
}
.highlights-body .highlights-row .highlight-status.inactive-status{
    color: rgb(145, 158, 171);
}
.add_new_highlight_overlay .user_admin_form textarea.floating-label{
    position: absolute;
    pointer-events: none;
    transition: 0.2s ease all;
    z-index: 6;
}
.add_new_highlight_overlay .inputTextarea + .floating-label + .label_strip, .add_new_highlight_overlay .char_label_strip{
    width: calc(100% - 1rem);
    height: 1.2rem;
    background: #fff;
    position: absolute;
    top: 1px;
    left: 4px;
    border-radius: 8px;
    z-index: 3;
}
.add_new_highlight_overlay .char_label_strip{
    top: 63px;
    height: 1rem;
}
.add_new_highlight_overlay .inputTextarea:active + .floating-label, .add_new_highlight_overlay .inputTextarea:focus + .floating-label, .add_new_highlight_overlay .inputTextarea:not(:placeholder-shown)+ .floating-label{
    top: 3px;
    left: 12px;
    font-size: 11px;
    opacity: 1;
    z-index: 4;
}
.add_new_highlight_overlay .modal-header, .add_new_highlight_overlay .modal-body{
    padding: 0 2rem;
}
.add_new_highlight_overlay .selected-value{
    margin-top: 9px;
    display: inline-block;
    color: rgb(21, 24, 27);
    font-size: 16px;
}
.add_new_highlight_overlay .dropdown-menu.show{
    z-index: 5;
}
.add_new_highlight_overlay .user_admin_form .custom-switch span.label-mark{
    margin-top: 3px;
}
.highlights-container .highlights-row .highlight-description{
    white-space: normal;
    word-wrap: break-word;
}
.add_new_highlight_overlay .highlights_dropdown .pull-left {
    color: rgb(21, 24, 27);
    padding-top: 7px;
}
.add_new_highlight_overlay .highlights_dropdown .pull-left .floating-label:not([value]){
    top: 25px;
    font-size: 16px;
    color: rgb(145, 158, 171) !important;
    font-family: Graphik-Regular !important;
}
@media (min-width: 700px){
   /* .IOJfilter_TP.gtBSLinner::after{
        top: 175px;
        height: 28px;
   }
    .IOJfilter_TP.gtBSLinner::before {
        top: 200px;
   }
    */
    .ioj-filter-custom{
        max-width: 320px !important;
   }
    .custom-tooltip span{
        font-size: 12px;
   }
}
/* Extra large devices (large desktops, 1600px and up) */
@media (min-width: 1600px){
    .main-container > div > .container-fluid,.main-container > .container-fluid.IOJTabs {
        padding-left: 8% !important;
        padding-right: 8% !important;
   }
    .main-container .functions-in-delivery-tab .mainContainerCC {
        background-color: transparent !important;
   }
    .main-container .functions-in-delivery-tab{
        background-color: #f8f9fa !important;
   }
    .header-tabs, header > .header-cc, .footer-container {
        padding-left: 6%;
        padding-right: 6%;
   }
   .header-tabsdropdown .container-fluid {
    padding-left: 7%;
    padding-right: 7%;
   }
    .add_new_highlight_overlay .highlights_dropdown .pull-left .floating-label:not([value]){
        top: 21px;
   }
    .ioj_panel{
        transform: translate(185px,560px) !important;
   }
    .ioj-filter-custom{
        transform: translate(66px,525px) !important;
   }
    .type-dark.custom-tooltip{
        width: 300px;
   }
}
@media (min-width: 1920px){ 
    .main-container .functions-in-delivery-tab .mainContainerCC {
        background-color: transparent !important;
   }
    .main-container .functions-in-delivery-tab{
        background-color: #f8f9fa !important;
   } 
    .main-container > div > .container-fluid.reference_container{
        padding-right: 0% !important;
   }
    .ioj_panel{
        transform: translate(331px,577px) !important;
   }
    .ioj_drill{
        transform: translate(955px,100px) !important;
   }
    .IOJfilter_TP.gtBSLinner::after{
        top: 195px;
        height: 80px;
   }
    .IOJfilter_TP.gtBSLinner::before {
        top: 275px;
   }
    .fav_list_container .referenceslist-section{
        margin-right: 0% !important;
   }
}

/* Responsive Code Ends */

/* SVM App CSS Overrides */
.data-visualisation-container .btn:hover {
    color: white;
}
.data-visualisation-container .btn {
    display: flex;
    box-shadow: none!important;
    line-height: 1.5;
}
.data-visualisation-container .btn-outline-stat-alternate:hover>.svg-container>svg {
    color: #a100ff !important;
}
.data-visualisation-container .btn.btn-outline-stat-alternate>* {
    font-size: 10px;
    margin: 0 2px;
}

.data-visualisation-container .btn-stat-alternate {
    border-radius: 24px;
    color: #fff;
    background-color: #a100ff;
    border-color: #a100ff;
}

.data-visualisation-container .btn-outline-stat-alternate {
    align-items: center;
    background: #fff;
    border: 1px solid #a100ff;
    border-radius: 16px;
    box-shadow: none;
    float: right;
    line-height: 13px;
    padding: 4px 7px;
    width: 115px;
}

.data-visualisation-container .project-tabs-list .groove-select-dropdown div#selectmenu-anchor {
    background: #faf5fd!important;
    border: 1px solid #a100ff;
    border-radius: 8px!important;
}

.data-visualisation-container .project-tabs-list .groove-select-dropdown div#selectmenu-anchor .display-field.field-label {
    color: #637381!important;
    font-family: Graphik-Medium;
    font-size: 11px;
}

.data-visualisation-container .project-tabs-list .groove-select-dropdown div.selectmenu-options div.dropdown-list {
    color: #fff!important;
    margin: -10px 15px 5px;
    max-height: 145px;
    width: -webkit-fill-available;
}

.data-visualisation-container .groove-select-dropdown div.selectmenu-options .dropdown-list {
    margin: 10px 15px!important;
    width: auto!important;
}

.data-visualisation-container .project-tabs-list .groove-select-dropdown svg {
    color: #212b36;
    width: 13px;
}

.data-visualisation-container .project-tabs-list .groove-select-dropdown div.selectmenu-options .searchable-dropdown-content .searchable-dropdown-input {
    width: 80%;
}

.data-visualisation-container .title-section .value-meter-title .fa-chevron-left {
    margin-right: 24px;
}

.data-visualisation-container .project-tabs-list .body-regular {
    height: 18px;
}
.data-visualisation-container .reportingYrDropdown .multi-select-dropdown .multi-select-label+div div {
    max-width: 90px;
}

#dashboard-tooltip-right.tooltip.show {
    opacity: 1;
}

#dashboard-tooltip-right .tooltip-inner .dashboard-details-wrap table tr th, #dashboard-tooltip-right .tooltip-inner .dashboard-details-wrap table tr td{
    padding: 0;
    vertical-align: top;
}
/* SVM App Ends */

/* ClientCockpit New Enhancement CSS starts here - created by Srihari */
.main-wrapper {
    padding: 0 1rem;
}
.main-wrapper .page-title {
    color: rgb(33, 43, 54);
    font-family: Graphik-Medium;
    font-size: 20px;
    font-weight: 500;
    padding: 0rem 0 1.5rem 0;
    letter-spacing: 0px;
    margin: 0;
}
.page-head-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 1rem;
}
.main-wrapper .page-head-wrapper .page-title {
    padding-bottom: 0px;
}
.signoff-list .signoff-item {
    background: rgb(255, 255, 255);
    border-radius: 16px;
    box-shadow: 0px 2px 8px 0px rgba(33, 43, 54, 0.1);
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    padding: 1rem 2rem;
    min-height: 82px;
}
.signoff-item .signoff-text {
    flex: 2 0 200px;
    color: rgb(33, 43, 54);
    font-size: 16px;
    font-family: Graphik-Medium;
    font-weight: 500;
    letter-spacing: 0px;
}
.signoff-item .signoff-desc,
.signoff-item .signoff-desc span,
.file-upload-wrapper .signoff-desc,
.file-upload-wrapper .signoff-desc span {
    flex-grow: 6;
    color: #637381;
    font-size: 12px;
    font-family: Graphik-RegularItalic;
    font-weight: normal;
    font-style: italic;
    letter-spacing: -0.25px;
    line-height: 16px;
}
.signoff-item .signoff-desc span,
.file-upload-wrapper .signoff-desc span {
    color: #dc2626;
}
.signoff-item .signoff-date {
    flex: 2 0 100px;
}
.signoff-item .signoff-date label {
    color: rgb(99, 115, 129);
    font-size: 11px;
    font-family: Graphik-Medium;
    font-weight: 500;
    letter-spacing: -0.25px;
    line-height: 11px;
    margin: 0;
}
.signoff-item .signoff-date span{
    color: rgb(55, 65, 81);
    font-size: 12px;
    font-family: Graphik-Medium;
    font-weight: 500;
    letter-spacing: -0.25px;
    line-height: 16px;
}
.signoff-item .signoff-status {
    flex: 2 0 100px;
    display: flex;
    align-items: center;
    justify-content: end;
}
.signoff-status button,
.signoff-status button:focus,
.signoff-status button:focus:active {
    background: rgb(1, 142, 255);
    border-radius: 16px;
    color: rgb(255, 255, 255);
    font-family: Graphik-Medium;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: -0.25px;
    line-height: 12px;
    text-align: center;
    height: 2rem;
}
.signoff-status button:disabled {
    color: rgb(107, 114, 128);
    font-size: 12px;
    font-family: Graphik-Regular;
    font-weight: normal;
    text-align: center;
    letter-spacing: -0.25px;
    line-height: 12px;
    border: 1px solid rgb(145, 158, 171);
    pointer-events: none;
}
.approver-wrapper .approver-card {
    background: rgb(255, 255, 255);
    border-radius: 16px;
    box-shadow: 0px 2px 8px 0px rgba(33, 43, 54, 0.1);
    padding: 1.5rem;
    margin-bottom: 1rem;
}

.custom-drop-wrapper {
    width: 485px;
    position: relative;
}
.custom-drop-wrapper .dropdown-menu.show {
    width: 100%;
}
.custom-drop-wrapper .dropdown button,
.custom-drop-wrapper .dropdown button:focus,
.custom-drop-wrapper .dropdown button:active:focus,
.custom-drop-wrapper .show > .btn-primary.dropdown-toggle {
    background: rgb(255, 255, 255);
    border-radius: 8px;
    border: 1px solid rgb(181, 192, 202);
    height: 56px;
    width: 100%;
    box-shadow: none;
    outline: none;
    display: flex;
    align-items: center;
    color: rgb(21, 24, 27);
    font-family: Graphik-Regular;
    font-size: 16px;
    font-weight: normal;
    letter-spacing: -0.35px;
    line-height: 16px;
    padding-top: 1rem;
    position: relative;
}
.custom-drop-wrapper .dropdown::after {
    content: '';
    position: absolute;
    right: 10px;
    background-image: url('../Images/UI-Caret-icon.svg');
    background-repeat: no-repeat;
    width: 16px;
    height: 10px;
    display: inline-block;
    bottom: 0;
    top: 0;
    margin: auto;
}
.add-icon {
    position: absolute;
    background: url('../Images/Add-icon.svg') no-repeat;
    width: 24px;
    height: 24px;
    cursor: pointer;
    display: inline-block;
    right: -34px;
    top: 0;
    bottom: 0;
    margin: auto;
}
.add-icon-disabled {
    opacity: 0.2 !important;
    position: absolute;
    background: url('../Images/Add-icon.svg') no-repeat;
    width: 24px;
    height: 24px;
    cursor: pointer;
    display: inline-block;
    right: -34px;
    top: 0;
    bottom: 0;
    margin: auto;
}
.custom-drop-wrapper .dropdown-menu .dropdown-item-text:hover,
.custom-drop-wrapper .dropdown-menu .dropdown-item-text.active {
    background-color: rgb(244, 246, 248);
}
.custom-drop-wrapper .dropdown-menu .dropdown-item-text span {
    color: rgb(33, 43, 54);
    font-size: 14px;
    font-family: Graphik-Medium;
    font-weight: 500;
    letter-spacing: -0.25px;
    line-height: 14px;
}
.custom-drop-wrapper .selected button span {
  top: 16px;
  opacity: 1;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
  color: rgb(145, 158, 171);
  font-family: Graphik-Regular;
}
.custom-drop-wrapper button span {
  color: black;
  font-size: 1rem;
  position: absolute;
  background: transparent;
  transform-origin: 0 0;
  font-family: Graphik-Regular;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
  display: flex;
  align-items: center;
  padding: 0;
  color: rgb(17, 24, 39);
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: 0.25px;
  line-height: 10px;
  top: 24px;
}
.custom-drop-wrapper .dropdown-menu.show .dropdown-item-text {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem;
}
.custom-drop-wrapper .dropdown-menu.show .dropdown-item-text span:first-child {
  padding-right: 1rem;
}
.searchFilter {
  position: relative;
}
.dropdown .searchFilter input[type="search"],
.dropdown .searchFilter input[type="search"]:focus {
  color: #919eab;
  outline: none;
  font-size: 0.75rem;
  letter-spacing: -0.25px;
  font-family: graphik-medium;
  border: 1px solid #ccc;
  /* background: #fff url(../../images/search-filter-icon.png) no-repeat top 7px left 12px; */
  background-size: 16px;
  background-position: 10px center !important;
  cursor: auto;
  border-radius: 20px;
  width: 99% !important;
  height: 32px;
  margin-bottom: 4px;
  padding: 5px 20px 5px 32px !important;
}
/* clears the 'X' from Internet Explorer */
input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.dropdown-menu.show {
  padding: 0.5rem;
}
.dropdown-wrapper {
  max-height: 190px;
  overflow-y: auto;
  overflow-x: hidden;
}
.dropdown-menu.show .dropdown-item-text {
  padding: 0.25rem 0.5rem;
  cursor: pointer;
}
.dropdown-menu.show .dropdown-item-text label {
  cursor: pointer;
}
.table-wrapper .table {
    margin-bottom: 0px;
}
.table-wrapper .table thead tr th {
    color: rgb(99, 115, 129);
    font-size: 16px;
    font-family: Graphik-Medium;
    font-weight: 500;
    letter-spacing: 0px;
    border-bottom: 1px solid rgb(151, 151, 151);
    border-top: none;
}
.table-wrapper .table tbody tr td {
    color: rgb(145, 158, 171);
    font-size: 16px;
    font-family: Graphik-Medium;
    font-weight: 500;
    letter-spacing: 0px;
}
.table-wrapper .table tbody tr td:first-child {
    color: rgb(33, 43, 54);
}
.table-wrapper .table thead tr th:last-child,
.table-wrapper .table tbody tr td:last-child {
    text-align: center;
}
.table-wrapper .table tbody tr td:last-child img {
    cursor: pointer;
}
.accordion-wrapper .stages-header {
  display: flex;
  align-items: center;
  justify-content: space-around;  
}
.accordion-wrapper .accordion .card {
    background: rgb(255, 255, 255);
    box-shadow: 0px 2px 8px 0px rgba(33, 43, 54, 0.1);
    border-radius: 16px;
    margin-bottom: 0.5rem;
    border: none;
}
.accordion-wrapper .accordion  .card  .card-header {
    background: rgb(255, 255, 255);
    border-bottom: 1px solid rgb(238, 238, 238);
    padding: 1rem 2rem;
}
.accordion-wrapper .accordion  .card  .card-body {
    padding: 0;
}
.accordion-wrapper .accordion  .card  .card-body .form-section {
    padding: 1.25rem;
}
.accordion-wrapper .card-body .form-control {
    padding: 1rem 0.75rem 0.375rem 0.75rem;
}
.accordion-wrapper .user_admin_form  input:not(:placeholder-shown)+ .floating-label,
.accordion-wrapper .user_admin_form input:focus+ .floating-label {
    top: 8px;
}
.accordion-wrapper .user_admin_form .form-group .input-group.disabled input{
    background: rgb(239, 242, 245);
}
.accordion-wrapper .input-group.disabled input.form-control::placeholder{ 
    color: rgb(181, 192, 202);
}
.stages-header .stage-name {
    flex: 30%;
}
.stages-header .stage-desc {
    flex: 60%;
}
.stages-header .stage-toggle {
    flex: 10%;
    justify-content: flex-end;
    display: flex;
}
.stages-header .custom-control {
    padding: 0 !important;
    margin: 0 !important;
    z-index: unset;
}
.stages-header .custom-control-input~.custom-control-label:before,
.stages-header .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    cursor: pointer;
    left: 0;
    right: 0;
}
.stages-header .custom-switch .custom-control-label::after {
    left: 0.25rem;
}
.stages-header .custom-control .custom-tooltip span{
    color: rgb(255, 255, 255);
    font-size: 10px;
    font-family: Graphik-Medium;
    font-weight: 500;
    letter-spacing: -0.18px;
    line-height: 16px;
}
.stages-header>div label,
.form-data-values label {
    color: rgb(99, 115, 129);
    font-size: 11px;
    font-family: Graphik-Medium;
    font-weight: 500;
    letter-spacing: -0.25px;
    line-height: 11px;
    margin: 0;
}
.stages-header>div.stage-toggle label {    
    width: 36px;
    height: 24px;
}
.stages-header>div span,
.form-data-values span {
    color: rgb(55, 65, 81);
    font-size: 12px;
    font-family: Graphik-Regular;
    font-weight: normal;
    letter-spacing: -0.25px;
    line-height: 16px;
}
.form-data-values span {
    font-family: Graphik-Medium;
}
.form-data-values span.blue-text {
    color: rgb(1, 142, 255);
    font-size: 12px;
    font-family: Graphik-Regular;
    font-weight: normal;
    letter-spacing: -0.25px;
    line-height: 12px;
}
.stages-header>div.stage-name span {
    color: rgb(33, 43, 54);
    font-family: Graphik-Medium;
    font-size: 1rem;
    font-weight: 500;
}

.file-upload-wrapper .dropped-list {
    display: "flex";
    flex-direction: "column";
}

.file-upload-wrapper .dropped-list .dropped-list-item {
    flex: 1;
    font-size: 0.8rem;
    color: #0069d9;
    display: block;
    padding: 0.3rem;
    margin-bottom: 0.2rem;
}

.file-upload-wrapper label{
    margin: 0;
    color: rgb(99, 115, 129);
    font-size: 11px;
    font-family: Graphik-Medium;
    font-weight: normal;
    letter-spacing: -0.25px;
    line-height: 16px;
}
.file-upload-wrapper .upload-files label {
    margin-bottom: 0.25rem;
}
.file-upload-wrapper .upload-files {
    width: 679px;
    height: 89px;
    border: 1px solid rgb(181, 192, 202);
    border-radius: 8px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 1rem;
}

.accordion-wrapper .form-control::placeholder,
.accordion-wrapper .form-control::-ms-input-placeholder  {
    color: rgb(145, 158, 171) !important;
    font-size: 16px;
    font-family: Graphik-Regular !important;
    font-weight: normal;
    letter-spacing: -0.35px;
    line-height: 16px;
}
.accordion-card-footer {
    height: 56px;
    background: rgb(249, 250, 251);
    border: 1px solid rgb(223, 227, 232);
    border-radius: 0px 0px 16px 16px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 2rem;
    /* margin-top: 1.5rem; */
}
.accordion-card-footer button {
    border-radius: 16px;
    height: 32px;
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.accordion-card-footer button.btn-primary {
    color: #fff;
}
.step-status {
    color: rgb(99, 115, 129);
    font-size: 11px;
    font-family: Graphik-Medium;
    font-weight: 500;
    letter-spacing: -0.25px;
    line-height: 11px;
    display: flex;
    justify-content: flex-end;
    padding: 0.6rem 2rem 0.6rem 0;
}
.step-status span{
    color: rgb(55, 65, 81);
    font-size: 12px;
    font-family: Graphik-Medium;
    font-weight: 500;
    letter-spacing: -0.25px;
    /* line-height: 16px; */
}
.accordion-wrapper .custom-control-input:checked ~ .label-mark::before {
    left: 21px;
}
.tabs-wrapper .nav-tabs {
    margin-top: 0;
}
.tabs-wrapper .nav-tabs a:first-child {
    padding-left: 0;
}
.tabs-wrapper .tab-content-wrapper {
    min-height: 480px;
    background: rgb(255, 255, 255);
    box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    display: flex;
}
.tab-content-wrapper .table-section,
.tab-content-wrapper .chart-section {
    width: 50%;
}
.tab-content-wrapper .chart-section {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.tab-content-wrapper .table-section thead {
    background: rgb(0, 142, 255);
}
.table-section .table-wrapper .table thead tr th {
    color: #fff;
}
.table-section .table-wrapper .table thead tr th:first-child {
    border-radius: 4px 0 0 4px;
}
.table-section .table-wrapper .table thead tr th:last-child {
    border-radius: 0 4px 4px 0;
}
.table-section .table-wrapper .table thead tr th {
    border-top: 0;
    border: none;
}
.table-section .table-wrapper .table tbody tr td:first-child,
.table-section .table-wrapper .table thead tr th:first-child  {
    padding-left: 2rem;
}
.table-section .table-wrapper .table tbody tr td:last-child,
.table-section .table-wrapper .table thead tr th:last-child  {
    padding-right: 2rem;
    text-align: right;
}
.table-section .table-wrapper .table tbody tr td.total-sum {
    font-family: Graphik-Medium;
    color: rgb(21, 24, 27);

}
.chart-section .recharts-default-legend {
    /* position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 80%;
    margin: auto !important; */
    grid-row-gap: 0.5rem;
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto auto;
    grid-column-gap: 0.5rem;
    width: auto;
    text-align: left !important;
}
.tab-content-wrapper .recharts-default-legend .recharts-legend-item-text {
    color: rgb(21, 24, 27) !important;
    font-size: 12px;
    font-family: Graphik-Regular;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 20px;
}
.chart-section .recharts-default-legend .recharts-surface,
.chart-section .recharts-default-legend .recharts-surface .recharts-legend-icon{
    width: 1rem !important;
    height: 1rem !important;
}

/* ClientCockpit New Enhancement CSS ends here - created by Srihari */

/* Key Facys CSS */

.key-facts-preview strong {
    color: #008eff;
}
.key-facts-preview a {
    text-decoration: underline !important;
    color:rgb(70, 0, 115) !important;
}

.ql-tooltip.ql-editing {
    z-index: 100 !important;
}

.notification-popup-item{
    margin-bottom: 20px;
}

.adoption-summary-search{
    margin-top: 25px;
    margin-bottom: 25px;
}

.adoption-summary-grid .ag-cell-value {
        margin-left: 0px!important;
}

.pie-chart1.chart-section .recharts-default-legend {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 80%;
    margin: auto !important;
    display: block !important;
    text-align: center !important;
    /* grid-row-gap: 0.5rem;
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto auto;
    grid-column-gap: 0.5rem;
    width: auto;
    text-align: left !important; */
}
.adoption-summary-grid.ag-theme-alpine .ag-layout-auto-height .ag-center-cols-container,

.adoption-summary-grid.ag-theme-alpine .ag-layout-auto-height .ag-center-cols-clipper{

    min-height: 50px !important;

}

/* Anchor Tag CSS */
.anchor:hover {
    text-decoration: underline;
    cursor: pointer;
}

#announcement-description > .ql-container > .ql-editor {
    min-height: 120px;
}
.announcement-calander::placeholder{
    background-image: url('../Images/calenderGrey-icon.svg');
    background-repeat: no-repeat;
    background-position: right;
}
.announcement-calander:focus::placeholder{
    background-image: url('../Images/calenderBlue-icon.svg');
}