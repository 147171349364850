
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.header-cc{
  background: url(/src/Images/purple-bg.svg) 0px 0px scroll no-repeat;
  background-size: 100%;  
  background-position: right; 
  background-color: #4c007c;
}
.header-height{
  min-height: 100px;
}
.header-effect-on-click{
  background: url(/src/Images/purple-bg.svg) 0px 0px scroll no-repeat;
  background-size: 100%;
  min-height: 100px;
  background-position: right;
  z-index: 11;
  position: fixed;
}

.header-height.header-inner .header-tabs,.header-inner.header-effect-on-click .header-tabs{
  opacity: 1;
  height: 49px;
}
.header-tabs-height{
  height: 100px !important;
}
.header-inner .header-tabs{
  opacity: 0;
}
.hamburgerMenu{
  background: url(/src/Images/hamburgerMenuIcon.svg) 0px 0px scroll no-repeat;
  background-size: 1.5rem;
  width: 1.5rem;
  height: 1.5rem;
  margin-top: .7rem;
}  

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.custom-backdropstyle{
  opacity: 0.6;
}

.spinstyle{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 80px;
}