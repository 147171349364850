/* Dark Theme Starts */
/* Footer Starts */
.dark-theme footer {
  background: #383535;
  border: 1px solid #c8c4c4;
}
.dark_theme_metric_modal.contact-support-modal .modal-footer{
  border-top: 1px solid transparent;
}
.dark_theme_metric_modal.contact-support-modal .modal-content .input-subject-box, .dark_theme_metric_modal.contact-support-modal .modal-content .input-descrition-box, .dark_theme_metric_modal.contact-support-modal .modal-content .capture-screen-btn button{
  background: rgb(31, 37, 40);
  border: 1px solid rgb(99, 115, 129);
}
.dark_theme_metric_modal.contact-support-modal .modal-content .character-count{
  color: rgb(99, 115, 129);
  background: transparent;
}
.dark_theme_metric_modal.contact-support-modal .modal-footer{
  background: rgb(21, 24, 27);
}
.dark_theme_metric_modal.contact-support-modal .modal-footer .cancel-btn{
  background: rgba(0, 0, 0, 0.1);
  border: 1.5px solid rgb(249, 250, 251);
  color: rgb(255, 255, 255);
}
.dark_theme_metric_modal.contact-support-modal .modal-footer .submit-btn{
  background: rgb(0, 186, 255);
}
.dark_theme_metric_modal.contact-support-modal .modal-content .capture-screen-btn button{
  color: rgb(145, 158, 171);
}
.dark_theme_metric_modal.contact-support-modal .modal-content .label-strip{
  background: transparent;
}
.dark_theme_metric_modal.contact-support-modal .modal-content .input-descrition-box:focus + .floating-label + .label-strip, .dark_theme_metric_modal.contact-support-modal .modal-content .input-descrition-box:active + .floating-label + .label-strip, .dark_theme_metric_modal.contact-support-modal .modal-content .input-descrition-box:not(:placeholder-shown) + .floating-label + .label-strip{
  background: transparent;
}
.dark_theme_metric_modal.contact-support-modal .modal-content .description-box{
  border-bottom: 1px solid rgb(99, 115, 129);
}
.dark_theme_metric_modal.contact-support-modal .modal-content .input-subject-box, .dark_theme_metric_modal.contact-support-modal .modal-content .input-descrition-box, .dark_theme_metric_modal.contact-support-modal .modal-header h3, .dark_theme_metric_modal.contact-support-modal .modal-content .form-check-label{
  color: rgb(255, 255, 255);
}
.dark_theme_metric_modal.contact-support-modal .modal-content .form-check .checkmark{
  background-color: transparent;
  border: 1px solid rgb(249, 250, 251);
  border-radius: 4px;
}
.dark_theme_metric_modal.contact-support-modal input[type="checkbox"].form-check-input:checked~.checkmark{
  background-color: #fff;
}
.dark_theme_metric_modal.contact-support-modal .modal-content .checkmark:after{
  border-color: #000;
}
.contact-support-modal.dark_theme_metric_modal{
  background: rgba(0, 0, 0, 0.2);
}
/* Footer Ends */
/* Header Starts */
.dark-theme .header-tabsdropdown {
  background: rgb(57 66 81);
  box-shadow: 0px 2px 16px 0px rgb(0 0 0 / 10%);
}
/* Header Ends */
/* Dark Theme Ends */
/* Common CSS Starts */
.viewMoreCC {
  color: rgb(1, 142, 255);
}
.gr-smoke-pnt5Opacity {
  background: rgb(33, 43, 54, 0.05);
}
.modal-header {
  border-bottom: 0px solid #dee2e6 !important;
}
hr.dot {
  border-top: .1rem dotted rgba(0, 0, 0, 0.1);
}
hr.dash {
  border-top: .1rem dashed rgba(0, 0, 0, 0.1);
}
.customListBlueDot>li::before {
  color: rgb(1, 142, 255);
}
.customListGreyDash>li::before {
  color: rgb(153, 153, 153);
}
.form-check .checkmark {
  border: 1px solid #ced4da;
  background-color: #ffffff;
}
.checkmark:after {
  border-style: solid;
  border-color: #ffffff;
}
input[type="checkbox"].form-check-input:checked~.checkmark {
  background-color: #018eff;
  border-color: #018eff;
}
input[type="radio"].form-check-input:checked~.checkmark {
  background-color: white;
  border-color: #018eff;
}
.form-check input[type="radio"]+.checkmark:after {
  border: 1px solid #018eff;
  background: #018eff;
}
.BOFilter .dropdown-item, .IOJ-headline-tabs .dropdown-item, .reference_container .dropdown-item {
  color: rgb(99, 115, 129);
}
.BOFilter .dropdown-menu, .IOJ-headline-tabs .dropdown-menu, .reference_container .dropdown-menu {
  background: rgb(255, 255, 255);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
}
.BOFilter .dropdown-item:hover, .IOJ-headline-tabs .dropdown-item:hover, .reference_container .dropdown-item:hover {
  background-color: #018eff;
  color: white;
}
.custom-hover-dropdown .dropdown-toggle {
  color: rgb(80, 35, 143) !important;
  background: rgb(235, 225, 248) !important;
}
.slick-dots li button:before {
  background: rgb(1, 142, 255);
}
.Business-Outcomes, .IOJTabs {
  background-color: #f8f9fa;
  min-height: calc(100vh - 100px);
}
.filter-btn-tabs .btn-outline-light.active, .filter-btn-tabs .btn-outline-light:hover {
  border: 1px solid rgb(33, 43, 54) !important;
}
.UserInitial {
  background: linear-gradient(-225deg, rgb(11, 175, 240) 0%, rgb(1, 142, 255) 54%, rgb(11, 76, 255) 100%);
  border: 1px solid rgb(255, 255, 255);
}
.BOTabs .btn-secondary-outline.active, .BOTabs .btn-secondary-outline:hover {
  border: 1px solid rgb(33, 43, 54) !important;
}
.custom-dropdown-search .search-select-list {
  background: rgb(255, 255, 255);
  box-shadow: 0px 2px 8px 0px rgb(0 0 0 / 10%);
}
.custom-dropdown-search ul li {
  color: rgb(99, 115, 129);
}
.custom-dropdown-search ul li:hover {
  background: rgb(244, 246, 248);
}
.custom-dropdown-search .form-check .form-check-label {
  color: rgb(33, 43, 54);
}
.customselectfilter-footer {
  background: #f4f6f8;
}
.customselectfilter-footer .btn.btn-outline-secondary {
  background: #fff;
  border: 1.5px solid rgb(99, 115, 129);
}
.customselectfilter-footer .btn.btn-outline-secondary:hover {
  background: rgb(99, 115, 129);
}
.favouritemodal-filter .fiscalyear-dropdown .custom-dropdown-search {
  border: 1px solid rgb(145, 158, 171);
  color: rgb(99, 115, 129);
}
.favouritemodal-filter .fiscalyear-dropdown .custom-dropdown-search:hover{
  border: 1.5px solid rgb(145, 158, 171);
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.15);
}
.favouritemodal-filter .fiscalyear-dropdown .custom-dropdown-search.custom-dropdown-searchopen {
  border: 1.5px solid rgb(1, 142, 255);
  color: rgb(33, 43, 54);
  background: rgb(244, 251, 255);
}
.searchFilter:after {
  background: #f9fafb;
}
.searchFilter input[type="search"] {
  color: rgb(145, 158, 171);
  border: 1px solid rgb(223, 227, 232);
  background: #fff url(../Images/search-filter.svg) no-repeat scroll 20px!important;
}
.popover {
  border: 1px solid rgba(0, 0, 0, 0) !important;
  box-shadow: 0px 0px 14px 1px rgba(0, 0, 0, .2) !important;
}
.bs-popover-right>.arrow::before, .bs-popover-auto[x-placement^="right"]>.arrow::before {
  border-right-color: rgb(0 0 0 / 7%);
}
.favouritemodal-filter .fiscalyear-dropdown .custom-dropdownname span{
  color: rgb(99, 115, 129);
}
.favouritemodal-filter .fiscalyear-dropdown .custom-dropdown-search.custom-dropdown-searchopen .custom-dropdownname span{
  color: rgb(33, 43, 54);
}
.lightgrey-bg{
  background: #f9fafb;
}
.empty-data {
  background: rgb(245, 245, 245);
}
.empty-data p{
  color: rgb(99, 115, 129);
}
.viewmore-btn .btn {
  background: rgb(1, 142, 255);
  color: rgb(255, 255, 255);
}
.no-filter-disable{
  border: 1.5px solid rgb(145, 158, 171);
  color: rgb(99, 115, 129);
}
.unauthorized-block{
  color: #fff;
}
._preloader_overlaycontent{
  color: #018eff;
}
._preloader_overlayoverlay {
  color: #637381 !important;
  background-color: rgba(255, 255, 255, 0.7) !important;
}
._preloader_ovelayspinner {
  background-color: rgba(99, 115, 129, 0.2) !important;
}
.DT_preloder_overlay ._preloader_overlayoverlay {
  color: #637381 !important;
  background-color: rgba(0, 0, 0,0.8) !important;
}
.unauthorized-block{
  box-shadow: 0px 3px 22px 0px rgb(33 43 54 / 40%);
}
/* Common CSS Ends */
/* Custom CSS Starts */
::-webkit-scrollbar-track{
  background: #ccc;
}
::-webkit-scrollbar-thumb{
  background: rgb(172, 170, 170);
}
::-webkit-scrollbar-corner {
  background: transparent 
}
.custom-tooltip span {
  color: rgb(255, 255, 255);
}
.customListRedDot>li::marker {
  color: red;
}
.initiatvecommon-heading h4 {
  color: rgb(33, 43, 54);
}
.initiatvecommon-heading .grey-text {
  color: rgb(99, 115, 129) !important;
}
.header-tabs .navbar-nav .nav-link {
  color: rgb(255, 255, 255) !important;
}
.header-tabs .navbar-nav .nav-link.active, .header-tabs .navbar-nav .nav-link:hover {
  color: #fff;
}
.header-tabs .navbar-nav .nav-link.active:after{
  background-color: #018eff;
}
.dropdown-content {
  background-color: #f1f1f1;
}
.dropdown-content a {
  color: black;
}
.dropdown-content a:hover {
  background-color: #ddd;
}
.notification-cc .badge {
  background-color: red;
  color: white;
}
[data-initials]:before {
  background: #099bdd;
  color: white;
}
/* Custom CSS Ends */
/* Footer CSS Starts */
footer{
  background: #fff;
}
.footer-links ul li a, .footer-container p{
  color: rgb(145, 158, 171);
}
.termsinfo-modal h3{
  color: rgb(1, 142, 255);
}
.termsinfo-modal .modal-content{
  background: rgb(255, 255, 255);
  border: 1px solid rgb(223, 227, 232);
  box-shadow: 0px 2px 64px 0px rgba(0, 0, 0, 0.15);
}
.termsinfo-modal p{
  color: rgb(33, 43, 54);
}
/*Footer CSS Ends */
/*Header CSS Stars */
.client-cockpit-logo::after {
  background: rgba(255, 255, 255, 0.35);
}
.header-notification-modal .modal-content {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(223, 227, 232);
  box-shadow: 0px 2px 64px 0px rgba(0, 0, 0, 0.15);
}
.userprofile-img[data-initials]:before {
  border: 1px solid rgb(223, 227, 232);
}
.clientinfo-modal .modalheader-block{
  border-bottom: 1px solid rgb(227, 227, 227) !important;
}
.clientinfo-modal .modal-dialog {
  background: rgb(255, 255, 255);
}
.header-leftmenu ul li a , .submenu-list ul li a, .changeclient-block h6, .userprofile-info p, .header-tabsdropdown, .clientchange-modal .header-search h4, .clientchange-modal .header-search h4 span, .client-listing .card-title{
  color: rgb(33, 43, 54) !important;
}
.header-leftmenu ul li.active a:hover, .header-leftmenu ul li.submenu-list li a:hover, li.submenu-list a:hover{
  color: #0891ff !important;
}
.header-leftmenu .comingsoon-tag {
  color: rgb(161, 0, 255);
}
.submenu-list .sub-menu.is-open{
  border-bottom: 1px solid rgb(227, 227, 227);
}
.header-tabsdropdown {
  box-shadow: 0px 2px 16px 0px rgb(0 0 0 / 10%);
}
.header-tabs .navbar-nav .header-tabsdropdown ul li .nav-link {
  color: rgb(223, 227, 232) !important;
}
.clientchange-modal .modal-dialog{
  background: rgb(255, 255, 255);
}
.clientchange-modal h6, .client-listing .card-footer p{
  color: rgb(99, 115, 129);
}
.client-listing .card-footer{
  background-color: #fff;
}
.client-listing .offering-count {
  background: #a100ff;
  color: #fff;
}
.client-listing .favourite-button {
  color: #c4cdd5;
}
button.favorite-button i.active {
  color: #ffc82c;
}
.client-listing .card:hover:after {
  background: -webkit-gradient(linear,left top,left bottom,from(#a100ff),to(#00baff));
  background: -webkit-linear-gradient(#a100ff,#00baff);
  background: linear-gradient(#a100ff,#00baff);
  box-shadow: 0 2px 8px 0 rgb(33 43 54 / 10%);
}
.client-listing .favourite-button.active {
  color: #ffc82c;
}
.UI-modal-box .modal-content{
  background: rgb(249, 250, 251);
}
.UI-modal-box .modal-content .close, .UI-button .dropdown-menu {
  color: rgb(255, 255, 255);
}
#UI-button{
  color: rgb(33, 43, 54);
  background: rgb(255, 255, 255);
  border: 1px solid rgb(223, 227, 232);
}
.UI-button .dropdown-item:hover {
  background: rgb(1, 142, 255);
  color: rgb(255, 255, 255);
}
.UI-button .dropdown-item{
  color: rgb(99, 115, 129);
}
.UI-modal-body .not-btn{
  background: rgb(255, 255, 255);
  border: 1.5px solid rgb(1, 142, 255);
  color: rgb(1, 142, 255);
}
/*Header CSS Ends */
/*Index CSS Stars */
.navBar ul .active, .navBar ul a:hover{
  background: #FFF;
  color: #222;
}
.navBar ul a{
  color: #999;
}
.navBar ul{
  background-color: #333;
  border: 5px solid #333333;
}
/*Index CSS Ends */
/*App CSS Starts */
body{
  color: rgb(33, 43, 54);
 /* background-color: rgb(33, 43, 54);*/
}
.font-color-white{
  color: #fff;
}
.App-link {
  color: #61dafb;
}
.App-header {
  background-color: #282c34;
  color: white;
}
.custom-backdropstyle{
  background-color: #fff !important;
}
/*App CSS Ends */
/*Overview CSS Stars */
.mainContainerCC-overview {
  background: rgb(255, 255, 255);
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
}
.deployedinitiatives .shadow-sm {
  background: rgb(255, 255, 255);
  box-shadow: 0px 2px 8px 0px rgba(33, 43, 54, 0.1) !important;
}
.records-accordion .card-header .card-link {
  color: rgb(161, 0, 255);
}
.records-accordion .card-link:after{
  color: #212b36;
}
.select-filters .select-custom select {
  color: rgb(33, 43, 54);
  border: 1px solid rgb(151, 151, 151);
}
.summary-header h4,
.summary-header .filtered-value span {
  color: rgb(33, 43, 54);
}
.summary-body #filter-accordion .card-header {
  background: #f9fafb;
}
.summary-body #filter-accordion .card-link,
.record-indicate h4,
.record-indicate .record-digit .record-digit-text,
.filtered-records .score-status {
  color: rgb(33, 43, 54);
}
.record-indicate .record-digit {
  color: rgb(1, 142, 255);
}
.filtered-records .pmm-recordsrow .record-divider{
  background: rgb(255, 255, 255);
}
.AccentureKeyFacts{
  border-left: 1px solid #dfe3e8;
}
.key-facts-modal .modal-content{
  background: rgb(255, 255, 255);
  box-shadow: 0px 2px 64px 0px rgba(0, 0, 0, 0.1);
}
.doughnutlayout-col h5,
.doughnutchart-middletext span{
  color: rgb(33, 43, 54);
}
.legend-marker {
  background: rgb(38, 193, 201);
}
.legend-marker.purplebg {
  background: rgb(161, 0, 255);
}
.legend-marker.orangebg {
  background: rgb(244, 147, 66);
}
.doughnutlegend-values li p {
  color: rgb(99, 115, 129);
}
.fiscalyear-dropdown .custom-dropdown-search {
  background: rgb(255, 255, 255);
  border: 1.5px solid rgb(1, 142, 255);
  color: rgb(1, 142, 255);
}
.favouriteapp-heading h3,
.favouriteapp-list p {
  color: rgb(33, 43, 54);
}
.favouriteapp-heading .seeall-link {
  color: rgb(1, 142, 255);
  border-left: 1px solid #637381;
}
.favouriteapp-list h4 {
  color: rgb(1, 142, 255);
}
.view-summary {
  color: #018eff;
}
.favouritesummary-modal .modal-content{
  background: rgb(255, 255, 255);
  box-shadow: 0px 2px 64px 0px rgba(0, 0, 0, 0.1);
}
.modaltitle-img h5,
.favouritemodal-filter p,
.summary-outcomes .outcome-card h6,
.summary-outcomes .outcome-digit .outcome-digittext {
  color: rgb(33, 43, 54);
}
.favouritemodal-filter {
  border-bottom: 1px solid #c4cdd5;
}
.summary-outcomes .outcome-card .outcome-digit {
  color: rgb(1, 142, 255);
}
.summary-outcomes .outcome-card p {
  color: rgb(123, 142, 152);
}
.highlights-for-you{
  background: rgb(255, 255, 255);
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
}
.highlights-for-you .img-holder{
  box-shadow: 0px 2px 16px 0px rgb(0 0 0 / 10%);
}
.HighlightsForYou .carousel-indicators li,
.HighlightsForYou .carousel-indicators li.active {
  background: rgb(1, 142, 255);
}
.highlights-modal .modal-content{
  background: rgb(255, 255, 255);
  box-shadow: 0px 2px 64px 0px rgba(0, 0, 0, 0.1);
}
.highlights-modal .carousel-item h6 {
  color: rgb(145, 158, 171);
}
.highlights-modal .carousel-item h3 {
  color: rgb(33, 43, 54);
}
.highlights-modal .carousel-item p {
  color: rgb(99, 115, 129);
}
.image-section iframe{
  border-bottom: 41px solid black;
}
.highlights-modal .keycontacts-list h6 {
  color: rgb(33, 43, 54);
}
.keycontacts-list li {
  color: rgb(1, 142, 255);
  border-right: 1px solid #dfe3e8;
}
.csupdated-info {
  border-bottom: 1px solid #c4cdd5;
}
.csupdated-info p {
  color: rgb(33, 43, 54);
}
.header-tabsdropdown ul li a {
  color: rgb(223, 227, 232);
}
.doughnut-layout .empty-data{
  border: 12px solid rgb(201, 201, 201);
}
.favouriteBO-list ul li {
  border-bottom: 1px solid rgb(223, 227, 232);
}
.favouriteBO-list ul li h4 {
  color: rgb(33, 43, 54);
}
.tooltipellipsis-text:hover .tooltipellipsis-hovertext{
  background: rgb(33, 43, 54);
  color: rgb(255, 255, 255);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1) ;
}
.tooltipellipsis-text:hover .tooltipellipsis-hovertext::after{
  border-top: 13px solid transparent;
  border-right: 26px solid #212b36;
  border-bottom: 13px solid transparent;
}
.seeall-link span a{
  color: rgb(1, 142, 255);
}
.favouriteBO-list ul li p {
  color: rgb(99, 115, 129);
}
.AccentureKeyFacts .custom-tooltip{
  background: rgb(0, 0, 0);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
}
.AccentureKeyFacts .custom-tooltip p{
  color: rgb(255, 255, 255);
}
.record-heading-tooltip {
  background: #fff;
  border: 1px solid #919eab;
}
/*Overview CSS Ends */
/*FnD CSS Starts */
.criticaloutcome-block{
  background: rgba(33, 43, 54, 0.05);
}
.criticaloutcome-card{
  border-top: 1px solid #919eab;
}
.criticaloutcome-block h5,
.criticaloutcome-heading p,
.criticaloutcome-card h6,
.criticaloutcome-status span,
.criticaloutcome-status p{
  color: rgb(33, 43, 54);
}
.functions-indelivery-tab .btn-outline-light.active,.functions-indelivery-tab .btn-outline-light:hover{
  border: 1px solid rgb(33, 43, 54) !important;
}
.FunctionsInDeliveryTab .open-btn{
  color: rgb(255, 255, 255);
}
.sub-offering-tile-card {
  background: rgb(255, 255, 255);
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
}
.sub-offering-heading1{
  color: rgb(145, 158, 171);
}
.sub-offering-heading2{
  color: rgb(0, 121, 255);
}
.sub-offering-tile-item .legends .legends-value{
  color: rgb(33, 43, 54);
}
.sub-offering-tile-item .btn{
  color: rgb(255, 255, 255);
}
.sub-offering-tile-item .legend-marker-tooltip{
  background: rgb(0, 0, 0);
}
.sub-offering-tile-item .legend-marker-tooltip .legende-marker-text{
  color: rgb(255, 255, 255);
}
.empty-critical-outcomes {
  background: rgba(104, 131, 160, 0.05);
}
.team-modal-box .modal-content{
  background: rgb(255, 255, 255);
  border: 1px solid rgb(223, 227, 232);
  box-shadow: 0px 2px 64px 0px rgba(0, 0, 0, 0.15);
}
.team-modalbox-text .name{
  color: rgb(99, 115, 129);
}
.team-modalbox-text .type,
.team-modalbox-text .mail{
  color: rgb(145, 158, 171);
}
.team-modal-box .border-line{
  border-bottom: 1px solid #e0e5e9;
}
.team-modal-box .img{
  border: 2px solid rgb(255, 255, 255);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
}
.popoverNotification .popover-body .no-data{
  color: rgb(99, 115, 129);
}
.popoverNotification .bs-popover-auto[x-placement^="right"]>.arrow::before {
  border-right-color: rgb(0 0 0 / 0%);
}
.nocritical-outcomedata p{
  color: rgb(145, 158, 171);
}
/*FnD CSS Ends */
/*BO CSS Stars */
.metriccard-list {
  background: rgb(255, 255, 255);
}
.metriccard-list.redColor:hover .metriccard-leftsection{
  background: linear-gradient(-225deg, rgb(242, 178, 192) 0%, rgb(213, 0, 47) 52%, rgb(170, 0, 37) 100%);
  border-radius: 8px 0px 0px 8px;
}
.metriccard-list.greenColor:hover .metriccard-leftsection{
  background: linear-gradient(-225deg, rgb(184, 214, 158) 0%, rgb(126, 195, 68) 51%, rgb(126, 195, 68) 100%);
  border-radius: 8px 0px 0px 8px;
}
.metriccard-list.amberColor:hover .metriccard-leftsection{
  background: linear-gradient(-225deg, rgb(255, 197, 139) 0%, rgb(244, 147, 66) 50%, rgb(192, 87, 23) 100%);
  border-radius: 8px 0px 0px 8px;
}
.metriccard-list.greyColor:hover .metriccard-leftsection{
  background: linear-gradient(-45deg, rgb(99, 115, 129) 0%, rgb(145, 158, 171) 100%);
  border-radius: 8px 0px 0px 8px;
}
.shimmer-effect h4{
  background: linear-gradient(to right, #d0d0d1 4%, #bbb9b9 25%, #cbcbcc 36%);
}
.metriccard-list h4{
  color: rgb(145, 158, 171);
}
.metriccard-list.redColor h4{
  color: rgb(213, 0, 47);
}
.metriccard-list.greenColor h4{
  color: #7ec344;
}
.metriccard-list.amberColor h4{
  color: rgb(192, 87, 23);
}
.metriccard-list.greyColor h4{
  color: #919eab;
}
.metriccard-list .metric-description{
  color: rgb(33, 43, 54);
}
.metriccard-list.redColor:hover h4, .metriccard-list.redColor:hover .metric-description, .metriccard-list.greenColor:hover h4, .metriccard-list.greenColor:hover .metric-description, .metriccard-list.amberColor:hover h4, .metriccard-list.amberColor:hover .metric-description, .metriccard-list.greyColor:hover h4, .metriccard-list.greyColor:hover .metric-description, .fid_dark_theme .carousel-tabs .MuiTab-wrapper, .business_outcome_dark_theme .carousel-tabs .MuiTab-wrapper, .IOJ_dark_theme .carousel-tabs .MuiTab-wrapper{
  color: #fff;
}
.benchmark-listtext p,
.benchmark-listtext span{
  color: rgb(99, 115, 129);
}
.metric-cards-text {
  color: rgb(33, 43, 54);
}
.overview-selected-bg {
  background: rgba(33, 43, 54, 0.05);
  border-radius: 8px;
}
.Metric-Cards .carousel-indicators li,
.Metric-Cards .carousel-indicators li.active {
  background: rgb(1, 142, 255);
}
.Business-Outcomes {
  background-color: #f8f9fa;
}
.Metric-Cards .metriccard-count span{
  color: rgb(33, 43, 54);
}
.metriccard-modalbox .modal-content{
  background: rgb(249, 250, 251);
  box-shadow: 0px 2px 16px 0px rgb(0 0 0 / 10%);
}
.metriccards-dropdownsection .dropdown-toggle, .metriccard-datepicker, .metriccards-dropdownsection .dropdown-toggle:disabled{
  border: 1.5px solid rgb(1, 142, 255) !important;
  background: #fff !important;
  color: rgb(1, 142, 255) !important;
}
.calenderPopover {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(223, 227, 232);
  box-shadow: 0px 2px 64px 0px rgba(0, 0, 0, 0.1);
}
.calenderPopover.popover{
  background: rgb(255, 255, 255);
  border: 1px solid rgb(223, 227, 232) !important;
  box-shadow: 0px 2px 64px 0px rgb(0 0 0 / 10%) !important;
}
.calenderPopoverSpan {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(223, 227, 232);
}
.calenderPopoverSpan:hover, .calenderPopoverSpanActive {
  background: rgba(1, 142, 255, 0.04);
  border: 1px solid rgb(1, 142, 255);
}
.calenderPopover .react-datepicker {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(209, 217, 222);
}
.calenderPopover .react-datepicker__month .react-datepicker__month-text {
  background: rgba(86, 182, 227, 0.05);
  border: 1px solid rgba(86, 182, 227, 0.3);
  color: #52657f;
}
.calenderPopover .react-datepicker__month .react-datepicker__month-text:hover, .calenderPopover .react-datepicker__month .react-datepicker__month--selected {
  background: rgb(1, 142, 255);
  color:#fff !important;
  border: 1px solid rgba(86, 182, 227, 0.3);
}
.calenderPopover .react-datepicker__header {
  background: rgb(251, 252, 252);
  color: #354152;
}
.calenderPopover .popover-footer {
  background: rgb(249, 250, 251);
  border-radius: 0px 0px 16px 16px;
  border: 1px solid rgb(223, 227, 232);
}
.CalenderDatepickerLabel {
  color: rgb(99, 115, 129);
}
.CalenderDatepickerDate,
.metriccards-chartlegend ul li {
  color: rgb(33, 43, 54);
}
.metriccards-chartsection{
  background: rgb(255, 255, 255);
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
}
.metriccards-chartlegend .lowerquartile-legendvalue{
  color: #d5002f;
}
.metriccards-chartlegend .median-legendvalue{
  color: #ffc82c;
}
.metriccards-chartlegend .upperquartile-legendvalue{
  color: #7ec344;
}
.chartlegend-dropdown .dropdown-toggle{
  background: rgb(255, 255, 255) !important;
  border: 1px solid rgb(196, 205, 213) !important;
  color: rgb(99, 115, 129) !important;
}
.chartlegend-dropdown .dropdown-menu{
  background: rgb(255, 255, 255);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
}
.chartlegend-dropdown .dropdown-menu a, 
.metriccards-dropdownsection .dropdown-menu a,
.custom-BOtooltip span{
  color: rgb(99, 115, 129);
}
.chartlegend-dropdown .dropdown-menu a:hover, .metriccards-dropdownsection .dropdown-menu a:hover{
  background: rgb(1, 142, 255);
  color: #fff;
}
.calenderPopover .react-datepicker__navigation--previous,
.calenderPopover .react-datepicker__navigation--next {
  border: 1px solid rgb(209, 217, 222);
}
.reference-heading h3,
.referencelist-card p,
.overview-card p{
  color: rgb(33, 43, 54);
}
.referencelist-card h4,
.overview-card h4{
  color: rgb(1, 142, 255);
}
.custom-BOtooltip.show{
  background: rgb(249, 250, 251);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  opacity: 1 !important;
}
.metriccards-chartsection .empty-data p{
  color: rgb(145,158,171) !important;
}
/*BO CSS Ends */
/*IOJ CSS Starts */
.legend-dot .blue-dot-col{
  color: #26c1c9;
}
.legend-dot .orange-dot-col{
  color: #f49342;
}
.legend-dot .grey-dot-col{
  color: #919eab;
}
.iojfilter-tabs .nav-tabs .nav-link{
  color: rgb(33, 43, 54);
}
.iojfilter-tabs .nav-tabs .nav-link.active, .iojfilter-tabs .nav-tabs .nav-link:hover{
  color: black;
}
.iojfilter-tabs .nav-tabs .nav-link.active:after {
  background-color: #018eff;
}
.iojfilter-tabs .nav-tabs{
  border-bottom: 1px solid #dfe3e8 !important;
}
.foundationtile-card{
  background: rgb(255, 255, 255);
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
}
.ioj-initiative-modal .para{
  color: rgb(0, 0, 0);
}
.ioj-initiative-modal-box .modal-content{
  background: rgb(249, 250, 251);
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
}
.ioj-initiative-modal .unordered-list .active{
  box-shadow: 0 0 0 12px #e7eaf1;
}
.ioj-initiative-modal .unordered-list .grey-dot {
  background: rgb(145, 158, 171);
}
.ioj-modal .blue{
  background: rgb(224, 243, 244);
  color: rgb(38, 193, 201);
  width: 85px;
}
.ioj-modal .orange {
  background: rgb(255, 222, 128) !important;
  color: rgb(192, 87, 23) !important;
  width: 97px;
}
.ioj-modal .red {
  background: rgb(251, 180, 181)!important;
  color: rgb(213, 0, 47)!important;
}
.ioj-modal .grey {
  background: rgb(145, 158, 171)!important;
  color: rgb(20, 51, 82)!important;
  width: 97px;
}
.iojtilecard-statusname,
.iojtilecard-statusname.delivered-status{
  color: rgb(38, 193, 201);
}
.iojtilecard-statusname.inflight-status{
  color: rgb(244, 147, 66);
}
.iojtilecard-statusname.inconcept-status,
.iojtilecard-deliveryinfo span{
  color: rgb(145, 158, 171);
}
.iojtilecard-name h4{
  color: rgb(0, 121, 255);
}
.iojtilecard-description p{
  color: rgb(33, 43, 54);
}
.iojtilecard-deliveryinfo span{
  color: rgb(145, 158, 171);
}
.iojtilecard-morebtn span{
  background: rgb(1, 142, 255);
}
.iojtilecard-morebtn span svg{
  color: #fff;
}
.iojfilter-tabs .empty-data{
  background: transparent !important;
}
/*IOJ CSS Ends */
/* Groove class Starts */
.hero80-black-midnight,.hero80-medium-midnight,.hero64-black-midnight,.hero64-medium-midnight,.hero56-black-midnight,.hero56-medium-midnight,.hero48-black-midnight,.hero48-medium-midnight,.hero32-black-midnight,.hero32-medium-midnight,.header28-semibold-midnight,.header24-semibold-midnight,.header20-semibold-midnight,.header18-semibold-midnight,.header16-semibold-midnight,.header14-semibold-midnight, .bodytext24-medium-midnight,.bodytext24-regular-midnight,.bodytext20-medium-midnight,.bodytext20-regular-midnight,.bodytext18-medium-midnight,.bodytext18-regular-midnight,.bodytext16-medium-midnight,.bodytext16-regular-midnight,.bodytext14-medium-midnight,.bodytext14-regular-midnight,.bodytext12-medium-midnight,.bodytext12-regular-midnight, .label24-medium-midnight,.label24-regular-midnight,.label20-medium-midnight,.label20-regular-midnight,.label18-medium-midnight,.label18-regular-midnight,.label16-medium-midnight,.label16-regular-midnight,.label14-medium-midnight,.label14-regular-midnight,.label12-medium-midnight,.label12-regular-midnight,.label11-medium-midnight,.label11-allcaps-midnight,.label10-allcaps-midnight {
  color: #212b36;
}
.hero80-black-opsblue,.hero80-medium-opsblue,.hero64-black-opsblue,.hero64-medium-opsblue,.hero56-black-opsblue,.hero56-medium-opsblue,.hero48-black-opsblue,.hero48-medium-opsblue,.hero32-black-opsblue,.hero32-medium-opsblue, .header28-semibold-opsblue,.header24-semibold-opsblue,.header20-semibold-opsblue,.header18-semibold-opsblue,.header16-semibold-opsblue,.header14-semibold-opsblue, .label24-medium-opsblue,.label24-regular-opsblue,.label20-medium-opsblue,.label20-regular-opsblue,.label18-medium-opsblue,.label18-regular-opsblue,.label16-medium-opsblue,.label16-regular-opsblue,.label14-medium-opsblue,.label14-regular-opsblue,.label12-medium-opsblue,.label12-regular-opsblue,.label11-medium-opsblue,.label11-allcaps-opsblue,.label10-allcaps-opsblue {
  color: #008eff;
}
.bodytext24-medium-slate,.bodytext24-regular-slate,.bodytext20-medium-slate,.bodytext20-regular-slate,.bodytext18-medium-slate,.bodytext18-regular-slate,.bodytext16-medium-slate,.bodytext16-regular-slate,.bodytext14-medium-slate,.bodytext14-regular-slate,.bodytext12-medium-slate, .bodytext12-regular-slate, .label24-medium-slate,.label24-regular-slate,.label20-medium-slate,.label20-regular-slate,.label18-medium-slate,.label18-regular-slate,.label16-medium-slate,.label16-regular-slate,.label14-medium-slate,.label14-regular-slate,.label12-medium-slate,.label12-regular-slate,.label11-medium-slate,.label11-allcaps-slate,.label10-allcaps-slate {
  color: #637381;
}
.label24-medium-silver,.label24-regular-silver,.label20-medium-silver,.label20-regular-silver,.label18-medium-silver,.label18-regular-silver,.label16-medium-silver,.label16-regular-silver,.label14-medium-silver,.label14-regular-silver,.label12-medium-silver,.label12-regular-silve,.label11-medium-silver,.label11-allcaps-silver,.label10-allcaps-silver {
  color: #919eab;
}
.header28-semibold-white,.header24-semibold-white,.header20-semibold-white,.header18-semibold-white,.header16-semibold-white,.header14-semibold-white, .bodytext24-medium-white,.bodytext24-regular-white,.bodytext20-medium-white,.bodytext20-regular-white,.bodytext18-medium-white,.bodytext18-regular-white,.bodytext16-medium-white,.bodytext16-regular-white,.bodytext14-medium-white,.bodytext14-regular-white,.bodytext12-medium-white,.bodytext12-regular-white, .label24-medium-white,.label24-regular-white,.label20-medium-white ,.label20-regular-white,.label18-medium-white,.label18-regular-white,.label16-medium-white,.label16-regular-white,.label14-medium-white,.label14-regular-white,.label12-medium-white,.label12-regular-white,.label11-medium-white,.label11-allcaps-white,.label10-allcaps-white {
  color: #ffffff;
}
/* Groove class Ends */
.BOTabs .text-right{
  display: block;
  width: 100%;
  position: relative;
  top: -11x;
}
.MuiTabs-root{
  margin: 0 !important;
}
.dark_theme_container, .business_outcome_dark_theme{
  background-color: #000000 !important;
  overflow: hidden;
}
.user_preference_dark_theme .modal-content{
  background-color: #1f2528 !important;
}
.dark_theme_container .Accenture-Key-Facts{
  background: rgb(33, 43, 54);
  border-color: #000;
}
.dark_theme_container .Accenture-Key-Facts .key-facts-body li,
.key-facts-modal.dark_theme_metric_modal .modal-body li{
  color: rgb(181, 192, 202);
}

.dark_theme_container .Accenture-Key-Facts .key-facts-body li span,
.dark_theme_container .Accenture-Key-Fact .label14-medium-opsblue.cursor.float-right,
.key-facts-modal.dark_theme_metric_modal .modal-body li span{
  color: #00baff;
}

.dark_theme_container .Accenture-Key-Facts .key-facts-body li::before,
.key-facts-modal.dark_theme_metric_modal .modal-body ul li::before{
  background: #00baff;
}
.dark_theme_container .mainContainerCC-overview, .dark_theme_container .gr-smoke-pnt5Opacity, .business_outcome_dark_theme .container-fluid, .business_outcome_dark_theme .gr-smoke-pnt5Opacity{
  background: rgb(33, 43, 54);
  min-height: auto !important;
}
.business_outcome_dark_theme .favouriteapp-heading h3, .dark_theme_container .favouriteapp-heading h3, .dark_theme_container .favouriteBO-list ul li h4, .dark_theme_container .FavoriteBusinessOutcomes h6, .dark_theme_container .initiatvecommon-heading h4, .dark_theme_container .deployedinitiatives .shadow-sm .header16-semibold-midnight, .dark_theme_container .deployedinitiatives .shadow-sm .header24-semibold-midnight, .dark_theme_container .custom-dropdown-search .form-check .form-check-label, .dark_theme_container .doughnutchart-middletext span, .dark_theme_container .doughnutlegend-values li b, .dark_theme_container .accenture-key-facts h6, .business_outcome_dark_theme .Reference h3, .business_outcome_dark_theme .Reference .label12-regular-midnight{
  color: rgb(255, 255, 255) !important;
}
.dark_theme_container .initiatvecommon-heading .grey-text{
  color: rgb(249, 250, 251) !important;
}
.dark_theme_container .fiscalyear-dropdown .custom-dropdown-search, .dark_theme_container .deployedinitiatives .shadow-sm, .dark_theme_container .customselectfilter-footer{
  background: #37404a;
}
.dark_theme_container .search-select-list ul li:hover,
.dark_theme_container .fiscalyear-dropdown .custom-dropdown-search{
  background: rgb(0,186,255);
}
.dark_theme_container .fiscalyear-dropdown .custom-dropdown-search span{
  color: white;
}
.dark_theme_container .form-check .checkmark{
  background: #637381;
}
.dark_theme_container .fiscalyear-dropdown .custom-dropdown-search, .view_summary_dark_theme .fiscalyear-dropdown .custom-dropdown-search{
  border: 1.5px solid rgb(0,186,255);
}
.dark_theme_container .custom-dropdown-search .search-select-list, .view_summary_dark_theme .custom-dropdown-search .search-select-list{
  background: #637381;
}
.dark_theme_container .custom-dropdown-search input[type="checkbox"].form-check-input:checked~.checkmark, .view_summary_dark_theme .custom-dropdown-search input[type="checkbox"].form-check-input:checked~.checkmark{
  background: rgb(255,255,255);
  border-color: rgb(255,255,255);
}
.dark_theme_container .custom-dropdown-search .form-check .checkmark:after, .view_summary_dark_theme .custom-dropdown-search .form-check .checkmark:after{
  border-color: rgb(0, 186, 255);
}
.dark_theme_container .customselectfilter-footer .btn.apply-button, .view_summary_dark_theme .customselectfilter-footer .btn.apply-button{
  background: rgb(0, 186, 255)!important;
  color: rgb(0, 0, 0);
  border-color: rgb(0, 186, 255)!important;
}
.dark_theme_container .customselectfilter-footer .btn.apply-button:disabled{
  opacity: 0.7;
}
.dark_theme_container .customselectfilter-footer .btn.clear-button, .view_summary_dark_theme .customselectfilter-footer .btn.clear-button{
  background: #37404a;
  color: rgb(0, 186, 255);
  border-color: rgb(0, 186, 255) 
}
.dark_theme_container .doughnutlegend-values li p,
.dark_theme_container .favouriteapp-list p,
.business_outcome_dark_theme .favouriteapp-list p,
.dark_theme_container .HighlightsForYou .tooltipellipsis-text div,
.dark_theme_container .highlights-for-you-description h6,
.dark_theme_container .favouriteBO-list ul li p,
.dark_theme_container .key-facts-body .bodytext14-regular-slate{
  color: rgb(181, 192, 202);
}
.dark_theme_container .favourite-apps .favouriteapp-list h4, .dark_theme_container .favourite-apps .favouriteapp-list .view-summary, .business_outcome_dark_theme .favourite-apps .favouriteapp-list h4, .business_outcome_dark_theme .favourite-apps .favouriteapp-list .view-summary, .dark_theme_container .seeall-link span a, .business_outcome_dark_theme .seeall-link span a, .dark_theme_container .click-casestudy, .fav_list_dark_container h4, .fav_list_dark_container .view-summary, .dark_theme_container .seeall-link a, .business_outcome_dark_theme .Reference .label14-medium-opsblue{
  color: rgb(0, 186, 255) !important;
}
.dark_theme_container .key-facts-body .bodytext14-regular-slate{
  color: rgb(181, 192, 202);
}
.dark_theme_container .favouriteBO-list ul li {
  border-bottom: 1px solid #37404a;
}
.dark_theme_container .AccentureKeyFacts {
  border-left: 1px solid #37404a;
}
/* SVM Starts */
.dark_theme_container .tab-content .custom-btn, .reference_page.dt_reference_page .custom-hover-dropdown .dropdown-toggle, .dt_reference_page .add_section_btn .section_btn{
  background: #000 !important;
  border: 1.5px solid rgb(0, 186, 255) !important;
  color: rgb(0, 186, 255) !important;
}
.dark_theme_container .dropdown-section .custom-hover-dropdown .dropdown-toggle{
  background: transparent !important;
  border: 1.5px solid rgb(0, 186, 255) !important;
  color: rgb(0, 186, 255) !important;
}
.reference_page.dt_reference_page .custom-hover-dropdown .dropdown-toggle:hover,
.dark_theme_container .dropdown-section .custom-hover-dropdown .dropdown-toggle:hover,.dark_theme_container .dropdown-section .custom-hover-dropdown .dropdown-item:focus {
  background-color: rgb(0, 186, 255) !important;
  color: white !important;
}
.dark_theme_container .dropdown-section .custom-hover-dropdown .dropdown-menu , .dt_reference_page .custom-hover-dropdown .dropdown-menu, .dt_reference_page .dropdown-menu{
  background: #637381;
  color: #fff;
}
.dark_theme_container .dropdown-section .custom-hover-dropdown .dropdown-item, .dt_reference_page .dropdown-item {
  border: none;
  color: #fff !important;
}
.dark_theme_container .dropdown-section .custom-hover-dropdown .dropdown-item:hover, .dt_reference_page .dropdown-item:hover, .dt_reference_page .custom-hover-dropdown .dropdown-item.active, .dt_reference_page .custom-hover-dropdown.nested-dropdown-wrapper .offering-droplistwrapper ul li span:hover {
  background: #00baff !important;
}
.dark_theme_container h3.header20-semibold-midnight, .dark_theme_container .dimension h2, .dark_theme_container .dimension-card .bodytext14-medium-midnight, .dark_theme_container .list-card h6.bodytext14-medium-midnight, .dark_theme_container .dimension-collapse-card h6.bodytext14-medium-midnight, .dark_theme_container .tab-content h3, .dark_theme_container .project-details h5, .dt_reference_page .custom-hover-dropdown.nested-dropdown-wrapper .offering-droplistwrapper ul li span{
  color: rgb(255, 255, 255);
}
.dark_theme_container .dimension-card, .dark_theme_container .list-card, .dark_theme_container .dimension-collapse-card{
  background: rgb(21, 24, 27);
}
.dark_theme_container .dimension-collapse-card{
  padding: 0 0.4rem;
}
.dark_theme_container .MuiSvgIcon-fontSizeSmall{
  background: #00baff;
  color: #000;
}
.dark_theme_container .project-details{
  background: rgb(31, 37, 40);
  border: 1px solid rgba(255, 255, 255, 0.2);
}
.dark_theme_container .project-details .project-detail-tabs button[aria-selected="true"] {
  border-bottom-color: #be82ff;
}
/* .dark_theme_container .project-detail-tabs button span,
.dark_theme_container .project-detail-tabs.nav-tabs .nav-link.active,
.dark_theme_container .project-detail-tabs.nav-tabs .nav-item.show .nav-link,
.dark_theme_container .project-detail-tabs.nav-tabs .nav-link.active:after{
  background: #be82ff !important;
} */
.dark_theme_container .project-details-button{
  border: 1px solid #be82ff;
  color: #be82ff;
}
.dark_theme_container .dimension-card p, .dark_theme_container .status-list span.bodytext14-medium-midnight, .dark_theme_container .tab-content p, .dark_theme_container .project-details p, .dark_theme_container .project-detail-tabs.nav-tabs, .dark_theme_container .project-detail-tabs.nav-tabs .nav-link{
  color: rgb(181, 192, 202);
}
/* SVM Ends */
.user_preference_dark_theme .theme_toggle_container .form-check .checkmark{
  background-color:#000 !important;
  border-color: #fff !important;
}
.user_preference_dark_theme .theme_toggle_container .form-check input[type="radio"]+.checkmark:after{
  border-color: #fff !important;
  background-color:#fff !important;
}
.user_preference_dark_theme .theme_toggle_container .form-check .form-check-label, .user_preference_dark_theme h6, .user_preference_dark_theme h5, .fav_list_dark_container .reference-heading h3, .fav_list_dark_container p{
  color: #fff;
}
.user_preference_dark_theme .UI-modal-body .okay-btn, .fav_list_dark_container .viewmore-btn .btn{
  background: rgb(0, 186, 255) !important;
  color: #000;
}
.user_preference_dark_theme .UI-modal-body .not-btn{
  background: rgb(31, 37, 40);
  border: 1.5px solid rgb(0, 186, 255);
}
.dark_theme_container .highlights-for-you{
  background: rgb(55, 64, 74);
}
.dark_theme_container .tooltipellipsis-text h2{
  color: rgb(231, 234, 238);
}
.fav_list_dark_container, .DT_BO_references, .DT_welcome_screen .modal-content{
  background: #212b36 !important;
}
.modal_dark_theme .modal-content, .fid_dark_theme .criticaloutcome-block{
  background: rgb(31, 37, 40);
}
.modal_dark_theme .pccPageListNagModal.open{
  background: rgb(33, 43, 54);
}
.modal_dark_theme .pccPageListNagModal p:last-child{
  color: rgb(255, 255, 255);
}
.modal_dark_theme .pccPageListNagModal a{
  color: rgb(0, 186, 255);
}
.modal_dark_theme .image-section h3, .modal_dark_theme .image-section h6, .modal_dark_theme .image-section p, .view_summary_dark_theme .modal-header h5, .view_summary_dark_theme .close span, .view_summary_dark_theme .favouritemodal-filter p, .view_summary_dark_theme .summary-outcomes .outcome-card h6, .view_summary_dark_theme .search-select-list ul li, .view_summary_dark_theme .summary-outcomes .outcome-digit .outcome-digittext{
  color: #fff;
}
.modal_dark_theme .modal-header .close span{
  color: rgb(255, 255, 255);
}
.view_summary_dark_theme .modal-content{
  background: rgb(31, 37, 40);
}
.view_summary_dark_theme .favorite-app-viewsmmary-dropdown .dropdown-toggle{
  background: rgb(31, 37, 40) !important;
  border: 1.5px solid rgb(0, 186, 255) !important;
}
.view_summary_dark_theme .favorite-app-viewsmmary-dropdown .dropdown-toggle:hover, .view_summary_dark_theme .favorite-app-viewsmmary-dropdown .dropdown-item:hover, .view_summary_dark_theme .favorite-app-viewsmmary-dropdown .dropdown-item.active, .view_summary_dark_theme .favorite-app-viewsmmary-dropdown.nested-dropdown-wrapper .offering-droplistwrapper ul li span:hover, .view_summary_dark_theme .favorite-app-viewsmmary-dropdown.nested-dropdown-wrapper .offering-droplistwrapper ul.nested-ul>li a.active{
  background-color: rgb(0, 186, 255) !important;
  color: white !important;
}
.view_summary_dark_theme .favorite-app-viewsmmary-dropdown .white_icon{
  display: none;
}
.view_summary_dark_theme .favorite-app-viewsmmary-dropdown .arrow_icon{
  transform: rotate(90deg);
  width: 26px;
}
.view_summary_dark_theme .favorite-app-viewsmmary-dropdown .dropdown-toggle .arrow_icon{
  padding-right: 0.5rem;
}
.view_summary_dark_theme .favorite-app-viewsmmary-dropdown .dropdown-menu{
  background: #637381;
}
.view_summary_dark_theme .favorite-app-viewsmmary-dropdown .dropdown-item, .view_summary_dark_theme .favorite-app-viewsmmary-dropdown.nested-dropdown-wrapper .offering-droplistwrapper ul li span{
  color: #fff;
}
.view_summary_dark_theme .favorite-app-viewsmmary-dropdown .dropdown-toggle, .view_summary_dark_theme .summary-outcomes .outcome-card .outcome-digit {
  color: rgb(0, 186, 255) !important;
}
.left_menu_dark_theme .modal-content{
  background: rgb(31, 37, 40);
}
.DT_BO_references .referenceheading-left h3, .left_menu_dark_theme .userprofile-info p, .left_menu_dark_theme .header-leftmenu ul li a, .left_menu_dark_theme h6, .left_menu_dark_theme .close span, .footer_dark_theme .footer-links ul li a, .footer_dark_theme.footer-container p, .business_outcome_dark_theme h5, .business_outcome_dark_theme .metriccard-leftsection p, .business_outcome_dark_theme .Metric-Cards .benchmark-list span{
  color: rgb(255, 255, 255) !important;
}
.business_outcome_dark_theme .Metric-Cards .benchmark-list p{
  color: rgb(145, 158, 171);
}
/* ------------- */
.footer_dark_bg{
  background: #000;
}
.footer-container .footer-logos .footerlogos-left p a{
  color: rgb(145, 158, 171) !important;
  text-decoration: none;
}
.footer_dark_theme .footer-logos .footerlogos-left p a{
  color: rgb(255, 255, 255) !important;
  text-decoration: none;
}
.footer_border{
  border-right: 2px solid #9e42f7 !important;
  border-radius: 0.5px;
  height: 20px;
  margin-left: 1rem;
  margin-right: 1rem;
}
/* ------------ */
.dark_theme_metric_modal .modal-content, .dark_theme_metric_modal .metriccards-chartsection, .dark_theme_metric_modal .metriccards-chartsection .multi-metric-dropdown .dropdown-menu .dropdown-header .searchFilter input{
  background: rgb(31, 37, 40) !important;
  border: 1px solid #37404a;
  margin:0 auto 15px 
}
.dark_theme_metric_modal .mc-comment-icon{
  background: url('../Images/commentBlue-icon.svg') 0px 0px scroll no-repeat;
}
.dark_theme_metric_modal .setting-alert-icon{
  background: url('../Images/bellOutlineBlue-icon.svg') 0px 0px scroll no-repeat;
}
.dark_theme_metric_modal .multimetric-dropdown .dropdown-menu, .dark_theme_metric_modal .popover-footer, .dark_theme_metric_modal .popover-body, .dark_theme_metric_modal .dropdown-menu, .dark_theme_metric_modal .dropdown-menu .dropdown-item{
  color:rgb(255, 255, 255)!important ;
  background: #637381;
}
.dark_theme_metric_modal .edit-delete-dropdown .dropdown-menu,
.dark_theme_metric_modal .metriccards-chartsection .multi-metric-dropdown .dropdown-menu .dropdown-header .searchFilter input{
  background: #637381;
}

.dark_theme_metric_modal .custom-switch .custom-control-label::after{
  background: white;
}
.dark_theme_metric_modal .custom-control-label::before{
  background-color: transparent;
}

.dark_theme_metric_modal .metriccards-chartsection .metriccards-chartlegend ul li,
.dark_theme_metric_modal .main-box h6,
.dark_theme_metric_modal .metriccards-chartsection .header14-semibold-midnight,
.dark_theme_metric_modal .empty-externalBenchmarkNull,
.dark_theme_metric_modal .defaultscreen p,
.dark_theme_metric_modal .usercomments .character-count p,
.dark_theme_metric_modal .multiMetricViewBtnHldr .groove-toggle .mini-toggle-slider>div,
.dark_theme_metric_modal .chartlegend-dropdown .metric-toggle-threshold .metric-threshold-label,
.dark_theme_metric_modal .modal-content .modal-header span,
.dark_theme_metric_modal .right-section .replycomments-box-user .user-comments-text,
.dark_theme_metric_modal .right-section .replycomments-box-user .usercomments .date-box .datetime,
.dark_theme_metric_modal .main-box .comments-body .left-section .team-box .maincomment .name,
.dark_theme_metric_modal .main-box .comments-body .left-section .team-box .maincomment .datetime,
.dark_theme_metric_modal .main-box .comments-body .left-section .team-box .maincomment .edited-text,
.dark_theme_metric_modal .main-box .comments-body .left-section .team-box .comments-text .user-comments-text,
.dark_theme_metric_modal .main-box .comments-body .left-section .team-box .replybtn,
.dark_theme_metric_modal .main-box .comments-body .left-section .team-box .replycomments-box .reply-box .replycomment span,
.dark_theme_metric_modal .main-box .comments-body .left-section .team-box .replycomments-box .reply-box .replies-text .user-comments-text,
.dark_theme_metric_modal .main-box .comments-body .left-section .team-box .view-hide-btn,
.dark_theme_metric_modal .modal-content button .close,
.dark_theme_metric_modal .right-section .replycoments-main .replycomments-box-others .right-section-color .replycomment .name,
.dark_theme_metric_modal .right-section .replycoments-main .replycomments-box-others .right-section-color .replycomment .datetime,
.dark_theme_metric_modal .user-replycomments-box .replies-text .user-comments-text,
.dark_theme_metric_modal .user-replycomments-box .date-box .datetime,
.dark_theme_metric_modal .user-comments-text,
.dark_theme_metric_modal .edited-text,
.dark_theme_metric_modal .modal-content .modal-body p{
  color: rgb(255, 255, 255) !important;
}
.dark_theme_metric_modal .other-user-bluetext, .dark_theme_metric_modal .annotation-comment{
  color: #00baff;
}
.dark_theme_metric_modal .logged-user-redtext, .dark_theme_reference.referenceModal .errorMsg{
  color: #ff5263;
}
.dark_theme_metric_modal .search-style{
  background-color: rgb(0, 186, 255,0.6);
}
.business_outcome_dark_theme .bg-light{
  background-color: transparent !important;
}
.business_outcome_dark_theme .custom-hover-dropdown .dropdown-toggle, .business_outcome_dark_theme .no-filter-disable, .IOJ_dark_theme .custom-hover-dropdown .dropdown-toggle, .IOJ_dark_theme .no-filter-disable, .DT_BO_references .custom-hover-dropdown .dropdown-toggle, .DT_BO_references .no-filter-disable, .business_outcome_dark_theme .BOTabs .bo-metric-month-dropdown .dropdown-toggle,.IOJ_dark_theme .d-inline-flex .summary-dashboard-wrapper>div, .summary-dashboard-btn.summary-dashboard-wrapper>div, .overview_dark_theme .custom-hover-dropdown .dropdown-toggle {
  background: rgb(0, 0, 0) !important;
  border: 1.5px solid rgb(0, 186, 255) !important;
  color: rgb(0, 186, 255) !important;
  height: 32px;
  padding: 3px 12px;
}
.business_outcome_dark_theme .BOTabs .bo-metric-month-dropdown.show .dropdown-toggle{
  background: rgb(0, 0, 0) !important;
  border: 1.5px solid rgb(0, 186, 255) !important;
}
.business_outcome_dark_theme .BOTabs .bo-metric-month-dropdown .dropdown-toggle:hover .white_icon{
  display: inline;
}
.business_outcome_dark_theme .BOTabs .bo-metric-month-dropdown .dropdown-toggle:hover .arrow_icon{
  display: none;
}
.business_outcome_dark_theme .BOTabs .import-btn{
  background: rgb(0, 0, 0) !important;
  border: 1.5px solid rgb(0, 186, 255) !important;
}
.business_outcome_dark_theme .BOTabs .import-btn:hover{
  background: rgb(0, 186, 255) !important;
}
.business_outcome_dark_theme .BOTabs .bo-metric-month-dropdown .dropdown-menu{
  border: none;
}
.business_outcome_dark_theme .BOTabs .import-btn + .custom-tooltip.place-top::after{
  border-top-color: rgb(33, 43, 54);
}
.business_outcome_dark_theme .BOTabs .import-btn + .custom-tooltip{
  background: rgb(33, 43, 54);
}
.bo-metric-month-dark.bo-metric-month-modal .modal-content, .bo-metric-month-dark.bo-metric-month-modal .modal-footer{
  background: rgb(31, 37, 40);
}
.bo-metric-month-dark.bo-metric-month-modal .modal-header .modal-title, .bo-metric-month-dark.bo-metric-month-modal .modal-body span{
  color: rgb(255, 255, 255) !important;
}
.bo-metric-month-dark.bo-metric-month-modal .modal-footer .cancel-btn{
  background: rgba(0, 0, 0, 0.1);
  color: rgb(255, 255, 255);
  border: 1.5px solid rgb(249, 250, 251);
}
.bo-metric-month-dark.bo-metric-month-modal .modal-header .close{
  color: white;
}
.bo-metric-month-dark.bo-metric-month-modal .bo-month-radio[type='radio'] {
  -webkit-appearance:none;
  width:12px;
  height:12px;
  border: 1px solid rgb(249, 250, 251);
  border-radius:50%;
  outline:none;
}
.bo-metric-month-dark.bo-metric-month-modal .bo-month-radio[type='radio']:before {
  content:'';
  display:block;
  width:88%;
  height:88%;
  margin: 0.6px auto;
  border-radius:50%;
}
.bo-metric-month-dark.bo-metric-month-modal .bo-month-radio[type='radio']:checked:before {
  background: rgb(255, 255, 255);
}
.bo-metric-month-dark.bo-metric-month-modal .bo-month-radio[type='radio']:checked {
  border: 1.5px solid rgb(255, 255, 255);
}
.bo-metric-month-dark.bo-metric-month-modal .modal-body h4{
  color: rgb(231, 234, 238);
}
.DT_BO_references .custom-tooltip{
  background-color: #637381;
}
.DT_BO_references .custom-tooltip.__react_component_tooltip.place-left::after {
  border-left-color: #637381 !important;
}
.business_outcome_dark_theme .custom-hover-dropdown .dropdown-toggle:hover, .IOJ_dark_theme .custom-hover-dropdown .dropdown-toggle:hover, .business_outcome_dark_theme .BOTabs .bo-metric-month-dropdown .dropdown-toggle:hover{
  background-color: rgb(0, 186, 255) !important;
  color: white !important;
}
.business_outcome_dark_theme .custom-hover-dropdown .dropdown-menu-right, .IOJ_dark_theme .custom-hover-dropdown .dropdown-menu-right, .business_outcome_dark_theme .BOTabs .bo-metric-month-dropdown .dropdown-menu, .overview_dark_theme .custom-hover-dropdown .dropdown-menu-right{
  background: #637381;
  color:#fff;
}
.business_outcome_dark_theme .BOTabs .bo-metric-month-dropdown .dropdown-item:hover{
  background: rgb(0, 186, 255);
}
.business_outcome_dark_theme .custom-hover-dropdown .dropdown-menu-right a, .business_outcome_dark_theme .BOTabs a, .IOJ_dark_theme .custom-hover-dropdown .dropdown-menu-right a, .business_outcome_dark_theme .custom-hover-dropdown.nested-dropdown-wrapper .offering-droplistwrapper ul li span, .IOJ_dark_theme .custom-hover-dropdown.nested-dropdown-wrapper .offering-droplistwrapper ul li span, .overview_dark_theme .custom-hover-dropdown .dropdown-menu-right a{
  border: none;
  color: #fff !important;
}
.business_outcome_dark_theme .custom-hover-dropdown .dropdown-item:focus, .IOJ_dark_theme .custom-hover-dropdown .dropdown-item:focus{
  background-color: #018eff !important;
  color: white !important;
}
.business_outcome_dark_theme .custom-hover-dropdown .dropdown-item:active, .IOJ_dark_theme .custom-hover-dropdown .dropdown-item:active{
  background-color: transparent !important;
  color: white !important;
}
.business_outcome_dark_theme .BOTabs a.active{
  color: #fff !important;
  background: #000 !important;
}
.business_outcome_dark_theme .Metric-Cards .metriccard-section{
  background: rgb(31, 37, 40);
  border-radius: 6px;
}
.business_outcome_dark_theme .BOTabs .bodytext12-medium-midnight,.business_outcome_dark_theme .BOTabs .label11-medium-midnight,
.dark_theme_container .HighlightsForYou .header20-semibold-midnight,
.dark_theme_metric_modal .dropdown-menu .dropdown-item, .dark_theme_metric_modal .multimetric-dropdown .multimetrc-suboff{
  color:rgb(255, 255, 255)!important ;
}
.dark_theme_metric_modal .usercomments .usecomments-btn .bodytext12-medium-white{
  color: #000;
}
.dark_theme_metric_modal .modal-content .modal-body .metriccards-chartsection .chartlegend-dropdown .dropdown .dropdown-toggle, .dark_theme_metric_modal .metriccard-datepicker, .dark_theme_metric_modal .metriccards-dropdownsection #country-dropdown{
  background: rgb(31, 37, 40) !important;
  border:1.5px solid rgb(0, 186, 255)!important;
  color:rgb(0, 186, 255)!important ;
}
.dark_theme_metric_modal .right-section .replycomments-box-user .right-section-color, .dark_theme_metric_modal .right-section .replycoments-main .replycomments-box-others .right-section-color {
  background: #30475C !important;
  color:rgb(255, 255, 255);
}
.dark_theme_metric_modal .left-section .team-box .usereplies > div > div, .dark_theme_metric_modal .usercomments > div> div{
  background: rgb(31, 37, 40) !important;
  color: rgb(255, 255, 255);
  border: 1px solid rgb(1, 142, 255);
}
.dark_theme_metric_modal .comments-body .left-section .team-box, .dark_theme_metric_modal .comments-body +.usercomments{
  background-color: rgb(33, 43, 54) !important;
  color:rgb(255, 255, 255);
  border: none !important;
}
.dark_theme_metric_modal .edit-delete-box{
  background: #212b36 !important;
  color: #fff !important;
}
.dark_theme_metric_modal .metriccards-chartsection {
  box-shadow: 30px 30px 30px 30px rgb(0 0 0 / 8%);
}
.dark_theme_metric_modal .chartlegend-dropdown .dropdown-toggle:after{
  background-image: url(/src/Images/rightarrow-blue.svg) !important;
}
.dark_theme_metric_modal .modal-content .modal-body .bodytext16-medium-slate .header16-semibold-opsblue{
  color: rgb(0, 186, 255)!important;
}

.dark-theme-metric-alert.metric-alert-settings-config .heading,
.dark-theme-metric-alert.metric-alert-settings-config .alert-setings-body .row div:nth-child(2), 
.dark-theme-metric-alert.metric-alert-settings-config .alert-setings-body .row div:nth-child(3),
.dark-theme-metric-alert.alert-settings-config-modal .modal-header .header16-semibold-midnight,
.dark-theme-metric-alert.alert-settings-config-modal .modal-header .close,
.dark-theme-metric-alert.alert-settings-config-modal .modal-body .bodytext14-medium-midnight,
.dark-theme-metric-alert.alert-settings-config-modal .alert-settings-dropdwn .dropdown-toggle .dropdown-header,
.dark-theme-metric-alert.alert-settings-config-modal .alert-settings-dropdwn .dropdown-toggle li,
.dark-theme-metric-alert.alert-settings-config-modal .alert-settings-input,
.dark-theme-metric-alert.alert-settings-config-modal .alert-settings-dropdwn .dropdown-item{
  color: rgb(255, 255, 255);
}

.dark-theme-metric-alert.metric-alert-settings-config .alert-btn{
  background: rgb(0, 186, 255) !important;
  color: rgb(255, 255, 255) !important;
  border: none !important;
}

.dark-theme-metric-alert.metric-alert-settings-config .alert-setings-body{
  background: rgb(31, 37, 40);
}

.dark-theme-metric-alert.metric-alert-settings-config .alert-setings-body .row div:nth-child(1){
  color: rgb(0, 186, 255);
}

.dark-theme-metric-alert.metric-alert-settings-config .heading-2 > div{
  color: rgb(181, 192, 202);
}

.dark-theme-metric-alert.alert-settings-config-modal .modal-content{
  background: rgb(31, 37, 40);
}

.dark-theme-metric-alert.alert-settings-config-modal .alert-settings-dropdwn .dropdown-toggle,
.dark-theme-metric-alert.alert-settings-config-modal .alert-settings-input{
  border: 1.5px solid rgb(0, 186, 255) !important;
  background: rgb(31, 37, 40) !important;
}

.dark-theme-metric-alert.alert-settings-config-modal .modal-footer{
  background: rgb(55, 64, 74);
}

.dark-theme-metric-alert.alert-settings-config-modal .modal-footer .cancel-btn{
  border: 1.5px solid rgb(0, 186, 255);
  color: rgb(0, 186, 255);
  background: rgb(55, 64, 74);
}

.dark-theme-metric-alert.alert-settings-config-modal  .modal-footer .submit-btn,
.dark-theme-metric-alert.alert-settings-config-modal .alert-settings-dropdwn .dropdown-item:hover{
  background: rgb(0, 186, 255);
  color: rgb(255, 255, 255);
}

.dark-theme-metric-alert.alert-settings-config-modal  .alert-settings-dropdwn .dropdown-menu{
  background: rgb(99, 115, 129);
}

.dark_theme_metric_modal .modal-content .modal-body .header-notification-modal ul li h4,
.dark_theme_metric_modal .modal-content .modal-title h5,
.dark_theme_metric_modal  .modal-body li,
.dark_theme_metric_modal  .modal-content h4.bodytext16-medium-midnight,
.dark_theme_metric_modal .metriccards-chartsection .metric-threshold li{
  color:rgb(255,255,255) 
}
.dark_theme_metric_modal .modal-content .nav-tabs .nav-link.active{
  background: transparent !important;
  color:white !important;
}
.main-container .functions-in-delivery-tab .mainContainerCC.fid_dark_theme{
  background: #000000 !important;
}
.fid_dark_theme.team-modal-box.show{
  background: rgba(0, 0, 0, 0.74) !important;
}
.fid_dark_theme .sub-offering-tile-card{
  background: rgb(33, 43, 54);
}
.fid_dark_theme .sub-offering-tile-card .open-btn{
  background: rgb(0, 186, 255);
}
.fid_dark_theme .sub-offering-tile-item .legends .legends-value, .fid_dark_theme .sub-offering-tile-item .heading .head1, .fid_dark_theme .functions-indelivery-tab a, .fid_dark_theme .popover-inner h4, .fid_dark_theme .popover-inner p, .fid_dark_theme .popover-body h5.bodytext16-medium-slate, .fid_dark_theme .popover-body li .bodytext12-regular-slate{
  color: rgb(255, 255, 255) !important;
}
.fid_dark_theme .sub-offering-heading2{
  color: rgb(0, 186, 255);
}
.fid_dark_theme .functions-indelivery-tab .btn-outline-light.active{
  border: 1px solid rgb(255, 255, 255) !important;
  background-color: #000;
}
.fid_dark_theme .__react_component_tooltip.show{
  background: rgb(99, 115, 129);
  box-shadow: none !important;
}
.fid_dark_theme.popoverNotification .popover-body{
  background: rgb(99, 115, 129);
  border-radius: 16px;
}
.fid_dark_theme .__react_component_tooltip.legend-marker-tooltip::before, .fid_dark_theme .__react_component_tooltip.legend-marker-tooltip::after {
  border-bottom: 8px solid rgb(99, 115, 129) !important;
}
.fid_dark_theme .__react_component_tooltip.place-top::before, .fid_dark_theme .__react_component_tooltip.place-top::after{
  border-top: 10px solid rgb(99, 115, 129) !important;
  border-bottom: 0 !important;
}
.fid_dark_theme hr.dash{
  border-top: .1rem dashed #fff;
}
.fid_dark_theme .close, .fid_dark_theme .close:hover, .IOJ_dark_theme .close, .IOJ_dark_theme .close:hover, .view_summary_dark_theme .close, .view_summary_dark_theme .close:hover, .dark_theme_metric_modal .close, .dark_theme_metric_modal .close:hover{
  color: #fff;
  opacity:1 !important;
}
.view_summary_dark_theme .favouritemodal-filter .custom-dropdown-search.custom-dropdown-searchopen .custom-dropdownname:after, .view_summary_dark_theme .favouritemodal-filter .custom-dropdown-search .custom-dropdownname:after{
  background-image: url('../Images/rightarrow-blue.svg');
  transform: rotate(90deg);
}
.view_summary_dark_theme .card,
.view_summary_dark_theme .filtered-records .pmm-recordsrow .record-divider,
.fid_dark_theme .popover {
  background-color: transparent;
}
.view_summary_dark_theme .record-indicate h4{
  color: #fff;
}
.fid_dark_theme .popover .arrow:after{
  border-right:8px solid rgb(99, 115, 129) !important 
}
.fid_dark_theme.team-modal-box .modal-content{
  background: rgb(31, 37, 40);
  border: none;
}
.fid_dark_theme.team-modal-box .modal-content h6, .fid_dark_theme .team-modalbox-text .name, .fid_dark_theme .criticaloutcome-block h5, .fid_dark_theme .criticaloutcome-block span, .fid_dark_theme .criticaloutcome-block p, .fid_dark_theme .criticaloutcome-block h6{
  color: #fff;
}
.dark_theme_container .empty-data p, .business_outcome_dark_theme .empty-data p, .fid_dark_theme .empty-data p, .dark_theme_metric_modal .metriccards-chartsection .empty-data p{
  color: rgb(145, 158, 171);
}
.fid_dark_theme .team-modalbox-text .type{
  color: rgb(231, 234, 238);
}
.fid_dark_theme .team-modalbox-text .mail{
  color: rgb(0, 186, 255);
  text-decoration: underline;
}
.fid_dark_theme .__react_component_tooltip.place-right::before, .fid_dark_theme .__react_component_tooltip.place-right::after,
.fid_dark_theme .custom-tooltip.place-right::after{
  border-right: 8px solid rgb(99, 115, 129) !important;
}
.fid_dark_theme .team-modal-box .img{
  border: 0px solid rgb(255, 255, 255);
}
.dark_theme_header .dropdown-divider{
  border-top:1px solid #37404a !important;
}
.dark_theme_metric_modal .CalenderDatepickerDate{
  color: #fff;
}
.dark_theme_metric_modal .calenderPopoverSpan:hover, .dark_theme_metric_modal .react-datepicker, .dark_theme_metric_modal .react-datepicker__header, .dark_theme_metric_modal .calenderPopoverSpan{
  background: #37404a !important;
  border: 0px #000;
  color: #fff !important;
}
.dark_theme_metric_modal.calenderPopover .react-datepicker__navigation--next:before{
  background-image: url('../Images/darkrightarrow-blue.svg') ;
  transform: none;
}
.dark_theme_metric_modal.calenderPopover .react-datepicker__navigation--previous:before{
  background-image: url('../Images/darkrightarrow-blue.svg') ;
}
.dark_theme_metric_modal .multiMetricViewBtnHldr .groove-toggle .mini-toggle-slider:before {
  background: #fff;
}
.dark_theme_metric_modal .multiMetricViewBtnHldr .groove-toggle .mini-toggle-slider{
  border: 1px solid rgb(255,255,255);
}
.dark_theme_metric_modal .main-box .search-box{
  background:rgb(31, 37, 40) no-repeat scroll 20px !important;
  border: 1px solid #37404a;
}
.dark_theme_metric_modal .main-box .search-coments input[type="search"]{
  background:rgb(31, 37, 40) no-repeat scroll 20px !important;
  height: 28px;
}
.dark_theme_container .sub-offering-tile-item .legend-marker-tooltip, .dark_theme_metric_modal .multi-metric-dropdown .tooltipellipsis-text:hover .tooltipellipsis-hovertext{
  background: #637381 !important;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
}
.dark_theme_metric_modal .metric-card-threshold{
  background: linear-gradient(90deg, rgba(99,115,129,.9) 0%, rgba(48,56,62,.9) 40%, rgba(31,37,40,.9) 100%) !important;
  box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.9);
}
.dark_theme_container .empty-data, .business_outcome_dark_theme .empty-data{
  background: rgb(55, 64, 74);
  color: #fff;
}
.dark_theme_metric_modal .metric-card-threshold .red-color{
  color: #FF5B5B !important;
}
.dark_theme_metric_modal .metric-card-threshold .green-color{
  color: #7ec344 !important;
}
.dark_theme_metric_modal .modal-content h3{
  color: rgb(255, 255, 255);
}
.dark_theme_container .custom-dropdown-search .custom-dropdownname:after, .dark_theme_container .dropdown .btn.btn-outline-gr-slate::after{
  background-image: url(../Images/headerAngleDown-icon.svg);
  transform: rotate(0deg);
}
.dark_theme_header .dropdown-menu, .dark_theme_header .dropdown-menu span, .dark_theme_header .header-dropdown a, .dt-my-cockpit .dropdown-menu{
  background: rgb(31, 37, 40) !important;
  color: white !important;
}
.dark_theme_header .searchFilter input[type="search"] {
  background: rgb(31, 37, 40) !important;
  border: 1px solid #fff;
}
.DT_BO_references .referenceheading-left img{
  width: 32px;
}
.business_outcome_dark_theme .referencelist-card, .DT_BO_references .referencelist-card{
  background: rgb(30, 30, 31) !important;
  border: 0px !important;
}
.dark_theme_header .dropdown-item, .business_outcome_dark_theme .description h4, .DT_BO_references .description h4{
  color: #fff;
}
.business_outcome_dark_theme .description p, .DT_BO_references .referencelist-card .description p, .DT_BO_references .referencelist-card p, .dt-my-cockpit .referencelist-card p{
  color: rgb(181,192,202);
}
.DT_BO_references .searchFilter input[type="search"] {
  background: #212b36 !important;
  border: 1px solid #37404a;
  height: 32px;
}
.DT_BO_references .custom-hover-dropdown .dropdown-toggle:hover, .DT_BO_references .no-filter-disable{
  background: rgb(0,186,255) !important;
  color: #fff !important;
}
.business_outcome_dark_theme .referenceslist-section:hover, .dt-my-cockpit .referenceslist-section:hover{
  border-radius: 8px;
  border: 1px solid rgb(0, 186, 255);
  box-shadow: 0px 0px 8px 0px rgba(0, 186, 255, 0.2);
}
.dt-my-cockpit .fav_list_dark_container .referenceslist-section:hover{
  border:none;
  box-shadow: none;
}
.dt_bo_reference .referenceslist-section:hover{
  background: transparent;
  box-shadow: none;
  border: none;
}
.business_outcome_dark_theme .referenceslist-section .img-holder, .DT_BO_references .referenceslist-section .img-holder, .dt-my-cockpit .referenceslist-section .img-holder{
  background:rgb(30, 30, 31) !important;
  border:1px solid rgb(30, 30, 31) !important;
}
.business_outcome_dark_theme .referenceslist-section .img-holder.external-link, .DT_BO_references .referenceslist-section .img-holder.external-link{
  background: rgb(63, 77, 93) !important;
  border:1px solid rgb(30, 30, 31) !important;
}
.DT_BO_references .viewmore-btn a{
  color: #000 !important;
}
.DT_BO_references .dropdown-menu.show, .dt-my-cockpit .dropdown-menu.show{
  background: rgb(99, 115, 129) !important;
  color: #fff !important;
}
.dt-my-cockpit .BOI-count-dropdown .boi-footer{
  background: rgb(99, 115, 129) !important;
}
.DT_BO_references .custom-hover-dropdown .dropdown-item{
  color: #fff;
}
.DT_landing_page .modal-content{
  background: rgb(31, 37, 40);
}
.DT_landing_page{
  background-color:rgb(31 37 39 / 90%);
}

.DT_welcome_screen .modal-body .left h1,
.DT_welcome_screen .modal-body .left h6,
.DT_welcome_screen .modal-body .left span,
.DT_welcome_screen .modal-body .left p,
.DT_landing_page .modal-body h4,
.DT_landing_page .modal-body h4 span,
.DT_landing_page .modal-body h6,
.DT_landing_page .client-listing .card span,
.DT_landing_page .client-listing .card-footer p{
  color: white !important;
}
.DT_landing_page .modal-content {
  border:1px solid rgba(0, 0, 0, 0.2);
}
.dark_theme_header .searchFilter input[type="search"], .DT_landing_page .searchFilter input[type="search"] {
  background: rgb(31, 37, 40) !important;
  border: 1px solid #37404a !important;
}
.left_menu_dark_theme .modal-dialog, .DT_landing_page .client-listing .card, .DT_landing_page .client-listing .card-footer{
  background: rgb(31, 37, 40);
}
.DT_landing_page .client-listing .card .card-body{
  box-shadow:0px 2px 16px 0px rgb(0 0 0 / 50%) 
}
.IOJ_dark_theme, .dt-my-cockpit, .dt-my-cockpit>.row{
  background-color: #000 !important;
}
.dt-my-cockpit{
  height: auto !important;
}
.IOJ_dark_theme h6, .IOJ_dark_theme .summary-section-card p, .IOJ_dark_theme .label12-medium-silver, .IOJ_dark_theme .grey-dot-col, .IOJ_dark_theme .IOJ-headline-tabs .header20-semibold-midnight, .IOJ_dark_theme .ioj-initiative-modal .boi-card .boi-description, .ioj-initiative-graph-dark.ioj-initiative-modal-graph .modal-header, .ioj-initiative-graph-dark.ioj-initiative-modal-graph .modal-header .close, .ioj-initiative-graph-dark.ioj-initiative-modal-graph .modal-body .boi-value{
  color: #fff;
}
.IOJ_dark_theme .ioj-initiative-modal .boi-card, .ioj-initiative-graph-dark.ioj-initiative-modal-graph .modal-content{
  background: rgb(31, 37, 40);
}
.IOJ_dark_theme .ioj-initiative-modal .boi-card:hover{
  background: rgb(0, 32, 43);
  border: 1px solid rgb(0, 186, 255);
}
.IOJ_dark_theme .ioj-initiative-modal .boi-card h4, .IOJ_dark_theme .ioj-initiative-modal .boi-card h4 span{
  color: rgb(0, 186, 255);
}
.IOJ_dark_theme .summary-section .summary-section-card.iojYouAreHere .legend-dot .grey-dot-col div:nth-last-child(1){
  color: rgb(145, 158, 171);
}
.IOJ_dark_theme h5{
  color: rgb(0, 186, 255);
}
.IOJ_dark_theme .tooltipellipsis-hovertext{
  background: rgb(99, 115, 129) !important;
}
.IOJ_dark_theme .tooltipellipsis-hovertext:after{
  border-right: 14px solid rgb(99, 115, 129) !important;
}
.IOJ_dark_theme .filter-btn-tabs .btn-outline-light{
  color: #fff !important;
  background: #000!important;
  border-color: #000 !important;
}
.IOJ_dark_theme .filter-btn-tabs .btn-outline-light.active{
  border-color: #f8f9fa !important;
}
.IOJ_dark_theme .iojfilter-tabs .nav-tabs .nav-link, .IOJ_dark_theme .iojfilter-tabs .nav-tabs .nav-link:hover, .IOJ_dark_theme .iojfoundation-tab span, .IOJ_dark_theme .iojtilecard-description p, .IOJ_dark_theme .iojtilecard-deliveryinfo span b{
  color: #fff;
}
.IOJ_dark_theme .foundationtile-card, .metriccard-modalbox.dark_theme_metric_modal{
  background: rgb(33, 43, 54) !important;
}
.IOJ_dark_theme .iojtilecard-name h4{
  color: rgb(0, 186, 255);
}
.IOJ_dark_theme .iojtilecard-morebtn span{
  background: rgb(0, 186, 255);
}
.IOJ_dark_theme .iojtilecard-morebtn span svg{
  color: #000;
}
.IOJ_dark_theme .iojtilecard-deliveryinfo span{
  color: rgb(231, 234, 238);
}
.IOJ_dark_theme.ioj-initiative-modal-box{
  background: none;
}
.IOJ_dark_theme.ioj-initiative-modal-box{
  background: #000000;
}
.IOJ_dark_theme.ioj-initiative-modal-box .details-card,.IOJ_dark_theme.ioj-initiative-modal-box .metric-card{
  background: rgb(31, 37, 40);
}
.IOJ_dark_theme.ioj-initiative-modal-box h5, .IOJ_dark_theme.ioj-initiative-modal-box p.para, .IOJ_dark_theme.ioj-initiative-modal-box h4.header20-semibold-midnight{
  color:#fff;
}
.IOJ_dark_theme.ioj-initiative-modal-box span{
  color: #00baff;
}
.IOJ_dark_theme .ioj-initiative-modal .unordered-list::before{
  border-bottom: 2px solid #fff;
}
.IOJ_dark_theme.ioj-initiative-modal-box p,.IOJ_dark_theme.ioj-initiative-modal-box .label12-medium-opsblue{
  color: rgb(0, 186, 255);
}
.IOJ_dark_theme.ioj-initiative-modal-box .bodytext12-regular-midnight{
  color: #e7eaee !important;
}
.IOJ_dark_theme .ioj-initiative-modal .unordered-list .grey-dot{
  background: #fff;
}
.IOJ_dark_theme .ioj-initiative-modal .unordered-list .grey-dot.active {
  background: none;
  box-shadow: none;
  transform: scale(2.5);
}
.IOJ_dark_theme .ioj-initiative-modal .unordered-list .active_text{
  color: rgb(0, 186, 255);
}
.ioj-initiative-modal .unordered-list .active_text{
  font-family:'Graphik-Semibold';
}
.footer_dark_theme {
  background: rgb(30, 30, 31);
  border-top: #000 1px solid;
}
.view_summary_dark_theme .dark, .view_summary_dark_theme .modal-body{
  color: white;
}
.fid_dark_theme .functions-indelivery-tab .btn-outline-light.active, .functions-indelivery-tab .btn-outline-light:hover, .business_outcome_dark_theme .BOTabs .btn-secondary-outline.active, .business_outcome_dark_theme .BOTabs .btn-secondary-outline:hover {
  border: 1px solid rgb(255,255,255) !important;
  box-shadow: none !important;
}
.fid_dark_theme .functions-indelivery-tab .btn-outline-light.active{
  color:#fff !important;
}
.business_outcome_dark_theme .dropdown-item:focus, .business_outcome_dark_theme .dropdown-item:hover, .DT_BO_references .dropdown-item:focus, .DT_BO_references .dropdown-item:hover{
  background:#00baff;
}
.business_outcome_dark_theme .Metric-Cards .metriccard-section:hover, .dark_theme_metric_modal .metriccard-list:hover .metriccard-leftsection{
  border-radius: 7px 0px 0px 7px;
}
.IOJ_dark_theme .iojYouAreHere{
  background: rgb(33, 43, 54);
  width: 304px;
  border-radius: 8px;
}
.IOJ_dark_theme .iojYouAreHere .media{
  padding: 8px 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background: rgb(99, 115, 129);
  margin: 0;
}
.IOJ_dark_theme .iojYouAreHere .media div{
  color: #fff;
}
.dark_theme_container.survey-content .modal-content{
  background: rgb(31, 37, 40);
  border: 1px solid rgb(99, 115, 129);
}
.dark_theme_container.survey-content .modal-content div, .dark_theme_container.survey-content .modal-content label{
  color:#fff 
}
.dark_theme_container input[type="radio"].form-check-input:checked~.checkmark {
  background-color: transparent;
  border-color: #fff;
}
.dark_theme_container.survey-content .modal-content .form-radio-C2 .checkmark:after{
  background-color:#fff;
}
.dark_theme_container.survey-content .modal-content .btn-primary{
  background: rgb(0, 186, 255);
}
.dark_theme_container.survey-content .modal-content .btn-outline-primary{
  color: rgb(0, 186, 255);
  border: 1.5px solid rgb(0, 186, 255);
}
.dark_theme_container.survey-content .survey-border .survey-answer-row{
  border-bottom: 1px solid rgb(63, 77, 89);
}
.dark_theme_container.survey-content .modal-content textarea, .dark_theme_container.survey-content .modal-content .dropdown button{
  background: rgb(31, 37, 40);
  border: 1px solid rgb(181, 192, 202);
  color: rgb(255, 255, 255);
}
.dark_theme_container.survey-content .form-check-C2 .checkmark{
  background: rgb(255, 255, 255) !important;
  border-color: #fff !important;
}
.dark_theme_container.survey-content .form-check-C2 .checkmark:after{
  border: solid rgb(33, 43, 54);
  border-width: 0 3px 3px 0;
}
.dark_theme_container .form-check-C2 input:checked ~ .checkmark ~ .label, .dark_theme_container .form-radio-C2 input:checked ~ .checkmark ~ .label{
  color:#fff;
}
.dark_theme_container .form-check-C2:hover input ~ .checkmark, .dark_theme_container .form-radio-C2:hover input ~ .checkmark{
  background: transparent;
}
.dark_theme_container .dropdown .btn.btn-outline-gr-slate::after{
  top: 23px;
}
.dark_theme_container .dropdown .btn.btn-outline-gr-slate span, .dark_theme_container.survey-content .dropdown-menu a, .dt-my-cockpit .dropdown-menu a, .dt-my-cockpit .custom-hover-dropdown.nested-dropdown-wrapper .offering-droplistwrapper ul li span, .dt-my-cockpit .preference-custom-dropdown.nested-dropdown-wrapper .offering-droplistwrapper ul li span{
  color:#fff;
}
.dark_theme_container.survey-content .dropdown-menu, .dt-my-cockpit .dropdown-menu{
  background: rgb(99, 115, 129);
}
.dark_theme_container .custom-tooltip.show, .dark_theme_metric_modal .custom-BOtooltip.show{
  background: rgb(99, 115, 129) !important;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
}
.dark_theme_metric_modal .custom-BOtooltip.__react_component_tooltip.place-bottom::after {
  border-bottom: 8px solid rgb(99, 115, 129) !important;
}
.dark_theme_container .custom-tooltip:after{
  border-right: 14px solid rgb(99, 115, 129) !important;
  left:-12px !important;
}
.dark_theme_container .custom-hover-dropdown .svm-offering-tooltip.__react_component_tooltip.place-left::after {
  border-left: 8px solid rgb(99, 115, 129) !important;
}
.dark_theme_container .custom-hover-dropdown .svm-offering-tooltip{
  background: rgb(99, 115, 129) !important;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
}
.dark_theme_container .custom-hover-dropdown .dropdown-custom-tooltip{
  background: rgb(99, 115, 129) !important;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
}
.dark_theme_container .custom-hover-dropdown .dropdown-custom-tooltip.__react_component_tooltip.place-bottom::after{
  border-bottom: 8px solid rgb(99, 115, 129) !important;
}
.left_menu_dark_theme .header-leftmenu ul li a:hover {
  color: rgb(0, 186, 255)!important;
}
.scroll.dark_theme.modal-open{
  overflow: hidden !important;
}
.DT_landing_page_overlay{
  opacity:0.9;
}
.DT_welcome_screen .modal-body .left img{
  border:none !important;
}
.mainContainerCC-overview .shadow-sm .pt-1{
  padding-top: 0.471rem !important;
}
.dark_theme_metric_modal .metriccard-list{
  background: rgb(99,115,129) !important 
}
.fid_dark_theme.ioj_dark_theme .popover .arrow:after{
  border-right: none !important;
  border-left-color: rgb(99, 115, 129);
}
.dark_theme_metric_modal .metriccard-metrictype{
  background:#5A5F62;
  color: rgb(255, 255, 255);
}
.dark_theme_metric_modal.calenderPopover.popover{
  background: rgb(99, 115, 129);
  border: none !important;
}
.dark_theme_metric_modal.calenderPopover .popover-body{
  border-radius: 16px;
}
.dark_theme_metric_modal.calenderPopover .popover-footer{
  background: rgb(55, 64, 74);
  border: none;
}
.dark_theme_metric_modal.calenderPopover .popover-footer .btn-outline-secondary{
  border: 1.5px solid rgb(0, 186, 255);
  color: rgb(0, 186, 255);
}
.dark_theme_metric_modal.calenderPopover .popover-footer .btn-primary{
  background: rgb(0, 186, 255);
}
.dark_theme_metric_modal.calenderPopover .react-datepicker__header, .dark_theme_metric_modal.calenderPopover .react-datepicker .react-datepicker__month-container{
  background: rgb(99, 115, 129) !important;
}
.dark_theme_metric_modal.calenderPopover .react-datepicker__header{
  border-bottom: 1px solid rgb(181, 192, 202);
}
.dark_theme_metric_modal.calenderPopover .calenderPopoverSpan{
  background: rgb(99, 115, 129) !important;
  border: 1px solid rgb(255, 255, 255);
}
.dark_theme_metric_modal .calenderPopoverSpan:hover,.dark_theme_metric_modal .calenderPopoverSpanActive {
  border: 2px solid rgb(0, 186, 255);
  background: rgba(145, 158, 171, 0.5);
}
.dark_theme_metric_modal.calenderPopover .CalenderDatepickerLabel, .DT_BO_references .reference-grid .ag-header-cell-text, .DT_BO_references .ag-theme-alpine .ag-paging-panel, .DT_BO_references .reference-grid .FileName, .DT_BO_references .reference-grid .ag-theme-alpine .ag-header .ag-icon{
  color: #fff;
}
.dark_theme_metric_modal.calenderPopover .react-datepicker__month .react-datepicker__month-text{
  color: rgb(255, 255, 255);
  background: rgb(145, 158, 171);
  border-radius: 0px;
  border: 1px solid rgb(0, 186, 255);
}
.dark_theme_metric_modal.calenderPopover .react-datepicker__month .react-datepicker__month-text:hover, .dark_theme_metric_modal.calenderPopover .react-datepicker__month .react-datepicker__month--selected {
  background: rgb(0, 186, 255);
}
.dark_theme_metric_modal.calenderPopover .react-datepicker__month .react-datepicker__month--disabled{
  background: rgb(99, 115, 129);
  border-radius: 0px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: rgb(181, 192, 202);
}
.dark_theme_container .doughnut-layout .empty-data{
  border: 12px solid rgb(55, 64, 74);
}
.dark_theme_metric_modal.calenderPopover .react-datepicker__month-container{
  border: 1px solid rgb(181, 192, 202);
}
.dark_theme{
  background: #000000;
}
.DT_BO_references .reference-grid .Description, .DT_BO_references .reference-grid .Date{
  color: rgb(231, 234, 238);
}
.DT_BO_references .custom-hover-dropdown .dropdown-toggle{
  border: 1.5px solid rgb(0, 186, 255) !important;
  color: rgb(0, 186, 255) !important;
  background: transparent !important;
}
.DT_BO_references .ag-theme-alpine .ag-row, .DT_BO_references .reference-grid .ag-theme-alpine .ag-header{
  border-bottom: 1px solid #485662 !important 
}
.DT_BO_references .ag-input-field-input{
  background: rgb(31, 37, 40) !important;
  color: rgb(255, 255, 255)!important;
  opacity: 0.5;
}
.DT_BO_references .referenceslist-section .img-holder, .DT_BO_references .reference-grid .img-holder{
  background: rgb(30, 30, 31) !important;
}
/* .main-container .container-fluid {
  min-height: 100vh ;
}
*/
.dark_theme_reference.referenceModal .input-group .inputText:-webkit-autofill{
  box-shadow: 0 0 0 1000px rgb(31,38,40) inset !important;
  -webkit-text-fill-color: #fff;
}
.IOJ_dark_theme .ioj-modal .grey{
  background: rgba(231, 234, 238,0.2) !important;
  color: rgb(255, 255, 255) !important;
  border: 1.5px solid rgb(145, 158, 171)!important;
}
.IOJ_dark_theme .ioj-modal button.orange{
  background: rgba(244, 147, 66,0.2) !important;
  border: 1.5px solid rgb(192, 87, 23) !important;
}
.IOJ_dark_theme .ioj-modal button.blue{
  background: rgba(171, 209, 211, 0.14);
  border: 1.5px solid rgb(38, 193, 201);
}
.IOJ_dark_theme .iojtilecard-statusname.inconcept-status{
  color: #fff;
}
.dt_reference_page{
  background: rgb(0, 0, 0);
}
.dt_reference_page .referenceheading-left h3{
  color: rgb(255, 255, 255);
}
.dt_reference_page .nav-tabs{
  border-bottom: 1px solid rgba(255, 255, 255, 0.35) !important;
}
.dt_reference_page .dropdown-toggle{
  border: 1.5px solid rgb(0, 186, 255) !important;
  background: #000 !important;
  color: rgb(0, 186, 255) !important;
  height: 32px;
  padding: 0.2rem 0.5rem !important;
}
.dt_reference_page .searchFilter input{
  border: 1px solid rgb(99, 115, 129);
  color: rgb(99, 115, 129);
  background-color: #000 !important;
}
.dt_reference_page .nav-tabs .nav-link.active{
  background-color: #000;
  color: rgb(255, 255, 255);
}
.dt_reference_page.reference_page .nav-tabs, .dt_reference_page.reference_page .referenceheading-left, .dt_highlights .ag-theme-alpine .ag-row{
  border-bottom: 1px solid rgba(255, 255, 255, 0.35) !important;
}
.dt_reference_page .no_ref_text{
  color: rgb(99, 115, 129);
}
.dt_reference_page .appearsBO .referenceBO .empty_card{
  background: rgb(30, 30, 31);
  border-radius: 8px;
}
.dt_reference_page .referenceBO .empty_card h3{
  color: rgb(145, 158, 171);
}
.dt_reference_page .referenceBO .empty_card .refrencecard{
  border: 1px solid transparent !important;
  box-shadow: none !important;
}
.dt_reference_page .referenceBO .empty_card .refrencecard:hover{
  border: 1px solid #008eff !important;
}
.dark_theme_reference.referenceModal .modal-content, .dark_theme_reference.referenceModal .input-group .inputTextarea, .dark_theme_reference.referenceModal .input-group .inputTextarea:active, .dark_theme_reference.referenceModal .input-group .inputTextarea:focus, .dark_theme_reference.referenceModal .input-group .floating, .dark_theme_reference.referenceModal .dropdown-toggle, .helpfeatureNotification-modal.dt_reference_popup .modal-content, .casestudy-detailmodal.dt_casestudy .modal-content, .casestudy-detailmodal.dt_casestudy .modal-title.casestudy-title, .helpfeatureNotification-modal.dt_reference_popup .helpfeaturenotification-body select{
  background: rgb(31, 37, 40);
  color:white !important;
}
.helpfeatureNotification-modal.dt_reference_popup .helpfeaturenotification-body select{
  border: 1px solid rgb(223, 227, 232);
}
.casestudy-detailmodal.dt_casestudy .modal-content .morMaximize .btn-outline-secondary {
  background: rgb(31, 37, 40);
  color:white !important;
  border-color: white;
}
.dark_theme_reference.referenceModal .reference_border{
  background: rgba(255, 255, 255, 0.35);
  border-radius: 0.5px;
}
.dt_reference_page .unselected_card span.text, .dt_reference_page .selected_card span.text {
  color: rgb(255, 255, 255) !important;
}
.dt_reference_page .unselected_card, .dt_reference_page .selected_card {
  background: rgb(99, 115, 129) !important;
}
.dark_theme_reference.referenceModal .reference-popup-dropdown .dropdown-menu, .dark_theme_reference.referenceModal .reference-popup-dropdown .dropdown-toggle{
  background: rgb(31, 37, 40) !important;
  border-radius: 8px;
  border: 1px solid rgb(181, 192, 202);
  color:white;
}
.dark_theme_reference.referenceModal .card_large, .dark_theme_reference.referenceModal .card_medium, .dark_theme_reference.referenceModal .card_small{
  background: rgb(31, 37, 40);
  color:white;
  border-radius: 0px;
  border: 1px solid rgb(181, 192, 202);
}
.dark_theme_reference.referenceModal .default_card_selected{
  border: 1px solid rgb(0, 186, 255);
  box-shadow: 0px 0px 4px 1px rgb(0 186 255);
}
.dark_theme_reference.referenceModal .modal-header h3.header16-semibold-midnight, .dark_theme_reference.referenceModal .filetype, .dark_theme_reference.referenceModal .label14-medium-midnight, .dark_theme_reference.referenceModal .label12-medium-midnight, .dark_theme_reference.referenceModal .input-group .inputTextarea .floating, .dark_theme_reference.referenceModal .card_large p, .dark_theme_reference.referenceModal .card_medium p, .dark_theme_reference.referenceModal .card_small p, .dark_theme_reference.referenceModal .modal-content .casestudy-detailmodal .modal-title.casestudy-title {
  color: white !important;
}
.dark_theme_reference.referenceModal .modal-footer{
  background: rgb(21, 24, 27);
  border:none !important;
}
.dark_theme_reference.referenceModal .reference-popup-dropdown .dropdown-item{
  color:white;
}
.dark_theme_reference.referenceModal .input-group .inputText:active, .dark_theme_reference.referenceModal .input-group .inputText:focus, .dark_theme_reference.referenceModal .inputText{
  background:rgb(31, 37, 40) !important;
  color: white;
}
.reference_page.dt_reference_page .refrencecard .referencelist-card{
  background:rgb(30, 30, 31) !important;
  color: white;
}
.dark_theme_reference.referenceModal .input-group .floating-label{
  color: rgb(145, 158, 171);
}
.dt_reference_page .sectionName, .dt_reference_page .sectionName:hover{
  background: transparent;
  color: rgb(255, 255, 255);
}
.dt_reference_page .referenceslist-section .row h3{
  color: rgb(249, 250, 251);
  font-family: 'Graphik-Regular';
}
.dt_reference_page .add_reference_dd .btn:hover{
  background: #000 !important;
  color: rgb(0, 186, 255) !important;
}
.dt_reference_page .add_reference_dd .btn:focus{
  background: rgb(1, 142, 255) !important;
  color: #fff !important;
}
.reference_page.dt_reference_page .refrencecard .referencelist-card{
  border:none !important;
}
.reference_page.dt_reference_page .refrencecard .description h4, .dt_bo_reference .referencelist-card .description h4{
  color: rgb(255, 255, 255);
}
.reference_page.dt_reference_page .refrencecard .description h5, .dt_bo_reference .referencelist-card .description h5, .reference_page.dt_reference_page .referencelist-card .date-time, .dt_bo_reference .referencelist-card .date-time, .dt-my-cockpit .offering-card .updated-date{
  color: rgb(181,192,202) !important;
}
.reference_page.dt_reference_page .refrencecard .img-holder, .reference_page.dt_reference_page .refrencecard .img-holder img.mb-3{
  background: rgb(63, 77, 93);
}
.reference_page.dt_reference_page .refrencecard .img-holder.external-link{
  border: none !important;
}
.dt_reference_page .referenceBO .refrencecard .img-holder img.mb-3{
  border:none !important;
}
.dark_theme_reference.referenceModal .purple{
  color: rgb(190, 130, 255) !important;
}
.dark_theme_reference.referenceModal .modal-footer h3,
.dark_theme_reference .modal-content .label14-medium-opsblue{
  color: rgb(0, 186, 255);
}
.dark_theme_reference.referenceModal .modal-footer .popup_blue_btn{
  background: rgb(0, 186, 255);
  color: #fff;
  border: none ;
}
.reference_page.dt_reference_page .referenceheading-left.reference_heading{
  border: none !important;
}
.dark_theme_reference.referenceModal .reference-popup-dropdown .text-left{
  color: rgb(145, 158, 171);
}
.dark_theme_reference.referenceModal .close span, .dt_highlights .close span, .dt_reference_popup .close span, .dt_casestudy .close span{
  color: rgb(99, 115, 129);
  text-shadow: none;
}
.dark_theme_reference.referenceModal .custom-control-label::before{
  background: rgb(255, 255, 255);
}
.dark_theme_reference.referenceModal .custom-control-label::after{
  background: rgb(1, 142, 255) !important;
}
.dark_theme_reference.referenceModal .input-group span.floating{
  color: rgb(145, 158, 171) !important;
}
.dt_highlights .modal-content, .dt_highlights .ag-root-wrapper.ag-layout-normal{
  background-color: rgb(31, 37, 40);
}
.dt_highlights .highlights-header h3, .dt_highlights .ag-theme-alpine .ag-ltr .ag-cell, .dt_highlights .highlightConfigModal .ag-theme-alpine .ag-paging-panel{
  color:#fff;
}
.dt_highlights .ag-header-container, .dt_highlights .ag-input-field-input, .dt_highlights .ag-text-field-input{
  background-color: rgb(31, 37, 40) !important;
  color: #fff !important;
  background: rgb(31, 37, 40) !important;
}
.delete-section-modal.dt_delete_modal .modal-content{
  background: rgb(31, 37, 40);
  border: 1px solid rgb(99, 115, 129);
  box-shadow: 0px 2px 64px 0px rgb(0 0 0 / 15%);
}
.delete-section-modal.dt_delete_modal .modal-footer{
  border: none;
  background: rgb(21, 24, 27);
  border-top: 1px solid rgb(99, 115, 129);
}
.delete-section-modal.dt_delete_modal .modal-footer .btn-cancel{
  border: 1.5px solid rgb(0, 186, 255);
  color: rgb(0, 186, 255);
}
.delete-section-modal.dt_delete_modal p{
  color: rgb(239, 242, 245);
}
.delete-section-modal.dt_delete_modal .modal-header h3, .dark_theme_reference .custom-control-input:checked ~ .label-mark::after{
  color:#fff;
}
.dark_theme_reference hr{
  background: rgba(255, 255, 255, 0.35);
}
.dt_reference_page .referenceheading-left .dropdown-toggle:focus:not(:focus-visible), .dt_reference_page .referenceslist-section .dropdown-toggle:focus:not(:focus-visible){
  background: rgb(0, 186, 255) !important;
}
.dark_theme_reference .preview_img{
  border-left: 1px solid rgba(255, 255, 255, 0.35);
}
.IOJ_dark_theme.ioj-initiative-modal-box p.para, .IOJ_dark_theme.ioj-initiative-modal-box p{
  color: rgb(231, 234, 238);
}
.dt_reference_page .refrencecard{
  border: 1px solid transparent;
}
.dt_reference_page .LargeCard:hover, .dt_reference_page .MediumCard:hover, .dt_reference_page .SmallCard:hover{
  border: 1px solid rgb(0, 186, 255);
}
.dark_theme_reference.referenceModal .inputText:active+ .floating-label, .dark_theme_reference.referenceModal .inputText:focus+ .floating-label, .dark_theme_reference.referenceModal .inputTextarea:active+ .floating, .dark_theme_reference.referenceModal .inputTextarea:focus+ .floating{
  color: rgb(0, 186, 255) !important;
}
.dt-my-cockpit .my-cockpit-heading h3, .dt-my-cockpit .my-cockpit-heading h6{
  color: rgb(239, 242, 245);
}
.dt-my-cockpit.add-widget-modal .add-widget-card .metric-cards{
  background: rgb(31, 37, 40);
}
.dt-my-cockpit.add-widget-modal .modal-content, .dt-my-cockpit .preference-footer,
.dt-my-cockpit.my-cockpit .offering-card, .dt-my-cockpit.my-cockpit .empty-data{
  background: rgb(21, 24, 27);
}
.dt-my-cockpit.add-widget-modal .add-widget-card .description{
  color: rgb(249, 250, 251);
  font-family: Graphik-Semibold;
}
.dt-my-cockpit.add-widget-modal .benchmark-listtext p, .dt-my-cockpit.add-widget-modal .benchmark-listtext span .add-widget-modal .add-widget-card .reference-card .date-time{
  color: rgb(231, 234, 238);
}
.dt-my-cockpit.add-widget-modal .modal-body .add-widget-card{
  background: rgb(33, 43, 54);
  box-shadow: 0px 1px 22px 0px rgb(0 0 0 / 10%);
}
.dt-my-cockpit.add-widget-modal .modal-header h3{
  color: rgb(181, 192, 202);
}
.dt-my-cockpit.add-widget-modal .widget-heading, .dt-my-cockpit.add-widget-modal .add-widget-card .favouriteapp p, .dt-my-cockpit.add-widget-modal .modal-content .close, .dt-my-cockpit.my-cockpit .card-heading{
  color: rgb(255, 255, 255);
}
.dt-my-cockpit.add-widget-modal .add-widget-card .reference-card .img-holder{
  background: rgb(99, 115, 129);
  border: 1px solid rgb(55, 64, 74);
}
.dt-my-cockpit.add-widget-modal .add-widget-card .referencelist-card{
  background: rgb(31, 37, 40);
  border: 1px solid rgb(55, 64, 74);
}
.dt-my-cockpit.add-widget-modal .add-widget-card .reference-card h4, .dt-my-cockpit.add-widget-modal .add-widget-card .reference-card p, .dt-my-cockpit .prefernce-text{
  color: rgb(249, 250, 251);
}
.dt-my-cockpit .widget-input-box{
  background: rgb(31, 37, 40);
  border: 1px solid rgb(181, 192, 202);
  color: rgb(255, 255, 255);
}
.dt-my-cockpit .preference-footer .close-btn{
  border: 1.5px solid rgb(0, 186, 255);
  color: rgb(0, 186, 255);
  background-color: transparent;
}
.dt-my-cockpit .preference-footer .save-btn,
.dt-my-cockpit .preference-footer .save-btn-disabled{
  background: rgb(0, 186, 255);
  color: white;
}
.dt-my-cockpit .dropdown .dropdown-toggle,.dt-my-cockpit .no-filter-disable{
  background: rgb(0, 0, 0) !important;
  border: 1.5px solid rgb(0, 186, 255) !important;
  color: rgb(0, 186, 255) !important;
  height: 32px;
  padding: 0.2rem 1rem;
}
.dt-my-cockpit .preference-custom-dropdown.dropdown .dropdown-toggle span{
  color: rgb(0, 186, 255) !important;
}
.dt-my-cockpit .preference-custom-dropdown.dropdown .dropdown-toggle:hover span{
  color: white !important;
}
.dt-my-cockpit .add-btn-widgetmodal{
  background: rgb(0, 186, 255);
  color: #fff;
  border: none;
}
.dt-my-cockpit .preference-custom-dropdown .dropdown-menu a:hover, .dt-my-cockpit .BOI-custom-dropdown .dropdown-item:hover, .BOI-count-dropdown .dropdown-item:hover, .dt-my-cockpit .preference-custom-dropdown.nested-dropdown-wrapper .offering-droplistwrapper ul li span:hover, .dt-my-cockpit .preference-custom-dropdown.nested-dropdown-wrapper .dropdown-item.active, .dt-my-cockpit .preference-custom-dropdown .dropdown-item.active{
  background: rgb(0, 186, 255);
}
.dt-my-cockpit .BOI-custom-dropdown .searchFilter input[type="search"]{
  background: rgb(75, 89, 101) !important;
  border: 1px solid rgb(181, 192, 202);
  border-radius: 11px;
}
.dt-my-cockpit .footer-cancel, .dt-my-cockpit .cancel-button{
  border: 1.5px solid rgb(0, 186, 255) !important;
  color: rgb(0, 186, 255) !important;
  background: transparent !important;
}
.dt-my-cockpit .footer-done{
  background: rgb(0, 186, 255);
}
.dt-my-cockpit .search-apply-btn a{
  color: rgb(0, 186, 255) !important;
  font-family: 'Graphik-Semibold';
}
.dt-my-cockpit .BOI-custom-dropdown .dropdown-item-text.boi-footer{
  background: rgb(75, 89, 101) !important;
  box-shadow: 0px -1px 0px 0px rgb(75 89 101);
}
.dt-my-cockpit .BOI-custom-dropdown .dropdown-item-text{
  background: rgb(99, 115, 129) !important;
}
.dt-my-cockpit .BOI-custom-dropdown input[type="checkbox"].form-check-input:checked~.checkmark{
  background: rgb(0, 186, 255);
}
.dt-my-cockpit.my-cockpit .slick-prev:before, .dt-my-cockpit.my-cockpit .slick-next:before{
  background-color: rgb(55, 64, 74);
}
.dt-my-cockpit .favouriteapp-list p{
  color: rgb(249, 250, 251);
}
.dt-my-cockpit .cockpit-preference-button{
  background: rgb(0, 186, 255);
}
.dt-my-cockpit.delete-widget-modal .modal-content{
  background: rgb(31, 37, 40);
  border: 1px solid rgb(99, 115, 129);
}
.dt-my-cockpit.delete-widget-modal .modal-footer{
  background: rgb(21, 24, 27);
  border: none;
  border-top: 1px solid rgb(99, 115, 129);
}
.dt-my-cockpit .modal-body h6{
  color: rgb(255, 255, 255);
}
.dt-my-cockpit .modal-body p{
  color: rgb(239, 242, 245);
}
.delete-widget-modal.dt-my-cockpit, .add-widget-modal.dt-my-cockpit{
  height: 100% !important;
}
.dt-my-cockpit .refrencecard .referenceslist-section .img-holder{
  background: rgb(99, 115, 129) !important;
  border: 1px solid rgb(55, 64, 74) !important;
}
.dt-my-cockpit .referencelist-card{
  background: rgb(31, 37, 40) !important;
  border: 1px solid rgb(55, 64, 74) !important;
}
.dt-my-cockpit .referencelist-card p.date-time{
  color: rgb(231, 234, 238);
}
.dt-my-cockpit .description h4, .dt-my-cockpit .referencelist-card p{
  color: rgb(249, 250, 251);
}
.dt-my-cockpit .metriccard-list, .dt-my-cockpit .add-widget-modal .add-widget-card .metric-cards{
  background: rgb(31, 37, 40) !important;
}
.dt-my-cockpit .benchmark-listtext p{
  color: rgb(231, 234, 238) !important;
}
.dt-my-cockpit .benchmark-listtext span, .dt-my-cockpit .metriccard-list .metric-description, .dt-my-cockpit .metric-card-threshold span{
  color: rgb(255, 255, 255) !important;
  font-weight: 500;
}
.dt-my-cockpit .see-all-filtering{
  background: rgb(75, 89, 101);
}
.dt-my-cockpit .see-all-filtering .body .dropdown-btn{
  background: rgb(75, 89, 101) !important;
  box-shadow: 0px 1px 0px 0px rgb(65 77 88) !important;
  color: rgb(0, 186, 255);
}
.dt-my-cockpit .see-all-filtering .body .dropdown-section2, .dt-my-cockpit .see-all-filtering .body .click-btn{
  background: rgb(99, 115, 129) !important;
}
.dt-my-cockpit .see-all-filtering .body .dropdown-section2 span, .dt-my-cockpit .see-all-filtering .footer .footer-cancel, .dt-my-cockpit .see-all-filtering .header .heading{
  color:rgb(255, 255, 255) 
}
.dt-my-cockpit .see-all-filtering .header .clear-all, .dt-my-cockpit .see-all-filtering .footer .footer-apply{
  color: rgb(0, 186, 255);
}
.dt-my-cockpit .see-all-filtering .body .dropdown-section2 input[type='radio'].input-size-filter:before{
  width: 9px;
  height: 9px;
  background: rgb(255, 255, 255);
  border: 0.5px solid rgb(132, 90, 178);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.16);
}
.dt-my-cockpit .see-all-filtering .header, .dt-my-cockpit .see-all-filtering .body, .dt-my-cockpit .see-all-filtering .body .dropdown-section1, .dt-my-cockpit .see-all-filtering .body .dropdown-btn, .dt-my-cockpit .see-all-filtering .footer, .dt-my-cockpit .see-all-filtering .footer button{
  border: none;
  border-bottom: 1px solid rgb(65 77 88);
  box-shadow: none !important 
}
.dt-my-cockpit .see-all-filtering .footer .footer-cancel{
  color: rgb(255, 255, 255) !important;
  border: none !important;
  border-right: 0.5px solid rgb(30, 36, 39) !important;
}
.dt-my-cockpit .see-all-filtering .body .dropdown-section2 input[type='radio']:checked:before {
  background: rgb(0, 186, 255);
}
.dark_theme_metric_modal .tooltipinfo-modalheader h3{
  color: rgb(255, 255, 255) !important;
}
.dark_theme_metric_modal .dropdown-toggle:hover:after{
  background-image: url(../Images/headerAngleDown-icon.svg) !important;
  transform: rotate(0deg);
}
.business_outcome_dark_theme .custom-hover-dropdown .dropdown-toggle:hover, .IOJ_dark_theme .custom-hover-dropdown .dropdown-toggle:hover, .dark_theme_metric_modal .dropdown-toggle:hover, .dark_theme_metric_modal .metriccard-datepicker:hover, .dt-my-cockpit .dropdown-toggle:hover, .dark_theme_metric_modal .metriccards-dropdownsection #country-dropdown:hover,
.IOJ_dark_theme .d-inline-flex .summary-dashboard-wrapper > div:hover, .IOJ_dark_theme .summary-dashboard-btn.summary-dashboard-wrapper > div:hover, .overview_dark_theme .custom-hover-dropdown .dropdown-toggle:hover{
  background-color: rgb(0, 186, 255) !important;
  color: white !important;
}
.dt-my-cockpit .perference-button{
  background: rgb(99, 115, 129);
  color: rgb(255, 255, 255);
}
.dark_theme_metric_modal .multimetric-dropdown .white_icon, .dark_theme_metric_modal #multimetric-deopdown:hover .arrow_icon{
  display: none;
}
.dark_theme_metric_modal #multimetric-deopdown:hover .white_icon{
  display: inline;
  transform: scale(0.6);
  position: relative;
  left: 22px;
  top: -1px;
}
.img_width16{
  width:16px;
}
#country-dropdown .icon_white, .metriccard-datepicker .icon_white, #country-dropdown:hover .icon_blue, .metriccard-datepicker:hover .icon_blue{
  display:none;
}
#country-dropdown:hover .icon_white, .metriccard-datepicker:hover .icon_white{
  display:inline;
}
.dark_theme_reference .label_strip{
  background-color: #1f2528 !important;
}
.business_outcome_dark_theme .carousel-tabs .MuiSvgIcon-fontSizeSmall, .fid_dark_theme .carousel-tabs .MuiSvgIcon-fontSizeSmall, .IOJ_dark_theme .carousel-tabs .MuiSvgIcon-fontSizeSmall{
  background: rgb(195, 232, 255) !important;
}
.dark_theme_metric_modal .tooltipellipsis-text:hover .tooltipellipsis-hovertext::after{
  border-right: 26px solid #637381;
}
.dark_theme_header .header-dropdown a:hover{
  background: #212b36 !important;
}
.dark_theme_container .svm-key-updates.border-left:before{
  border-left: 1px solid rgb(63, 77, 89);
}
.dt-my-cockpit .add-widget-modal .modal-body .add-widget-card, .dt-my-cockpit .dummy-data.mobile-view .favouriteapp{
  background: #000 !important;
}
.dt-my-cockpit .dummy-data .add-widget-modal .add-widget-card .reference-card .img-holder{
  background: rgb(99, 115, 129);
  border: 1px solid rgb(55, 64, 74);
}
.dt-my-cockpit .dummy-data .add-widget-modal .add-widget-card .referencelist{
  background: rgb(31, 37, 40);
  border: 1px solid rgb(55, 64, 74);
}
.dt-my-cockpit .dummy-data .add-widget-modal .add-widget-card .reference-card p, .dt-my-cockpit .dummy-data .add-widget-modal .add-widget-card .reference-card h4{
  color: rgb(249, 250, 251);
}
.dt-my-cockpit.metriccard-modalbox .container-fluid .row .mobile-view-style{
  background: rgb(33, 43, 54) !important;
}
.dark_theme_metric_modal.dt-my-cockpit .usercomments .annotation-btn{
  background: rgba(255, 255, 255, 0.1) !important;
  color: rgb(255, 255, 255) !important;
  border: none !important;
}
.dark_theme_metric_modal .usercomments .usecomments-btn .btn.bodytext12-medium-white{
  background: rgb(1, 142, 255) !important;
  color: white !important;
}
.dark_theme_metric_modal .usercomments .cancel-annotation-btn{
  background: rgba(0, 0, 0, 0.1) !important;
  border: 1.5px solid rgb(249, 250, 251) !important;
  color: #fff !important;
}
.dark_theme_metric_modal .usercomments .add-annotation-btn{
  background: #a100ff !important;
  color: rgb(255, 255, 255) !important;
}
.dark_theme_metric_modal .usercomments .add-annotation-btn.btn:disabled{
  background: rgb(33, 43, 54) !important;
  color: #fff !important;
}
.dt-my-cockpit .wiget-seall-height h5, .dark_theme_metric_modal .custom-BOtooltip span{
  color: #fff !important;
}
.dt-my-cockpit.add-widget-modal .add-widget-card .referencelist{
  background: rgb(31, 37, 40);
  border: 1px solid rgb(55, 64, 74);
}
.dt-my-cockpit .border-right{
  border-right: 1px solid rgb(151, 151, 151) !important;
}
.dt-my-cockpit .border-right:last-child{
  border-right: none !important;
}
.dt-my-cockpit .BOI-custom-dropdown .form-check-label, .dt-my-cockpit .BOI-count-dropdown .form-check-label, .dt-my-cockpit .modaltitle-img h5, .dt-my-cockpit.view-summary-cockpit .dark, .dt-my-cockpit .comments-body .team-box .name{
  color: rgb(255, 255, 255) !important;
}
.dt-my-cockpit .dropdown-menu::-webkit-scrollbar{
  width: 3px;
}
.my-cockpit .dropdown-menu .dropdown-body::-webkit-scrollbar-track{
  background: transparent;
}
.dt-my-cockpit .offering-card input::placeholder {
  color: rgb(145, 158, 171);
}
.dt-my-cockpit .chartlegend-dropdown .dropdown-toggle:after {
  top:11px !important;
}
.my-cockpit .empty-data p{
  color: rgb(145,158,171) !important;
}
.dark_theme_metric_modal .edit-comment-selected, .dark_theme_metric_modal .delete-comment-selected {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 3px;
}
.dark_theme_metric_modal .edit-comment, .dark_theme_metric_modal .delete-comment{
  display:none 
}

/* SVM Dark Theme */

.dark_theme_container .svm-dark-theme .data-visualisation-container .project-title .title-section h4, 
.dark_theme_container .svm-dark-theme  .data-visualisation-container .title-section h4,
.dark_theme_container .svm-dark-theme  .data-visualisation-container .bodyText24-Semibold,
.dark_theme_container .svm-dark-theme  .visualization-collapse .card-header button .bodytext18-medium-midnight,
.dark_theme_container .svm-dark-theme .data-visualisation-container #prog-view,
.dark_theme_container .svm-dark-theme  .data-visualisation-container .projectDetails-modal-header h4,
.dark_theme #dashboard-tooltip-right .dashboard-details-wrap h6,
.dark_theme #clientDetails-popover .detail-popup-section .section-title,
.dark_theme_container .svm-dark-theme  .data-visualisation-container .project-tabs-list .body-small,
.dark_theme_container .svm-dark-theme .data-visualisation-container .project-tabs-list .groove-select-dropdown div.selectmenu-options .searchable-dropdown-content .searchable-dropdown-input,
.dark_theme_container .svm-dark-theme .data-visualisation-container .project-tabs-list .groove-select-dropdown div.selectmenu-options .searchable-dropdown-content .searchable-dropdown-input::placeholder,
.dark_theme_container .svm-dark-theme .data-visualisation-container .project-tabs-list .groove-select-dropdown .display-list svg{
  color: white !important;
}

.dark_theme_container .svm-dark-theme .statusDropdown .multi-select-dropdown .css-1pkbwyu-control,
.dark_theme_container .svm-dark-theme .statusDropdown .multi-select-dropdown,
.dark_theme_container .svm-dark-theme .statusDropdown .multi-select-dropdown .css-1sgei2z-control,
.dark_theme_container .svm-dark-theme .reportingYrDropdown .multi-select-dropdown .css-1pkbwyu-control,
.dark_theme_container .svm-dark-theme .reportingYrDropdown .multi-select-dropdown,
.dark_theme_container .svm-dark-theme .reportingYrDropdown .multi-select-dropdown .css-1sgei2z-control,
.dark_theme_container .svm-dark-theme .data-visualisation-container .btn-outline-stat-alternate{
  background: transparent;
} 

.dark_theme_container .svm-dark-theme .data-visualisation-container .project-tabs-list .groove-select-dropdown div#selectmenu-anchor {
    background: transparent !important;
}

.dark_theme_container .svm-dark-theme .reportingYrDropdown .multi-select-dropdown .css-192gmcv-menu,
.dark_theme_container .svm-dark-theme .reportingYrDropdown .multi-select-dropdown .css-192gmcv-menu div,
.dark_theme_container .svm-dark-theme .statusDropdown .multi-select-dropdown .css-192gmcv-menu,
.dark_theme_container .svm-dark-theme .statusDropdown .multi-select-dropdown .css-192gmcv-menu>div{
  background: #37404a;
  color: white;
}

.dark_theme_container .svm-dark-theme .reportingYrDropdown .multi-select-dropdown .css-192gmcv-menu>div div:hover,
.dark_theme_container .svm-dark-theme .statusDropdown .multi-select-dropdown .css-192gmcv-menu>div div:hover{
  background: #a100ff;
}

.dark_theme_container .svm-dark-theme .data-visualisation-container .dm-card-box{
  background: rgb(21, 24, 27);
}

.dark_theme_container .svm-dark-theme .data-visualisation-container .dm-card-details ul li div:first-child,
.dark_theme_container .svm-dark-theme .data-visualisation-container .dm-card-details ul li p,
.dark_theme_container .svm-dark-theme .data-visualisation-container #prog-view-colored,
.dark_theme_container .svm-dark-theme .data-visualisation-container .title-container .fy,
.dark_theme_container .svm-dark-theme .data-visualisation-container .title-container .title,
.dark_theme_container .svm-dark-theme .data-visualisation-container .graph-right .trend-header,
.dark_theme_container .svm-dark-theme .data-visualisation-container .projectDetails-modal-header h5,
.dark_theme #dashboard-tooltip-right .dashboard-details-wrap p,
.dark_theme #clientDetails-popover .close-btn,
.dark_theme #programDetails-popover .close-btn,
.dark_theme #clientDetails-popover .popover-body,
.dark_theme #programDetails-popover .popover-body{
  color: rgb(181, 192, 202) !important;
 }

 .dark_theme_container .svm-dark-theme .data-visualisation-container .project-tabs-list .groove-select-dropdown div#selectmenu-anchor .display-field.field-label,
 .dark_theme_container .svm-dark-theme .data-visualisation-container .project-tabs-list .groove-select-dropdown svg,
 .dark_theme_container .svm-dark-theme .data-visualisation-container .project-tabs-list .body-regular,
 .dark_theme_container .svm-dark-theme .data-visualisation-container .project-detail-tabs button .subtabs-UOM{
   color: #a100ff !important;
 }

 .dark_theme_container .svm-dark-theme .data-visualisation-container .dashboard-wheel-spacing,
.dark_theme_container .svm-dark-theme .data-visualisation-container .dashboard-wheel-vd-spacing{
    fill: black;
  }
  
.dark_theme_container .svm-dark-theme .recharts-layer.recharts-pie-sector path{
  stroke: transparent;
}

.dark_theme_container .svm-dark-theme .data-visualisation-container .project-tabs-list .groove-select-dropdown div.selectmenu-options.display-list,
.dark_theme_container .svm-dark-theme .data-visualisation-container .project-tabs-list .groove-select-dropdown div.selectmenu-options .searchable-dropdown-content,
.dark_theme_container .svm-dark-theme .data-visualisation-container .project-tabs-list .groove-select-dropdown div.selectmenu-options div.dropdown-list{
  background: #37404a;
}

.dark_theme_container .svm-dark-theme .data-visualisation-container .project-tabs-list .groove-select-dropdown div.selectmenu-options.display-list .dropdown-item:hover{
  background: #a100ff !important;
  color: white !important;
}

.dark_theme_container .svm-dark-theme  .data-visualisation-container #legend-container{
  color: rgb(181, 192, 202);
}

.dark_theme_container .svm-dark-theme .visualization-collapse.accordion .card,
.dark_theme_container .svm-dark-theme .visualization-collapse.accordion .card .card-header{
  background: rgb(33, 43, 54);
}

.dark_theme_container .svm-dark-theme .data-visualisation-container .dashboard-projectDetails-modal{
  background: rgb(31, 37, 40);
}

.dark_theme #client-stage-defination.bs-tooltip-bottom .tooltip-inner,
.dark_theme #dashboard-tooltip-right .tooltip-inner,
.dark_theme #clientDetails-popover,
.dark_theme #programDetails-popover{  
  background: #37404a !important;
}

.dark_theme #client-stage-defination.bs-tooltip-bottom .arrow::before,
.dark_theme #dashboard-tooltip-right.bs-tooltip-bottom .arrow:before{
  border-bottom-color: #37404a !important;
}

.dark_theme #clientDetails-popover.bs-popover-right > .arrow::after{
  border-right-color: #37404a !important;
}

.dark_theme_container .svm-dark-theme #wheel-chart-container .recharts-surface svg{
  filter: brightness(0) invert(1);
}

.dark_theme_container .svm-dark-theme #wheel-chart-container .recharts-surface .recharts-layer svg{
  filter: none;
}

.data-visualisation-container .projectDetails-modal-header .fa-times{
  cursor: pointer;
}

/* SVM Dark Theme */


@media only screen and (max-width: 600px) and (min-width: 350px) and (orientation: portrait ){
  .dt-my-cockpit.add-widget-modal .add-widget-card .metric-cards, .dt-my-cockpit.my-cockpit .offering-card, .dt-my-cockpit.my-cockpit .empty-data{
      background: #000 !important;
 }
  .dt-my-cockpit .favouriteapp-list{
      background: rgb(31, 37, 40) !important;
      border: 1px solid rgb(55, 64, 74) !important;
 }
  .dt-my-cockpit .seeall-btn{
      color: rgb(0, 186, 255) !important 
 }
  .dt-my-cockpit.my-cockpit .summary-outcomes .outcome-card h6, .dt-my-cockpit.my-cockpit .summary-outcomes .outcome-digit .outcome-digittext{
      color: #fff;
 }
 .my-cockpit .dark_theme_metric_modal.metriccard-modalbox .container-fluid .row .mobile-view-style{
  background: rgb(31, 37, 40);
}
.dt-my-cockpit .see-all-filtering .body .dropdown-section2 label{
  color: rgb(0, 186, 255);
}
}
@media only screen and (max-width: 900px) and (min-width: 600px) and (orientation: landscape ){
  .dt-my-cockpit.add-widget-modal .add-widget-card .metric-cards, .dt-my-cockpit.my-cockpit .offering-card, .dt-my-cockpit.my-cockpit .empty-data{
      background: #000 !important;
 }
  .dt-my-cockpit .favouriteapp-list{
      background: rgb(31, 37, 40) !important;
      border: 1px solid rgb(55, 64, 74) !important;
 }
  .dt-my-cockpit .seeall-btn{
      color: rgb(0, 186, 255) !important 
 }
  .dt-my-cockpit.my-cockpit .summary-outcomes .outcome-card h6, .dt-my-cockpit.my-cockpit .summary-outcomes .outcome-digit .outcome-digittext{
      color: #fff;
 }
 .my-cockpit .dark_theme_metric_modal.metriccard-modalbox .container-fluid .row .mobile-view-style{
  background: rgb(31, 37, 40);
}
.dt-my-cockpit .see-all-filtering .body .dropdown-section2 label{
  color: rgb(0, 186, 255);
}
}


