body {
  margin: 0;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body, html{
  font-size: 16px;
}

.navBar ul{
  padding: 0;
  margin: 0;
  display: flex;
  list-style: none;
  width: 100%;  
}
.navBar ul a{
  text-decoration: none;
  display: block;
  padding: 15px;
  margin: 0 5px;  
}
@media (min-width: 768px) and (max-width:1023px){
  html{
    font-size: 13px;
  }
}
@media (min-width: 1024px) and (max-width:1200px){
  html{
    font-size: 14px;
  }
}
@media (min-width: 1600px){
  html{
    font-size: 17px;
  }
}
@media (min-width: 1900px){
  html{
    font-size: 18px;
  }
}

html > body > iframe {
  z-index: -1 !important;
}

.owl-carousel {
  z-index: 0 !important;
}

.rmsc.multi-select .dropdown-content {
  display: block;  
}

.MuiTabs-indicator {
  background-color: transparent !important;
}

/***SVM CSS ***/
.data-visualisation-container div[role='tabpanel'] > div {
  left: 18px;
}