.hero80-black-midnight,.hero80-black-white,.hero80-black-opsblue,.hero80-black-alternate,.hero80-medium-midnight,.hero80-medium-white,.hero80-medium-opsblue,.hero80-medium-alternate{
  font-size: 5rem !important;
  letter-spacing: 0 !important;
}
.hero64-black-midnight,.hero64-medium-midnight,.hero64-black-white,.hero64-medium-white,.hero64-black-opsblue,.hero64-medium-opsblue,.hero64-black-alternate,.hero64-medium-alternate{
  font-size: 4rem !important;
  letter-spacing: 0 !important;
}
.customListRedDot {
  list-style: none;
}
.customListRedDot>li {
  position: relative;
}
.customListRedDot>li::marker {
  content: '.';
  font-size: 40px;
  line-height: 0;
  position: absolute;
  left: -15px;
  top: 0px;
}
.hero56-black-midnight,.hero56-medium-midnight,.hero56-black-white,.hero56-medium-white,.hero56-black-opsblue,.hero56-medium-opsblue,.hero56-black-alternate,.hero56-medium-alternate{
  font-size: 3.5rem !important;
  letter-spacing: 0 !important;
}
.hero48-black-midnight,.hero48-medium-midnight,.hero48-black-white,.hero48-medium-white,.hero48-black-opsblue,.hero48-medium-opsblue,.hero48-black-alternate,.hero48-medium-alternate{
  font-size: 3rem !important;
  letter-spacing: 0 !important;
}
.hero32-black-midnight,.hero32-medium-midnight,.hero32-black-white,.hero32-medium-white,.hero32-black-opsblue,.hero32-medium-opsblue,.hero32-black-alternate,.hero32-medium-alternate{
  font-size: 2rem !important;
  letter-spacing: 0 !important;
}
.header28-semibold-midnight,.header28-semibold-white,.header28-semibold-opsblue{
  font-size: 1.75rem !important;
  letter-spacing: 0 !important;
}
.header24-semibold-midnight,.header24-semibold-white,.header24-semibold-opsblue{
  font-size: 1.5rem !important;
  letter-spacing: 0 !important;
}
.header20-semibold-midnight,.header20-semibold-white,.header20-semibold-opsblue{
  font-size: 1.25rem !important;
  letter-spacing: 0 !important;
}
.header18-semibold-midnight,.header18-semibold-white,.header18-semibold-opsblue{
  font-size: 1.125rem !important;
  letter-spacing: 0 !important;
}
.header16-semibold-midnight,.header16-semibold-white,.header16-semibold-opsblue{
  font-size: 1rem !important;
  letter-spacing: 0 !important;
}
.header14-semibold-midnight,.header14-semibold-white,.header14-semibold-opsblue{
  font-size: 0.875rem !important;
  letter-spacing: 0 !important;
}
.bodytext24-medium-midnight,.bodytext24-regular-midnight,.bodytext24-medium-white,.bodytext24-regular-white,.bodytext24-medium-slate,.bodytext24-regular-slate{
  font-size: 1.5rem !important;
  letter-spacing: 0 !important;
}
.bodytext20-medium-slate,.bodytext20-regular-slate,.bodytext20-medium-white,.bodytext20-regular-white,.bodytext20-medium-slate,.bodytext20-medium-slate,.bodytext20-regular-midnight{
  font-size: 1.25rem !important;
  letter-spacing: 0 !important;
}
.bodytext18-medium-midnight,.bodytext18-regular-midnight,.bodytext18-medium-white,.bodytext18-regular-white,.bodytext18-medium-slate,.bodytext18-regular-slate{
  font-size: 1.125rem !important;
  letter-spacing: 0 !important;
}
.bodytext16-medium-midnight,.bodytext16-regular-midnight,.bodytext16-medium-white,.bodytext16-regular-white,.bodytext16-medium-slate,.bodytext16-regular-slate{
  font-size: 1rem !important;
  letter-spacing: 0 !important;
}
.bodytext14-medium-midnight,.bodytext14-regular-midnight,.bodytext14-medium-white,.bodytext14-regular-white,.bodytext14-medium-slate,.bodytext14-regular-slate{
  font-size: 0.875rem !important;
  letter-spacing: 0 !important;
}
.bodytext12-medium-midnight,.bodytext12-regular-midnight,.bodytext12-medium-white,.bodytext12-regular-white,.bodytext12-medium-slate,.bodytext12-regular-slate{
  font-size: 0.75rem !important;
  letter-spacing: 0 !important;
}
.label24-medium-midnight,.label24-regular-midnight,.label24-medium-white,.label24-regular-white,.label24-medium-slate,.label24-regular-slate,.label24-medium-silver,.label24-regular-silver,.label24-medium-opsblue,.label24-regular-opsblue,.label24-medium-green,.label24-regular-green,.label24-medium-red,.label24-regular-red,.label24-medium-yellow,.label24-regular-yellow{
  font-size: 1.5rem !important;
  letter-spacing: 0 !important;
}
.label20-medium-midnight,.label20-regular-midnight,.label20-medium-white,.label20-regular-white,.label20-medium-slate,.label20-regular-slate,.label20-medium-silver,.label20-regular-silver,.label20-medium-opsblue,.label20-regular-opsblue,.label20-medium-green,.label20-regular-green,.label20-medium-red,.label20-regular-red,.label20-medium-yellow,.label20-regular-yellow{
  font-size: 1.25rem !important;
  letter-spacing: 0 !important;
}
.label18-medium-midnight,.label18-regular-midnight,.label18-medium-white,.label18-regular-white,.label18-medium-slate,.label18-regular-slate,.label18-medium-silver,.label18-regular-silver,.label18-medium-opsblue,.label18-regular-opsblue,.label18-medium-green,.label18-regular-green,.label18-medium-red,.label18-regular-red,.label18-medium-yellow,.label18-regular-yellow{
  font-size: 1.125rem !important;
  letter-spacing: 0 !important;
}
.label16-medium-midnight,.label16-regular-midnight,.label16-medium-white,.label16-regular-white,.label16-medium-slate,.label16-regular-slate,.label16-medium-silver,.label16-regular-silver,.label16-medium-opsblue,.label16-regular-opsblue,.label16-medium-green,.label16-regular-green,.label16-medium-red,.label16-regular-red,.label16-medium-yellow,.label16-regular-yellow{
  font-size: 1rem !important;
  letter-spacing: 0 !important;
}
.label14-medium-midnight,.label14-regular-midnight,.label14-medium-white,.label14-regular-white,.label14-medium-slate,.label14-regular-slate,.label14-medium-silver,.label14-regular-silver,.label14-medium-opsblue,.label14-regular-opsblue,.label14-medium-green,.label14-regular-green,.label14-medium-red,.label14-regular-red,.label14-medium-yellow,.label14-regular-yellow{
  font-size: 0.875rem !important;
  letter-spacing: 0 !important;
}
.label12-medium-midnight,.label12-regular-midnight,.label12-medium-white,.label12-regular-white,.label12-medium-slate,.label12-regular-slate,.label12-medium-silver,.label12-regular-silver,.label12-medium-opsblue,.label12-regular-opsblue,.label12-medium-green,.label12-regular-green,.label12-medium-red,.label12-regular-red,.label12-medium-yellow,.label12-regular-yellow{
  font-size: 0.75rem !important;
  letter-spacing: 0 !important;
}
.label11-medium-midnight,.label11-allcaps-midnight,.label11-medium-white,.label11-allcaps-white,.label11-medium-slate,.label11-allcaps-slate,.label11-medium-silver,.label11-medium-opsblue,.label11-allcaps-opsblue,.label11-medium-green,.label11-allcaps-green,.label11-medium-red,.label11-allcaps-red,.label11-medium-yellow,.label11-allcaps-yellow{
  font-size: 0.6875rem !important;
  letter-spacing: 0 !important;
}
.label10-allcaps-midnight,.label10-allcaps-white,.label10-allcaps-slate,.label10-allcaps-silver,.label10-allcaps-opsblue,.label10-allcaps-green,.label10-allcaps-red,.label10-allcaps-yellow{
  font-size: 0.625rem !important;
  letter-spacing: 0 !important;
}
a {
  text-decoration: none;
}
.groove-radio-button .groove-radio-btn-selectable{
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.form-check-radio-btn{
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.form-check-radio-btn .label16-regular-midnight {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}
/* On mouse-over, add a grey background color */
.form-check-radio-btn:hover input ~ .label16-regular-midnight {
  background-color: #ccc;
}
/* When the radio button is checked, add a blue background */
.form-check-radio-btn input:checked ~ .label16-regular-midnight {
  background-color: #2196F3;
}
/* Create the indicator (the dot/circle - hidden when not checked) */
.label16-regular-midnight:after {
  content: "";
  position: absolute;
  display: none;
}
/* Show the indicator (dot/circle) when checked */
.form-check-radio-btn input:checked ~ .label16-regular-midnight:after {
  display: block;
}
/* Style the indicator (dot/circle) */
.form-check-radio-btn .label16-regular-midnight:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}
/* Customize the label (form-check-C2) */
.form-check-C2,.form-radio-C2{
  display: inline-block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* Hide the browser's default checkbox */
.form-check-C2 input,.form-radio-C2 input{
  position: absolute !important;
  opacity: 0 !important;
  cursor: pointer;
  height: 21px !important;
  width: 100%!important;
  left: 20px;
  top: 0;
  z-index: 1;
  display: block;
}
/* Create a custom checkbox and radio */
.form-check-C2 .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid rgb(181, 192, 202);
}
.form-radio-C2 .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: transparent;
  border-radius: 24px;
  border: 1px solid rgb(181, 192, 202);
}
/* On mouse-over, add a grey background color */
.form-check-C2:hover input ~ .checkmark,.form-radio-C2:hover input ~ .checkmark {
  background-color: #ccc;
}
/* When the checkbox is checked, add a blue background */
.form-check-C2 input:checked ~ .checkmark,.form-radio-C2 input:checked ~ .checkmark {
  background-color: #2196F3;
}
.form-check-C2 input:checked ~ .checkmark ~ .label,.form-radio-C2 input:checked ~ .checkmark ~ .label{
  font-family: Graphik-Medium;
  color: rgb(21, 24, 27);
}
/* Create the checkmark/indicator (hidden when not checked) */
.form-check-C2 .checkmark:after,.form-radio-C2 .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
/* Show the checkmark when checked */
.form-check-C2 input:checked ~ .checkmark:after,.form-radio-C2 input:checked ~ .checkmark:after {
  display: block;
}
/* Style the checkmark/indicator */
.form-check-C2 .checkmark:after {
  left: 7px;
  top: 2px;
  width: 7px;
  height: 13px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.form-radio-C2 .checkmark:after {
  position: absolute;
  top: 3px;
  left: 3px;
  height: 16px;
  width: 16px;
  background-color: transparent;
  border-radius: 16px;
  border: 0px;
  background-color: rgb(1, 142, 255);
}
.groove-radio-button input.form-radio-btn[type=radio]:checked:before,.groove-radio-button input.form-radio-btn[type=radio]:checked:after{
  opacity: 0;
}
.mainContainerCC {
  min-height: 95vh;
}
.GTBackDropActive{
  position: fixed;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100vh;
  left:0;
  top:0;
}
.guidedTourElementAdded{
  position: relative;
  z-index: 1001;
}
.guidedTourPopover{
  display: none;
}
.guidedTourPopoverClose{
  background: url(../Images/close_cc_white.svg) 0px 0px scroll no-repeat;
  background-size: 10px 10px;
}
.gtPopActive.guidedTourPopover{
  background: linear-gradient(-225deg, rgb(161, 0, 255) 0%, rgb(117, 0, 192) 52%, rgb(70, 0, 115) 100%);
  border-radius: .5rem;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  min-height: 153px;
  min-width: 300px;
}
.display-table {
  display: table;
}
.display-tabel-row {
  display: table-row;
}
.display-tabel-cell {
  display: table-cell;
}
.w-40 {
  width: 40%;
}
/* Tab Content Scroller */
.carousel-tabs .MuiTab-root {
  margin-top: 0.5rem !important;
  overflow: hidden !important;
  position: relative !important;
  min-width: fit-content !important;
  min-height: 32px !important;
  letter-spacing: 0em !important;
  text-transform: none !important;
  max-width: fit-content !important;
}
.carousel-tabs .MuiSvgIcon-fontSizeSmall{
  width: 24px !important;
  height: 24px !important;
  background: rgb(102, 187, 255,0.3) !important;
  border-radius: 100%;
}
.carousel-tabs .MuiSvgIcon-fontSizeSmall:hover{
  background: #018eff !important;
  color: #fff !important;
}
.carousel-tabs .MuiTabScrollButton-root.Mui-disabled {
  opacity: 0.4 !important;
}
.carousel-tabs .MuiTab-wrapper{
  color: rgb(33, 43, 54);
  font-size: .75rem;
  font-family: Graphik-Semibold;
  font-weight: 600;
  line-height: 12px;
}
.carousel-tabs .PrivateTabIndicator-colorPrimary-2{
  display: none;
}
.modal-header {
  padding: 0rem 1rem !important;
}
.w-30 {
  width: 30%;
}
.w-40 {
  width: 40%;
}
.Mx-Ht-20rem-y-axis-auto {
  overflow-y: auto;
  max-height: 21rem;
}
.Mx-Ht-25rem-y-axis-auto {
  max-height: 25rem;
  overflow-y: auto;
  min-height: 2rem;
}
.w-15 {
  width: 15rem;
}
.w-20 {
  width: 20%;
}
.w-10 {
  width: 10%;
}
.customListBlueDot {
  list-style: none;
}
.customListBlueDot>li, .customListGreyDash>li, .tooltipellipsis-text, .customListGreyDot>li, .DT_BO_references .searchFilter, .DT_landing_page .searchFilter {
  position: relative;
}
.customListBlueDot>li::before {
  content: '.';
  font-size: 40px;
  line-height: 0;
  position: absolute;
  left: -15px;
  top: 0px;
}
.customListGreyDash {
  list-style: none;
}
.customListGreyDash>li::before {
  content: '-';
  font-size: 20px;
  line-height: 14px;
  position: absolute;
  left: -15px;
}
/* Custom Checkbox CSS */
input[type="text"].form-input {
  height: 25px;
  width: 150px;
  margin-top: 15px;
  margin-left: 100px;
}
.form-dropdown {
  margin-top: 15px;
  margin-left: 20px;
  width: 150px;
}
.form-dropdown:focus-visible {
  outline: none;
  border: 1px solid #018eff;
}
.form-dropdown-error {
  border: 1px solid #ff0000;
}
.form-dropdown-error:focus-visible {
  outline: none;
  border: 1px solid #ff0000 !important;
}
input[type="text"].form-input:focus {
  outline: none;
  border: 1px solid #018eff;
}
label.form-check {
  position: relative;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-left: 1.1rem;
  margin-bottom: 0;
  display: inline-flex;
}
label.form-radio {
  position: relative;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-left: 1.1rem;
  margin-bottom: 25px;
}
.form-check input[type="checkbox"], .form-check input[type="radio"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.form-check .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  border-radius: 3px;
}
.form-check input[type="radio"]+.checkmark {
  border-radius: 50%;
}
.checkmark:after {
  left: 4.7px;
  top: 0px;
  width: 5px;
  height: 10px;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  content: "";
  position: absolute;
  display: none;
}
.form-check-input:checked~.checkmark:after {
  display: block;
}
.form-check-input:disabled~.form-check-label, .form-check-input:disabled~.checkmark {
  opacity: 0.7;
}
.form-check input[type="radio"]+.checkmark:after {
  border-radius: 50%;
  height: 8px;
  width: 8px;
  transform: initial;
  left: 50%;
  margin-left: -4px;
  top: 50%;
  margin-top: -4px;
}
.form-check-label {
  vertical-align: middle;
  font-size: 14px;
  position: relative;
  margin-left: 0.235rem;
  bottom: 0.118rem;
}
.tooltipellipsis-text .tooltipellipsis-hovertext {
  display: none;
}
.tooltipellipsis-text:hover .tooltipellipsis-hovertext{
  position: absolute;
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  border-radius: 8px;
  padding-left: 13px;
  align-items: center;
  display: flex;
  bottom: -100px;
  z-index: 999 !important;
}
.tooltipellipsis-text:hover .tooltipellipsis-hovertext::after{
  content: "";
  position: absolute;
  left: 50%;
  top: -15px;
  margin-left: -5px;
  transform: rotate(90deg);
}
.custom-hover-dropdown .dropdown-item {
  font-size: 0.824rem;
  font-family: Graphik-Regular;
  font-weight: normal;
  padding-left: 0.8rem !important;
  min-width: 210px;
  padding: 0.6rem;
  margin-top: 0.25rem;
}
.custom-hover-dropdown .dropdown-menu {
  border-radius: 8px;
  border: none;
  max-height: 236px;
  overflow-y: auto;
}
.custom-hover-dropdown{
  height: 40px;
}
.custom-hover-dropdown .dropdown-toggle {
  font-size: 0.647rem;
  font-family: Graphik-Medium;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.custom-hover-dropdown .dropdown-toggle span {
  font-size: 0.647rem;
  font-family: Graphik-Medium;
}

.custom-hover-dropdown.show .dropdown-toggle {
  background: rgb(250, 245, 254) !important;
  border: 1px solid rgb(160, 85, 245) !important;
  margin: -1px !important;
}
.custom-hover-dropdown .dropdown-item:hover, .custom-hover-dropdown .dropdown-item:active, .custom-hover-dropdown .dropdown-item:focus, .custom-hover-dropdown .dropdown-item.active {
  background-color: #018eff;
  color: white;
}
.custom-hover-dropdown img{
  padding-left: 0.9rem;
  padding-right: 0.4rem;
}

.dropdown-toggle::after,
.dropdown-toggle:after {
  content: none !important;
}
/* Common Slick Slider Styles */
.slick-dots {
  position: absolute;
  bottom: -30px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}
.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button:before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.5;
  height: 8px;
  width: 8px;
  border-radius: 100%;
}
.slick-dots li.slick-active button:before {
  opacity: 1;
}
.slick-prev, .slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 65%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}
/* Till implement the page */
.Business-Outcomes, .IOJTabs {
  padding: 1rem;
}
.slick-prev.slick-disabled, .slick-next.slick-disabled {
  pointer-events: none;
}
.slick-next.slick-disabled:before {
  background-image: url("../Images/rightarrow-grey.svg");
}
.slick-prev.slick-disabled:before {
  background-image: url("../Images/leftarrow-grey.svg");
}
.slick-next:before {
  background-image: url("../Images/rightarrow-blue.svg");
}
.slick-prev:before {
  background-image: url("../Images/leftarrow-blue.svg");
}
.slick-next:before, .slick-prev:before{
  background-size: 20px 20px;
  width: 20px;
  height: 20px;
}
.slick-next:before, .slick-prev:before, .right-arrow:before{
  background-repeat: no-repeat;
  display: inline-block;
  content: "" !important;
}
.slick-next {
  right: 30px;
}
.slick-prev {
  left: -25px;
}
.right-arrow{
  display: inline-block;
  position: relative;
}
.right-arrow:before {
  background-image: url("../Images/rightarrow-blue.svg");
  background-size: 15px 15px;
  width: 15px;
  height: 15px;
}
.filter-btn-tabs .btn-outline-light {
  font-family: Graphik-Semibold;
  font-size: .75rem;
  background-color: transparent;
  border-radius: 1rem !important;
}
.filter-btn-tabs .btn-outline-light.active, .filter-btn-tabs .btn-outline-light:hover {
  border-radius: 1rem !important;
}
.customListGreyDot {
  list-style: none;
}
.popoverNotification .customListGreyDot>li::before {
  top:0;
}
.UserInitial {
  width: 3rem;
  height: 3rem;
}
.BOTabs .btn-secondary-outline {
  font-family: Graphik-Semibold;
  font-size: 12px;
  background-color: transparent !important;
  border-radius: 1rem !important;
}
.BOTabs .btn-secondary-outline.active, .BOTabs .btn-secondary-outline:hover {
  border-radius: 1rem !important;
}
/* Custom Dropdown Search Start */
.custom-dropdown-search .search-select-list {
  min-width: 280px;
  border-radius: 8px;
  position: absolute;
  top: 32px;
  left: 0px;
  z-index: 10;
  border: none;
}
.custom-dropdown-search ul {
  max-height: 197px;
  overflow: auto;
  margin: 0;
  padding: 5px 0;
}
.custom-dropdown-search ul li {
  font-size: 14px;
  font-family: graphik;
  font-weight: normal;
  padding: 10px 12px 10px 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 0;
}
.custom-dropdown-search .form-check .checkmark {
  width: 20px;
  height: 20px;
  top: 0px;
}
.custom-dropdown-search .form-check .checkmark:after {
  left: 6px;
  top: 1px;
  width: 6px;
  height: 12px;
}
.custom-dropdown-search .form-check .form-check-label {
  margin-left: 10px;
  bottom: 0;
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
}
.custom-dropdown-search-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.custom-dropdown-search .custom-dropdownname {
  position: relative;
  width: 100%;
  font-size: 0.85rem;
  cursor: pointer;
}
.custom-dropdown-search .custom-dropdownname:after {
  background-image: url("../Images/rightarrow-blue.svg");
  background-size: 14px 14px;
  background-repeat: no-repeat;
  display: inline-block;
  width: 14px;
  height: 14px;
  content: "";
  transform: rotate(90deg);
  position: absolute;
  right: 0;
  top: 4px;
}
.customselectfilter-footer {
  display: flex;
  justify-content: space-between;
  padding: 1em;
}
.customselectfilter-footer .btn {
  border-radius: 16px;
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  min-width: 65px;
}
.favouritemodal-filter .custom-dropdown-search .search-select-list{
  top: 22px;
  min-width: 235px;
  width: max-content;
  max-width: 280px;
  min-height: 74px;
}
.favouritemodal-filter .custom-dropdown-search .custom-dropdownname:after {
  background-image: url("../Images/rightarrow-grey.svg");
  background-size: 10px 10px;
  width: 10px;
  height: 10px;
  content: "";
  transform: rotate(90deg);
  position: absolute;
  right: -24px;
  top: 6px;
}
.favouritemodal-filter .fiscalyear-dropdown .custom-dropdown-search {
  min-width: 164px;
  width: auto;
  padding: 0 2rem 0 1rem;
  height: 32px;
  box-shadow: none;
}
.favouritemodal-filter .fiscalyear-dropdown .custom-dropdown-search.custom-dropdown-searchopen {
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  max-width: 360px;
}
.favouritemodal-filter .custom-dropdown-search.custom-dropdown-searchopen .custom-dropdownname:after {
  background-image: url(/src/Images/angle-down.svg);
  background-size: 10px 10px;
  transform: rotate(180deg);
}
/* Custom Search Filter */
.searchFilter:after {
  content: "";
  position: absolute;
  width: 24px;
  height: 0px;
  bottom: 0;
  right: 0;
}
.searchFilter input[type="search"] {
  font-family: Graphik-Regular;
  font-size: 0.824rem;
  border-radius: 20px;
  height: 40px;
  margin-bottom: 0;
  padding: 0.294rem 1.765rem 0.294rem 2.353rem !important;
  background-size: 14px!important;
  width: 225px;
  cursor: auto;
  outline: none;
}
.DT_BO_references .searchFilter::before, .DT_landing_page .searchFilter::before{
  background: url(../Images/search-filter.svg) no-repeat scroll 0px !important;
  position: absolute;
  z-index: 1000;
  content: '';
  width: 20px;
  height: 20px;
  left: 13px;
}
.DT_BO_references .searchFilter::before{
  top:5px;
}
.DT_landing_page .searchFilter::before{
  top:10px;
}
.popover {
  border-radius: 1rem !important;
}
.popover-body {
  padding: 1.5rem;
}
input::-webkit-search-decoration, input::-webkit-search-cancel-button {
  display: none;
}
.favouritemodal-filter .fiscalyear-dropdown .custom-dropdownname span{
  font-family: Graphik-Medium;
  font-size: 0.824rem;
  font-weight: 500;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis 
}
.lightgrey-bg{
  min-height: 73vh;
}
.empty-data {
  min-height: 220px;
  width: 100%;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  text-align: center;
}
.empty-data p{
  font-family: Graphik-Medium;
  font-size: 1rem;
  font-weight: 500;
  line-height: 18px !important;
  width: 60%;
}
.viewmore-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.viewmore-btn .btn {
  border-radius: 20px;
  height: 40px;
  min-width: 117px;
  font-size: 14px;
  line-height: 14px;
  padding: 0.75rem 1.5rem;
}
.no-filter-disable{
  border-radius: 16px;
  padding: 0.37rem;
  padding-left: 0.7rem;
  height: fit-content;
  font-size: 0.647rem;
  opacity: 0.5;
  cursor: auto !important;
}
.viewmore-btn .btn, .no-filter-disable{
  font-family: Graphik-Medium;
  font-weight: 500;
}
.no-filter-disable img {
  padding-left: 0.7rem;
  padding-right: 0.3rem;
}
/* Unauthorized Screen CSS Start */
.unauthorized-screen{
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.unauthorized-block{
  background: url(/src/Images/unauthorized-bg.png) 0px 0px scroll no-repeat;
  background-position: center;
  background-size: auto;
  border-radius: 12px;
  max-width: 896px;
  width: 100%;
  text-align: center;
  padding: 2rem;
}
.unauthorized-block p{
  max-width: 540px;
  margin: 0 auto;
}
.deniedaccess-heading{
  display: flex;
  align-items: center;
  justify-content: center;
}
.deniedaccess-heading h4{
  margin-bottom: 0;
}
/* Preloader Animation CSS Start */
._preloader_overlaycontent{
  color: #018eff;
  font-family: Graphik-Semibold;
  font-size: 16px;
  font-weight: 600;
}
._preloader_overlayoverlay {
  position: fixed !important;
  z-index: 10010 !important;
}
._preloader_ovelayspinner {
  border-radius: 5px !important;
}
._preloader_overlayspinner svg{
  display: none;
}
._preloader_overlayspinner{
  width: 100px !important;
}
._preloader_overlayspinner:before{
  background-image: url("../Images/Synops-Loader-transparent.gif");
  height: 100px;
  width: 100%;
  background-size: 80px;
  background-repeat: no-repeat;
  background-position: center center;
}
.DT_preloder_overlay ._preloader_overlayspinner:before{
  background-image: url("../Images/Synops-loader-dark-theme.gif");
}
.groove-toggle .mini-toggle-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 34px;
}
.groove-toggle .switch {
  position: relative;
  display: inline-block;
  width: 160px;
  height: 30px;
  border: 1px solid #dfe3e8;
  border-radius: 100px;
}
.groove-toggle .mini-toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.groove-toggle .mini-toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  height: 25px;
  width: 45px;
  border-radius: 20px;
  background-color: #c4cdd5;
}
.groove-toggle .mini-toggle-slider > div {
  margin-left: 55px;
  width: auto;
}
.groove-toggle .mini-toggle-slider:hover {
  background-color: #919eab;
}
.groove-toggle .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.groove-toggle .mini-toggle-slider:before {
  position: absolute;
  height: 23px;
  width: 24px;
  background-color: #fff;
  -webkit-transition: 0s;
  transition: 0s;
  border-radius: 20px;
  margin: 1px;
  content: "";
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.24);
  margin-left: 2px;
}
.groove-toggle .slider:before {
  position: absolute;
  height: 26px;
  width: 26px;
  background-color: #fff;
  -webkit-transition: 0s;
  transition: 0s;
  border-radius: 100px;
  margin: -4px;
  content: "";
  left: 4px;
  bottom: 4px;
  background: #7ec344;
  height: 28px;
  width: 85px;
  font-family: "Graphik-Medium";
  font-size: 12px;
}
.groove-toggle .switch input {
  display: none;
}
.groove-toggle input:checked + .mini-toggle-slider {
  background-color: #018eff;
  border: none !important;
}
.groove-toggle input:checked + .slider {
  background-color: #ffff;
}
.groove-toggle input:focus + .mini-toggle-slider {
  box-shadow: 0 0 1px #018eff;
}
.groove-toggle input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}
.groove-toggle .mini-toggle-slider.round {
  border-radius: 34px;
}
.groove-toggle .mini-toggle-slider.round:before {
  border-radius: 50%;
}
.groove-toggle input:checked + .mini-toggle-slider:before {
  border: 1px solid #018eff;
  margin-top: 0.85px;
  height: 22px;
  border-radius: 12px;
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}
.groove-toggle input:checked + .mini-toggle-slider:after {
  content: "";
  position: absolute;
  left: 30px;
  bottom: 6px;
  width: 4px;
  height: 13px;
  border: solid #018eff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  padding-bottom: 2px;
}
.groove-toggle input:checked + .slider:before {
  -webkit-transform: translateX(75px);
  -ms-transform: translateX(75px);
  transform: translateX(75px);
  background: #919eab;
}
.groove-toggle .on, .groove-toggle .off{
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  font-size: 12px;
  font-family: Verdana, sans-serif;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 50px;
  text-align: center;
  user-select: none;
}
.groove-toggle .on {
  color: #fff;
  left: 27%;
}
.groove-toggle .off {
  color: #212b36;
  left: 75%;
}
.groove-toggle input:checked + .slider .on, .groove-toggle input:checked + .slider .off{
  display: block;
  user-select: none;
}
.groove-toggle input:checked + .slider .on {
  color: #212b36;
}
.groove-toggle input:checked + .slider .off {
  color: #fff;
}
.groove-toggle .slider.round {
  border-radius: 34px;
}
.groove-toggle .slider.round:before {
  border-radius: 100px;
}
/* Configuration CSS */
.ag-theme-alpine .ag-header{
  background: rgb(0, 142, 255) !important;
  border-radius: 4px !important;
}
.ag-theme-alpine{
  width: 100%;
  height: calc(100vh - 300px);
}
.ag-theme-alpine .ag-paging-panel,.ag-theme-alpine .ag-root-wrapper{
  border: none !important;
}
.ag-theme-alpine .ag-row {
  background: transparent !important;
  border-bottom: 1px solid rgb(223, 227, 232) !important;
}
.ag-theme-alpine .ag-row {
  color: rgb(21, 24, 27) !important;
  font-size: 14px !important;
  font-family: Graphik-Regular !important;
  font-weight: normal;
  line-height: 20px !important;
}
.ag-theme-alpine .ag-paging-panel{
  justify-content: space-between;
}
.ag-header-cell-text{
  color: rgb(255, 255, 255);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
}
.ag-popup{
  height: 0 !important;
  min-height: 0 !important;
}
.errorMsg{
  color: rgb(213, 0, 47);
  font-size: 12px;
  font-family: Graphik-Regular;
  font-weight: normal;
  line-height: 16px;
}
.ag-theme-alpine .ag-header .ag-icon{
  color: white;
}
.tbledit, .tbleditgrey, .tbldelete, .tbldeletegrey{
  content:'';
  position:absolute;
  width:15px;
  height:18px;
  top: 25%;
  cursor: pointer;
}
.tbledit{
  background: url('../Images/editPencilBlue-icon.svg') no-repeat;
  z-index:1 
}
.tbleditgrey{
  background: url('../Images/editPencilGrey-icon.svg') no-repeat;
}
.tbldelete{
  background: url('../Images/deleteBlue-icon.svg') no-repeat;
}
.tbldeletegrey{
  background: url('../Images/deleteGrey-icon.svg') no-repeat;
}
/* Toggle switch */
.custom-control-input:checked ~ .label-mark::before {
  content: ""!important;
  position: absolute!important;
  left: -13px;
  top: 10.5px;
  width: 4px!important;
  height: 10px!important;
  border: solid #212b36!important;
  border-width: 0 2px 2px 0!important;
  transform: rotate(45deg)!important;
  z-index: 1!important;
}

.custom-switch .custom-control-label::before{
  border-radius: 12px;
  height: 24px;
  width: 36px;
  box-shadow: none !important;
  border: 1px solid rgb(181, 192, 202) !important;
  outline: none !important;
}

.custom-switch .custom-control-label::after {
  width: 16px;
  height: 16px;
  background: rgb(181, 192, 202);
  transform: translate(1px, 2px);
  border-radius: 100%;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  transform: translate(15px, 2px);
  border-radius: 100%;
}

/* Custom DatePicker */
.datepicker-dropdown.dropdown-menu {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(223, 227, 232);
  box-shadow: 0px 2px 64px 0px rgb(0 0 0 / 10%);
  border-radius: 8px;
  padding: 15px 20px;
  margin-top: 28px;
  max-width: 400px;
  width: 100%;
}
.datepicker-dropdown table {
  width: 100%;
  border: 1px solid rgb(220, 226, 231);
  border-radius: 4px 4px 0px 0px;
  font-family: Graphik-Medium;
  font-weight: 500;
  padding: 10px;
}
.datepicker-dropdown table thead tr {
  border-top: 1px solid rgb(196, 205, 213);
  border-bottom: 1px solid rgb(196, 205, 213);
}
.datepicker-dropdown table thead th {
  font-size: 16px;
  font-family: Graphik-Medium;
  font-weight: 500 !important;
}
.datepicker-dropdown table thead th.prev {
  margin-left: 25px;
}
.datepicker-dropdown table thead th.prev, .datepicker-dropdown table thead th.next {
  position: relative;
  border: 1px solid rgb(196, 205, 213);
  height: 24px;
  width: 24px;
  background: #fff;
  border-radius: 50%;
  color: #fff;
  font-size: 0;
  margin-top: 10px;
  margin-bottom: 10px;
  align-items: center;
  display: flex;
}
.datepicker table tr td.new, .datepicker table tr td.old {
  color: #999;
}
.datepicker-dropdown table tbody td {
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  border: 1px solid rgb(223, 227, 232);
  padding: 8px;
}
.datepicker-dropdown table thead th.prev:after, .datepicker-dropdown table thead th.next:after {
  content: "";
  background: url('../Images/leftCaret-icon.svg') no-repeat center;
  position: absolute;
  right: 5px;
  top: 5px;
  color: #c4cdd5;
  width: 12px;
  height: 12px;
}
.datepicker-dropdown table thead th.next:after {
  background: url('../Images/rightCaret-icon.svg') no-repeat center;
}
/* Prism Doc */
.casestudy-modalimg .pccv [data-pcc-pagelist]{
  padding-top: 30px !important;
}
.casestudy-modalimg .pccv.pcc-full-screen{
  z-index: 2;
}

.pccv .pcc-status-bar input[type="number"] {
  -webkit-appearance: textfield;
     -moz-appearance: textfield;
          appearance: textfield;
}

.pccv .pcc-status-bar input[type=number]::-webkit-inner-spin-button, 
.pccv .pcc-status-bar input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none;
  width: 0 !important;
  height: 0 !important;
}
/* Prism Doc */

.fa-pencil-alt:before {
  content: "" !important;
}

.fa-trash-alt:before {
  content: "" !important;
}

/* Responsive Code Starts */

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px){
  .BOFilter .dropdown-item, .IOJ-headline-tabs .dropdown-item {
      font-size: 10px;
      padding-left: 0.5rem !important;
      min-width: 130px;
      padding: 0.2rem;
      margin-top: 0.1rem;
 }
  .BOFilter .dropdown-menu, .IOJ-headline-tabs .dropdown-menu {
      max-height: 105px;
 }
  .BOFilter .dropdown-menu.show, .IOJ-headline-tabs .dropdown-menu.show {
      margin-top: 0.1rem;
 }
  .custom-hover-dropdown .dropdown-toggle {
      font-size: 8px;
      padding: 0.3rem;
 }
  .BOFilter img, .IOJ-headline-tabs img {
      padding-left: 0.5rem;
      padding-right: 0.2rem;
 }
  .adminAndConfigHdr .main-container > .container-fluid {
      padding-left: 2% !important;
 }
 .custom-control-input:checked ~ .label-mark::before {
  left: -8px;
  top: 10px;
 }
}
/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px){
  .reference_container .searchFilter input[type="search"]{
      width:140px 
 }
  .custom-hover-dropdown .dropdown-toggle{
      padding:0.5rem;
 }
 .custom-control-input:checked ~ .label-mark::before {
  left: -9px;
  top: 10px;
 }
}
/* large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and ( max-width: 1359.98px){
}
/* Extra large devices (large desktops, 1600px and up) */
@media (min-width: 1600px){
  .main-container > .container-fluid,
  .adminAndConfigHdr ~ .main-container > .container-fluid.metric-alert-settings-config {
      padding-left: 7.5% !important;
      padding-right: 7.5% !important;
 }
  .adminAndConfigHdr ~ .main-container > .container-fluid {
      padding-left: 5% !important;
 }
 .custom-control-input:checked ~ .label-mark::before {
  left: -16px;
 }
 
}
@media (min-width: 1900px){
  .main-container > .container-fluid {
      padding-left: 13.5% !important;
      padding-right: 13% !important;
 }
  .adminAndConfigHdr ~ .main-container > .container-fluid.metric-alert-settings-config{
    padding-left: 13% !important;
    padding-right: 13.5% !important;
 }
 .custom-control-input:checked ~ .label-mark::before {
  left: -18px;
 }
}
/* Responsive Code Ends */

/* page not found css */
.page_notfound_container {
  padding: 15.2% 0% 0% 0%;
  text-align: center;
}
.page_notfound_content {
  display: inline-block;
  text-align: left;
  vertical-align: top;
  padding: 15px 0 0 11.5px;
}
.page_notfound_btn {
  font-size: 1.1em;
  background-color: rgb(161, 0, 255);
  padding: 4.5px 26px;
  font-weight: normal;
  color: #fff;
  font-family: OpenSans-Regular;
  border: 0;
  margin: 15px 0 0 23px;
}